<template>
    <b-container>
        <b-row align-v="start">
            <b-col align-v="center">
                <b-card v-if="isError">
                    <b-card-text class="mx-auto text-center">{{errorMessage}}</b-card-text>

                </b-card>
                <div v-else-if="isRegistering">
                    <div />
                </div>
                <div v-else>
                    <b-overlay :show="isBusy" rounded="sm" opacity="0.6">
                        <div v-if="state === states.start">
                            <b-card-text class="mx-auto"></b-card-text>
                        </div>
                        <b-jumbotron :header="client.title" :lead="client.instructions" border-variant="secondary">
                            <b-card-group>
                                <b-card v-if="state === states.enterEmailOrPhoneOrPassword && dataOptions && (dataOptions.email || dataOptions.phone)" bg-variant="transparent">
                                    <b-card-title>{{dataOptions && dataOptions.email && dataOptions.phone ? 'Sign in with Email or Phone' : dataOptions.email ? 'Sign in with Email' : 'Sign in with Phone'}}</b-card-title>
                                    <b-form-group id="fieldset-1"
                                                  description="Please enter an email or mobile phone where you can receive texts. Data rates may apply."
                                                  :label="dataOptions && dataOptions.email && dataOptions.phone ? 'Enter Email or Phone' : dataOptions.email ? 'Enter Email' : 'Enter Phone'"
                                                  label-for="emailOrPhone">
                                        <b-form-input id="emailOrPhone" v-model="userEmailOrPhone" trim
                                                      :autofocus="true"
                                                      name="emailOrPhone"
                                                      type="text"
                                                      autocomplete="off"
                                                      :placeholder="dataOptions && dataOptions.email && dataOptions.phone ? 'Email or Mobile Phone' : dataOptions.email ? 'Email' : 'Mobile Phone'"
                                                      @keydown.enter.prevent="onEmailOrPhoneEnterKey"></b-form-input>
                                    </b-form-group>
                                    <b-button @click="onSubmitEmailOrPhoneClicked">Submit</b-button>
                                </b-card>
                                <b-card v-if="state === states.enterEmailOrPhoneOrPassword && dataOptions && (dataOptions.password || dataOptions.username)" bg-variant="transparent">
                                    <b-card-title>Sign in with Username</b-card-title>
                                    <b-form-group id="fieldset-3"
                                                  :description="undefined"
                                                  label="Username"
                                                  label-for="username"
                                                  v-if="dataOptions.username || (dataOptions.password && !dataOptions.identityFound)">
                                        <b-form-input id="username" v-model="username" trim
                                                      :autofocus="true"
                                                      name="username"
                                                      type="text"
                                                      autocomplete="off"
                                                      placeholder="Username"
                                                      @keydown.enter.prevent="onUsernameOrPasswordEnterKey"></b-form-input>
                                    </b-form-group>
                                    <b-form-group id="fieldset-4"
                                                  :description="undefined"
                                                  label="Password"
                                                  label-for="password"
                                                  v-if="dataOptions.password">
                                        <b-form-input id="password" v-model="password" trim
                                                      name="password"
                                                      type="password"
                                                      autocomplete="off"
                                                      placeholder="Password"
                                                      @keydown.enter.prevent="onUsernameOrPasswordEnterKey"></b-form-input>
                                    </b-form-group>
                                    <b-button @click="onSubmitUsernameAndPasswordClicked">Submit</b-button>
                                </b-card>
                                <b-card v-if="state === states.enterEmailOrPhoneOrPassword && dataOptions && dataOptions.fido2" bg-variant="transparent">
                                    <b-card-title>Sign in with FIDO Token</b-card-title>
                                    <b-button @click="onLoginWithFidoClicked" variant="outline-secondary" block><b-img :src="icons.yubiKey" class="rounded-0 not-too-big"></b-img><div>Use Token</div></b-button>
                                </b-card>
                                <b-card v-if="state === states.enterEmailOrPhoneOrPassword && dataOptions && dataOptions.mobileAuth" bg-variant="transparent">
                                    <b-card-title>Sign in with Mobile Device</b-card-title>
                                    <b-button @click="onLoginWithMobileClicked" variant="outline-secondary" block><b-img :src="icons.phone" class="rounded-0 not-too-big"></b-img><div>Use Mobile Device</div></b-button>
                                </b-card>
                                <b-card v-if="state === states.enterEmailOrPhoneOrPassword && dataOptions && dataOptions.skip" bg-variant="transparent">
                                    <b-card-title>Skip this Step</b-card-title>
                                    <b-button @click="onSkipClicked" variant="outline-secondary" block><div>Skip this Step</div></b-button>
                                </b-card>
                            </b-card-group>
                            <b-card v-if="state === states.enterAccessCode" bg-variant="transparent">
                                <b-form-group id="fieldset-2"
                                              description="Please enter the one-time access code that was sent to you."
                                              label="Enter Access Code"
                                              label-for="accessCode">
                                    <b-form-input id="accessCode" v-model="userAccessCode" trim
                                                  :autofocus="true"
                                                  name="accessCode"
                                                  type="text"
                                                  autocomplete="off"
                                                  placeholder="Access Code"
                                                  @keydown.enter.prevent="onAccessCodeEnterKey"></b-form-input>
                                </b-form-group>
                                <b-button @click="onSubmitAccessCodeClicked">Submit</b-button>
                            </b-card>

                            <b-card v-if="dataOptions && Array.isArray(dataOptions.registrationActions) && dataOptions.registrationActions.length > 0"
                                    bg-variant="transparent">
                                <b-card-title>Register</b-card-title>
                                <b-row>
                                    <b-col v-for="a in dataOptions.registrationActions" :key="a.name">
                                        <b-button @click="onRegister(a)" class="mr-2" block size="lg">{{a.label}}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-jumbotron>

                        <template #overlay>
                            <b-card class="text-center" border-variant="dark">
                                <b-card-title class="mx-auto">{{busyMessage || "Please wait..."}}</b-card-title>
                                <b-card-text v-if="busyDetails">{{busyDetails}}</b-card-text>
                                <b-card v-if="typeof busyOTP === 'string'">
                                    <h1>{{busyOTP}}</h1>
                                </b-card>
                                <b-spinner v-else class="mx-auto"></b-spinner>
                                <b-button v-if="busyShowCancel" @click="onBusyCancel">Cancel</b-button>
                            </b-card>
                        </template>
                    </b-overlay>
                </div>
            </b-col>
        </b-row>

        <WizardModal :handler="wizard"
                     @next="wizard.onNext()"
                     @skip="wizard.onSkip()"
                     @cancel="wizard.onCancel()"
                     @previous="wizard.onPrevious()"
                     @error="(evt) => wizard.onError(evt)"
                     />


    </b-container>
</template>

<script>
    import LoginClient, { LoginAuthStatus } from "../library/LoginClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    import iconYubiKey from "../assets/icons/yubikey.svg"; // "../assets/icons/yubi-600x600.png";
    import iconSmartCard from "../assets/icons/card.svg"; //  "../assets/icons/Card.png";
    import iconPhone from "../assets/icons/phone.svg"; //  "../assets/icons/Card.png";
    import WizardModal from "../components/WizardModal.vue";
    import WizardHandler from "../library/WizardHandler.js";


    export default {
        props: {
            //used to support delegated login, where we are already logged in but are requesting a new login on behalf of an application (such as the Activator)
            existingAuth: Object
        },
        data() {
            return {
                showConfirm: false,
                client: new LoginClient(),
                isBusy: false, //just for the search results
                busyMessage: null,
                busyDetails: null,
                busyOTP: null,
                busyShowCancel: false,
                busyIsCanceled: false,

                isError: false,
                errorMessage: null,
                errorDetails: null,

                userEmailOrPhone: null,
                userAccessCode: null,
                username: null,
                password: null,
                scopesToRequest: ["openid", "manage"],
                states: {
                    start: null,
                    enterEmailOrPhoneOrPassword: 1,
                    enterAccessCode: 2,
                    finished: 3,
                    register: 4
                },
                state: null,
                dataOptions: null,
 
                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone
                },

                userEmailOrPhoneIsInvalid: false,
                usernameOrPasswordIsInvalid: false,
                userAccessCodeIsInvalid: false,

                //An object that has the id_token, the access_token, expiration, etc.
                //If null then we need to authenticate.
                authTokens: null,

                isRegistering: false,

                wizard: new WizardHandler({
                    doStep: this.doStepWizard,
                    onFinish: this.onFinishWizard
                }),


            }
        },
        components: {
            WizardModal
        },
        computed: {

        },
        emits: ['authenticated', 'error', 'interface'],
        async mounted() {
            try {
                this.showBusy("Please wait...");

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                await this.authenticate();
            }
            catch (ex) {
                this.handleError(ex);
            }

            this.hideBusy();
        },
        methods: {
            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    logOut: () => this.logOut()
                });
            },

            logOut() {
                DConsole.log("Logging out...");

                LoginClient.clearCachedAuthTokens();
                LoginClient.clearSessionCookie();

                this.userEmailOrPhone = null;
                this.userAccessCode = null;
                this.username = null;
                this.password = null;
                this.state = null;
                this.userEmailOrPhoneIsInvalid = false;
                this.usernameOrPasswordIsInvalid = false;
                this.userAccessCodeIsInvalid = false;
                this.authTokens = null;

                this.client = new LoginClient();

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                this.hideBusy();

                DConsole.log(ex);
                if (!ex.userCanceled) {
                    this.raiseErrorEvent(ex);
                }
                //else do nothing, because the user canceled it.
            },

            clearError() {
                this.raiseErrorEvent(null);
            },


            onShowConfirm() {
                this.showConfirm = true;
            },

            // Returns a LoginAuthStatus object.
            // Emits the "authenticated" event if authentication succeeds without needing more data.
            // This will check the cache first. If it finds an existing token that hasn't expired, 
            // it will use it.
            async authenticate() {
                this.client.requestedScopes = [...this.scopesToRequest]; //copy array.

                let cachedTokens = await this.client.getCachedAuthTokens();
                let wasAlreadyAuthenticated = cachedTokens ? true : false;

                let isDelegated = LoginClient.checkUrlForDelegatedLoginRequest();

                let tokens = null;

                let result = null;

                if (isDelegated || !wasAlreadyAuthenticated) {
                    result = await Promise.race(
                        [
                            this.client.authenticate(),
                            this.waitForBusyCancel()
                        ]);
                    DConsole.log("result:");
                    DConsole.log(result);
                    if (!result) {
                        let err = new Error("User canceled.");
                        err.userCanceled = true;
                        window.location.reload();
                        throw err;
                    }
                    else if (result.tokens) {

                        tokens = result.tokens;
                        if (!wasAlreadyAuthenticated) {
                            await this.client.cacheAuthTokens(tokens);
                        }
                    }
                }
                else {
                    result = new LoginAuthStatus();
                    result.tokens = cachedTokens;
                    tokens = cachedTokens;
                }

                this.dataOptions = result.dataOptions;
                if (result.needsData || result.dataOptions?.allowRegistration) {
                    if (result.dataOptions.accessCode) {
                        this.state = this.states.enterAccessCode;
                    }
                    else {
                        this.state = this.states.enterEmailOrPhoneOrPassword;
                    }
                }

                if (tokens) {

                    this.$emit('authenticated', tokens, tokens.redirect_uri ? true : false);
                    this.state = this.states.finished;

                    if (tokens.redirect_uri) {
                        window.location.href = tokens.redirect_uri;
                    }
                }

                return result;
            },

            async onEmailOrPhoneEnterKey() {
                await this.onSubmitEmailOrPhoneClicked();
            },

            async onSubmitEmailOrPhoneClicked() { //, index, evt) {
                this.clearError();

                let input = this.userEmailOrPhone;
                let isValid = false;
                let email = null;
                let phone = null;

                if (input === undefined || input === null || input === "") {
                    isValid = false;
                }
                else if (ExpUtil.isValidEmail(input)) {
                    email = input;
                    isValid = true;
                }
                else if (ExpUtil.isValidPhoneNumber(input)) {
                    phone = input;
                    isValid = true;
                }

                this.userEmailOrPhoneIsInvalid = !isValid;

                this.showBusy("Sending one-time access code...");

                this.userAccessCode = null;

                this.client.userEmail = email;
                this.client.userPhone = phone;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();

            },

            async onAccessCodeEnterKey() {
                await this.onSubmitAccessCodeClicked();
            },

            async onSubmitAccessCodeClicked() { //, index, evt) {
                this.clearError();

                let input = this.userAccessCode;
                let isValid = false;


                if (input === undefined || input === null || input === "") {
                    isValid = false;
                }
                else {
                    isValid = true;
                }

                this.userAccessCodeIsInvalid = ! isValid;

                this.showBusy("Verifying access code...");

                this.client.userAccessCode = this.userAccessCode;

                this.userAccessCode = null;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);

                }
                this.hideBusy();
            },

            async onUsernameOrPasswordEnterKey() {
                await this.onSubmitUsernameAndPasswordClicked();
            },

            async onSubmitUsernameAndPasswordClicked() { //, index, evt) {
                this.clearError();

                let requirePassword = this.dataOptions.password;
                let requireUsername = !this.dataOptions.identityFound;
                let isValid = true;
                let username = this.username;
                let password = this.password;

                if (requireUsername && (username === undefined || username === null || username === "")) {
                    isValid = false;
                }
                if (requirePassword && (password === undefined || password === null || password === "")) {
                    isValid = false;
                }

                this.usernameOrPasswordIsInvalid = !isValid;

                this.showBusy("Verifying login information...");

                this.client.username = username;
                this.client.password = password;

                this.password = null;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();

            },

            async onLoginWithFidoClicked() {
                this.clearError();

                this.showBusy("Follow pop-ups to login...");

                this.username = null;
                this.password = null;
                this.userEmailOrPhone = null;

                this.client.username = null;
                this.client.password = null;
                this.client.userEmail = null;
                this.client.userPhone = null;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();

            },

            async onLoginWithMobileClicked() {
                this.clearError();

                let details = null;
                if (this.dataOptions.hasMobileAuthOTP) {
                    details = "When prompted by your device, please enter the number below."
                }

                this.showBusyWithCancel("Please check your mobile device for an authentication request.", details, this.dataOptions.mobileAuthOTP);

                this.username = null;
                this.password = null;
                this.userEmailOrPhone = null;

                this.client.username = null;
                this.client.password = null;
                this.client.userEmail = null;
                this.client.userPhone = null;

                this.client.isMobileAuthRequested = true;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();

            },

            async onSkipClicked() {
                this.clearError();

                this.showBusy("Please wait...");

                this.username = null;
                this.password = null;
                this.userEmailOrPhone = null;

                this.client.username = null;
                this.client.password = null;
                this.client.userEmail = null;
                this.client.userPhone = null;

                this.client.isSkipRequested = true;

                try {
                    await this.authenticate();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();

            },

            async onRegister(action) {
                if (!action || !action.name) return;

                this.clearError();

                this.isRegistering = true;

                try {
                    await this.wizard.start(action);
                }
                catch (ex) {
                    this.handleError(ex);
                    this.isRegistering = false;
                }
            },


            async doStepWizard(stepInput) {
                if (!this.isRegistering) throw new Error("No registration is in progress.");

                return await this.client.register(
                    this.identity?.id,
                    null, null, null, stepInput);
            },

            async onFinishWizard(ok) {
                this.isRegistering = false;
                if (ok) {
                    //Just putting this to avoid warning about unused "ok" param.
                    //Could simply remove it but I want to leave it as a reminder that 
                    //there is a parameter passed in.
                    DConsole.log("Finished wizard.");
                }
            },

            showBusyWithCancel(message, details, otp) {
                this.showBusy(message, details, otp, true);
            },

            showBusy(message, details, otp, allowCancel) {
                this.isBusy = true;
                this.busyMessage = message;
                this.busyDetails = details;
                this.busyOTP = otp;
                if (allowCancel === undefined) {
                    this.busyShowCancel = (typeof busyOTP === "string") ? true : false;
                }
                else {
                    this.busyShowCancel = allowCancel;
                }
            },

            hideBusy() {
                this.isBusy = false;
                this.busyMessage = null;
                this.busyDetails = null;
                this.busyOTP = null;
                this.busyShowCancel = false;
                this.busyIsCanceled = false;
            },

            async waitForBusyCancel(returnValueIfCanceled) {
                let done = false;
                while (!done) {
                    let timer = new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(null);
                            //DConsole.log("timeout");
                        }, 20); //every 20 milliseconds
                    });

                    await timer;

                    if (this.busyIsCanceled || !this.isBusy) {
                        DConsole.log("is canceled");
                        done = true;
                    }
                }
                return returnValueIfCanceled === undefined ? null : returnValueIfCanceled;
            },

            onBusyCancel() {
                if (this.busyShowCancel) {
                    this.busyIsCanceled = true;
                    DConsole.log("Cancel button pressed...");
                }
            },

 
            getBaseUrl() {
                return this.client?.baseUrl;
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .not-too-big {
        max-height: 25vw;
    }
</style>