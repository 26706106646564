<template>
    <div id="app" class="bg-epdark-fade">
        <AppHeader class="header" @goHome="onGoHome" @logOut="onLogOut"
                   @goReports="onGoReports" @goSelfService="onGoSelfService"
                   @goInventory="onGoInventory" @goAdmin="onGoAdmin"
                   :isLoggedIn="isAuthenticated"
                   :showHome="hasManageScope" :showReports="hasManageScope" :showSelfService="isAuthenticated"
                   :showAdmin="hasManageScope"
                   :showInventory="hasManageScope" />

        <b-alert v-model="showError" variant="danger" dismissible class="mx-auto text-center alert">
            {{error ? (error.message || error) : null}}
        </b-alert>
        <!--<b-card v-if="isError">
        <b-card-text class="mx-auto text-center text-danger">{{errorMessage}}</b-card-text>
    </b-card>
        -->
        <Home v-if="false && hasManageScope && (page === 'home' || !page)" class="home" msg="" :auth="authTokens" @needsAuth="onNeedsAuth" @error="onError" @interface="onHomeInterface" />
        <Home2 v-if="hasManageScope && (page === 'home' || !page)" class="home" msg="" :auth="authTokens" @needsAuth="onNeedsAuth" @error="onError" @interface="onHomeInterface" />
        <Reports v-else-if="hasManageScope && page === 'reports'" class="home" msg="" :auth="authTokens" @goHome="onGoHome" @needsAuth="onNeedsAuth" @error="onError" @interface="onReportsInterface" />
        <Inventory v-else-if="hasManageScope && page === 'inventory'" class="home" :auth="authTokens" @goHome="onGoHome" @needsAuth="onNeedsAuth" @error="onError" @interface="onInventoryInterface" />
        <Admin v-else-if="hasManageScope && page === 'admin'" class="home" :auth="authTokens" @goHome="onGoHome" @needsAuth="onNeedsAuth" @error="onError" @interface="onAdminInterface" />
        <SelfService v-else-if="isAuthenticated && (page === 'self' || !page || true)" class="home" :auth="authTokens" @goHome="onGoHome" @needsAuth="onNeedsAuth" @error="onError" @interface="onSelfServiceInterface" />
        <Login v-else @authenticated="loginAuthenticated" @error="onError" class="home" @interface="onLoginInterface" :existingAuth="authTokens" />

        <AppFooter class="footer text-white-semiclear">
            <template #right>
                &copy; 2024 Technology Industries Inc. All rights reserved.
            </template>
            <template #left v-if="authTokens && authTokens.id_parsed && authTokens.id_parsed.payload">
                Logged in as {{authTokens && authTokens.id_parsed && authTokens.id_parsed.payload && authTokens.id_parsed.payload.name}}
            </template>
        </AppFooter>
    </div>
</template>

<script>
    //import './assets/global.css';
    //import './assets/colors.css';

    import Home from './components/Home.vue';
    import Home2 from './components/Home2.vue';
    import Reports from './components/Reports.vue';
    import AppHeader from './components/AppHeader.vue';
    import AppFooter from './components/AppFooter.vue';
    import Login from "./components/Login.vue";
    import LoginClient from "./library/LoginClient.js";
    import DConsole from "./util/DConsole.js";
    import SelfService from "./components/SelfService.vue";
    import Admin from "./components/Admin.vue";
    import Inventory from "./components/Inventory.vue";
    import Url from "./util/Url.js";

    //import { computed } from "vue";

    const Page = {
        Home: "home",
        Reports: "reports",
        SelfService: "self",
        Inventory: "inventory",
        Admin: "admin"
    }

    export default {
        name: 'app',
        components: {
            Home,
            Home2,
            Reports,
            AppHeader,
            AppFooter,
            Login,
            SelfService,
            Inventory,
            Admin
        },
        data() {
            return {
                authTokens: null,  //object returned the "tokens" oauth call.
                error: null,
                showError: false,
                homeInterface: null,
                loginInterface: null,
                reportsInterface: null,
                selfServiceInterface: null,
                adminInterface: null,
                inventoryInterface: null,
                isDelegatedLogin: false
            }
        },
        provide() {
            //These properties can be "injected" in components that are descendants of this one.
            //see provide/inject in vue docs. https://vuejs.org/guide/components/provide-inject.html#provide-inject
            return {
                //authTokens: this.authTokens,  //use computed(() => this.authTokens) in Vue 3 to allow changes to propagate
            }
        },
        computed: {
            isAuthenticated() {
                return this.authTokens && ! this.isDelegatedLogin ? true : false;
            },
            page() {
                return this.$route.params.page;
            },
            hasManageScope() {
                return this.isAuthenticated && this.authTokens && this.authTokens.hasScope(LoginClient.AuthScopes.Manage);
            },
            hasSelfScope() {
                return this.isAuthenticated && this.authTokens && this.authTokens.hasScope(LoginClient.AuthScopes.SelfService);
            },
        },
        mounted() {
            DConsole.log("App component mounted.");

            this.isDelegatedLogin = LoginClient.checkUrlForDelegatedLoginRequest();
        },
        methods: {
            loginAuthenticated(tokens, isDelegatedLogin) {
                if (tokens) {
                    this.authTokens = tokens;
                    this.isDelegatedLogin = isDelegatedLogin ? true : false;

                    //Remove the query string from the URL without reloading.
                    let cleanUrl = Url.removeQueryStringAndHash(window.location.href);
                    history.replaceState(null, null, cleanUrl);

                    DConsole.log(`clean URL: ${cleanUrl}`);

                    DConsole.log(tokens.id_parsed?.payload);


                }

            },

            onNeedsAuth() {
                //Clear the cached tokens.
                LoginClient.clearCachedAuthTokens();

                DConsole.log("Clearing tokens...");
                this.authTokens = null;


                //This should trigger the Login component to get remounted, which will in turn
                //cause it to reauthenticate.
            },

            onError(ex) {
                if (ex) {
                    DConsole.log("onError()");
                    DConsole.log(ex);
                }
                this.error = ex;
                this.showError = ex ? true : false;
            },

            onGoHome(optionalIdentity) {
                DConsole.log("onGoHome");
                DConsole.log(this.homeInterface);
                if (this.homeInterface) {
                    this.homeInterface.goHome(optionalIdentity);
                }
                this.$router.push("/" + Page.Home);
            },

            onGoReports() {
                DConsole.log("onGoReports");
                DConsole.log(this.reportsInterface);
                this.$router.push("/" + Page.Reports);
            },

            onGoSelfService() {
                DConsole.log("onGoSelfService");
                DConsole.log(this.selfServiceInterface);
                this.$router.push("/" + Page.SelfService);
            },

            onGoInventory() {
                DConsole.log("onGoInventory");
                this.$router.push("/" + Page.Inventory);
            },

            onGoAdmin() {
                DConsole.log("onGoAdmin");
                this.$router.push("/" + Page.Admin);
            },

            onLogOut() {
                DConsole.log("onLogOut");

                if (this.homeInterface) {
                    this.homeInterface.goHome();
                }

                if (this.loginInterface) {
                    this.loginInterface.logOut();
                }

                this.authTokens = null;

                //Redirect without all the parameters.
                let cleanUrl = Url.removeQueryStringAndHash(window.location.href);
                //window.location.href = cleanUrl;
                history.replaceState(null, null, cleanUrl);

                //This should trigger the Login component to get remounted, which will in turn
                //cause it to reauthenticate.
            },

            onHomeInterface(input) {
                this.homeInterface = input;
            },

            onLoginInterface(input) {
                this.loginInterface = input;
            },

            onSelfServiceInterface(input) {
                this.selfServiceInterface = input;
            },
            onAdminInterface(input) {
                this.adminInterface = input;
            },
            onInventoryInterface(input) {
                this.inventoryInterface = input;
            }

        }
    };
</script>

<style scoped>
    #app {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
        height: 100vh;
    }


    .home {
        flex: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-grow: 1;
    }

    .alert {
    }

    .header {
        align-self: start;
    }

    .footer {
    }

    .bg-epdark {
        background-color: #384f68;
    }

    .bg-epdark-fade {
        background: rgb(255,255,255);
        /*background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(56,79,104,1) 10%, rgba(56,79,104,1) 90%, rgba(255,255,255,1) 100%);
            */
        background: linear-gradient(0deg, rgba(56,79,104,1) 0%, rgba(255,255,255,1) 100%);
    }

    .text-white-semiclear {
        color: rgba(255,255,255,0.5);
    }
</style>

