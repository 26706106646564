class Url {

    static combine(base, relative) {
        if (base === undefined || base === null ) base = "";
        if (relative === undefined || relative === null ) relative = "";

        if (base.endsWith('/') && base.length > 1) {
            base = base.substr(0, base.length - 1);
        }
        if (! relative.startsWith('/') && (base.length > 0 && base !== "/")) {
            relative = "/" + relative;
        }

        //Make sure we don't have two slashes together.
        if (base.endsWith('/') && relative.startsWith('/')) {
            if (base.length > 1) {
                base = base.substr(0, base.length - 1);
            }
            else {
                base = "";
            }
        }
        return base + relative;
    }

    static removeLastPart(url) {
        url = url || "";

        //Remove the query string if any.
        let questionMark = url.indexOf('?');
        if (questionMark >= 0) {
            url = url.substr(0, questionMark);
        }

        //Remove the anchor part if any.
        let hash = url.indexOf('#');
        if (hash >= 0) {
            url = url.substr(0, hash);
        }

        //Now remove the last part of the path, if any.
        let result = url;
        if (url.length > 1) {
            //find the last slash, skipping the last character (in case it is a slash.)
            let lastSlash = url.lastIndexOf('/', url.length - 2);

            if (lastSlash >= 0) {
                result = url.substr(0, lastSlash);
            }

            //If all we are left with is what appears to be the scheme (e.g., http:/)
            //then just put it back, since that was probably all we had.
            if (result.endsWith(":/")) {
                result = url;
            }
        }
        return result;
    }

    static removePathPart(url) {
        url = url || "";

        //Remove the query string if any.
        let questionMark = url.indexOf('?');
        if (questionMark >= 0) {
            url = url.substr(0, questionMark);
        }

        //Remove the anchor part if any.
        let hash = url.indexOf('#');
        if (hash >= 0) {
            url = url.substr(0, hash);
        }

        //Now remove the last part of the path, if any.
        let result = url;
        while (url.length > 1) {
            //find the last slash, skipping the last character (in case it is a slash.)
            let lastSlash = url.lastIndexOf('/', url.length - 2);

            if (lastSlash >= 0) {
                result = url.substr(0, lastSlash);
            }

            //If all we are left with is what appears to be the scheme (e.g., http:/)
            //then just put it back, since that was probably all we had.
            if (result.endsWith(":/")) {
                result = url;
                break;
            }
            url = result;
        }
        return result;
    }

    static getCurrentRoot() {
        //Note: this won't always work. I need a better solution. This will work if application path is rooted
        //at "exp".
        return Url.removePathPart(window.location.href);
    }

    static isAbsolute(url) {
        return url.match(/^[a-z]+:\/\//) !== null;
    }

    // wraps the url in "url()" as required by CSS.
    static css(url) {
        return "url(\"" + url + "\")";
    }

    static makeDataUrl(base64EncodedData, mimeType) {
        mimeType = mimeType || "image/jpeg";
        return "data:" + mimeType + ";base64," + base64EncodedData;
    }

    static makePhotoDataUrl(base64Image) {
        return Url.makeDataUrl(base64Image, "image/jpeg");
    }


    static removeQueryStringAndHash(inputUrlString) {
        let url = new URL(inputUrlString);
        url.search = "";
        url.hash = "";
        return url.toString();
    }
}

export default Url;
