import ExpUtil from "../util/ExpUtil.js";
//import DConsole from "../util/DConsole.js";

class UserDevice {
    constructor(options) {
        if (options) {
            Object.assign(this, options);
        }
    }

    get shortName() {
        let id = this.shortID;
        let name = this.name;

        return name || (this.family || "Device") + " " + id;
    }

    get shortID() {
        let output = this.id;
        if (typeof this.id === "string") {
            output = this.id.slice(-8); //last 8 characters.
        }
        return output;
    }

    get formattedPushDeviceToken() {
        let output = this.pushDeviceToken;
        if (typeof output === "string") {
            output = this.id.replace(/.{32}/g, "$& ");
        }
        return output;
    }

    get creationTimeString() {
        if (this.creationTime) {
            return ExpUtil.formatEventTime(this.creationTime);
        }
        else {
            return "";
        }
    }

    get updateTimeString() {
        if (this.updateTime) {
            return ExpUtil.formatEventTime(this.updateTime);
        }
        else {
            return "";
        }
    }

    get expirationTimeString() {
        if (this.expirationTime) {
            return ExpUtil.formatEventTime(this.expirationTime);
        }
        else {
            return "";
        }
    }

    get revocationTimeString() {
        if (this.revocationTime) {
            return ExpUtil.formatEventTime(this.revocationTime);
        }
        else {
            return "";
        }
    }

    get isValid() {
        let now = new Date();
        let exp = this.expirationTime ? new Date(this.expirationTime) : null;

        return !this.isRevoked && (!exp || exp > now);
    }

    //True if these refer to the same device (by ID)
    equals(other) {
        let output = false;
        if (other && other instanceof UserDevice) {
            output = this.id === other.id;
        }

        return output ? true : false;
    }

}

export default UserDevice;