<template>
    <b-modal :visible="visible === undefined || visible === null ? (handler && handler.isVisible) : visible" no-close-on-backdrop size="lg" scrollable centered
             :title="title === undefined || title === null ? (handler && handler.action && (handler.action.label || handler.action.name)) : title"
             >
        <template #default>
            <b-alert v-model="isError" variant="danger" dismissible class="mx-auto text-center alert">
                {{errorMessage}}
            </b-alert>

            <Wizard :step="step || (handler && handler.step)"
                    :showNextByDefault="showNextByDefault === undefined || showNextByDefault === null ? (handler && handler.showNextByDefault) : showNextByDefault"
                    :showCancelByDefault="showCancelByDefault === undefined || showCancelByDefault === null ? (handler && handler.showCancelByDefault) : showCancelByDefault"
                    :showPreviousByDefault="showPreviousByDefault === undefined || showPreviousByDefault === null ? (handler && handler.showPreviousByDefault) : showPreviousByDefault"
                    :showSkipByDefault="showSkipByDefault === undefined || showSkipByDefault === null ? (handler && handler.showSkipByDefault) : showSkipByDefault"
                    :showNext="showNext"
                    :showCancel="showCancel"
                    :showPrevious="showPrevious"
                    :showSkip="showSkip"
                    :isBusy="isBusy === undefined || isBusy === null ? (handler && handler.isBusy) : isBusy" 
                    :busyMessage="busyMessage === undefined || busyMessage === null ? (handler && handler.busyMessage) : busyMessage" 
                    :busyDetails="busyDetails === undefined || busyDetails === null ? (handler && handler.busyDetails) : busyDetails"
                    @next="onNext" @skip="onSkip" @previous="onPrevious" @cancel="onCancel" @error="onError"/>
        </template>

        <template #modal-header>

        </template>
        <template #modal-footer>

        </template>
    </b-modal>

</template>

<script>
    //import DConsole from "../util/DConsole.js";
    //import ExpUtil from "../util/ExpUtil.js";
    import Wizard from "../components/Wizard.vue";

    export default {
        props: {
            visible: { type: Boolean, default: undefined },
            size: {
                type: String,
                default: "lg"  //see bootstrap-vue Modal for size values.
            },

            handler: Object, //must be a WizardHandler. This can be used instead of all the specific ones.

            title: String,

            error: Object,

            step: Object,
            showNextByDefault: {
                type: Boolean,
                default: undefined
            },
            showCancelByDefault: {
                type: Boolean,
                default: undefined
            },
            showPreviousByDefault: {
                type: Boolean,
                default: undefined
            },
            showSkipByDefault: {
                type: Boolean,
                default: undefined
            },
            showNext: {
                type: Boolean,
                default: undefined
            },
            showCancel: {
                type: Boolean,
                default: undefined
            },
            showPrevious: {
                type: Boolean,
                default: undefined
            },
            showSkip: {
                type: Boolean,
                default: undefined
            },

            isBusy: {
                type: Boolean,
                default: undefined
            },
            busyMessage: String,
            busyDetails: String

        },
        emits: ['error', 'next', 'previous', 'skip', 'cancel'],
        data() {
            return {
            }
        },
        components: {
            Wizard
        },
        computed: {
            isError() {
                return (this.error === undefined || this.error === null ? (this.handler && this.handler.error) : this.error) ? true : false;
            },
            errorMessage() {
                let e = this.error === undefined || this.error === null ? (this.handler && this.handler.error) : this.error;
                return e ? (e.message || e.title || (e.body && e.body.title) || e) : null;
            }
        },
        async mounted() {

        },
        methods: {

            onError(ex) {
                this.$emit("error", ex);
            },

            emitNext() {
                this.$emit("next");
            },

            emitPrevious() {
                this.$emit("previous");
            },

            emitSkip() {
                this.$emit("skip");
            },

            emitCancel() {
                this.$emit("cancel");
            },

            onPrevious() {
                this.emitPrevious();
            },

            onNext() {
                this.emitNext();
            },

            onCancel() {
                this.emitCancel();
            },

            onSkip() {
                this.emitSkip();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 
</style>

