<template>
    <div v-if="form">
        <b-card-title v-if="showTitle && form.title">{{form.title}}</b-card-title>
        <b-card-text v-if="showInstructions && form.instructions">{{form.instructions}}</b-card-text>
        <b-row v-for="(section, sectionIndex) in form.sections" :key="'section' + sectionIndex">
            <b-col cols="12" v-if="this.doesSectionHaveData(section)">
                <b-card-title v-if="section.title" title-tag="dt"
                              :class="! section.instructions && doesRowHaveNoLabels(section, 0) ? 'mb-0' : undefined"
                              >{{section.title}}</b-card-title>
                <b-card-text v-if="section.instructions">{{section.instructions}}</b-card-text>
                <b-row v-for="(row, rowIndex) in section.rows" :key="'row' + rowIndex">

                    <b-col v-for="(field, fieldIndex) in row.fields" :key="'field' + fieldIndex"
                           :cols="field.width">
                        <dl v-if="dataModel[field.propertyName] !== undefined && dataModel[field.propertyName] !== null && dataModel[field.propertyName] !== ''"
                            class="p-0 m-0"
                            >
                            <dt v-if="field.label">{{field.label}}</dt>
                            <dd :class="doesRowHaveNoLabels(section, rowIndex + 1) ? 'mb-0' : undefined">{{formatFieldValue(field)}}</dd>
                        </dl>

                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>

</template>

<script>
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubi-600x600.png";
    import iconSmartCard from "../assets/icons/Card.png";

    export default {
        props: {
            model: Object,
            form: Object,
            showTitle: {
                type: Boolean,
                default: true
            },
            showInstructions: {
                type: Boolean,
                default: true
            }

        },
        emits: ['error'],
        data() {
            return {

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard
                },
            }
        },
        components: {
        },
        computed: {
            dataModel() {
                return ExpUtil.getCaseInsensitiveProxy(this.model ?? this.form?.dataModel ?? {});
            },

        },
        async mounted() {

        },
        methods: {

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (typeof this.client?.handleError === "function") {
                    this.client.handleError(ex);
                }
                else {
                    if (ex.status === 401) {
                        this.raiseNeedsAuthEvent();
                    }
                    else {
                        this.raiseErrorEvent(ex);
                    }
                }
            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            formatFieldValue(field) {
                let model = this.dataModel;
                if (!field || !model) return;

                let val = field.propertyName === undefined ? null : model[field.propertyName];
                let output = val;


                if (val === undefined || val === null) {
                    output = "";
                }
                else if (Array.isArray(field.options) && field.options.length > 0) {
                    output = field.options.find(x => x.value === val)?.text ?? val;
                }
                return output;
            },

            doesSectionHaveData(section) {
                let hasData = false;
                if (Array.isArray(section.rows)) {
                    for (let row of section.rows) {
                        if (Array.isArray(row.fields)) {
                            for (let field of row.fields) {
                                let val = this.formatFieldValue(field);
                                if (val !== undefined && val !== null && val !== "") {
                                    hasData = true;
                                    break;
                                }
                            }
                        }
                        if (hasData) break;
                    }
                }
                return hasData;
            },

            isSectionEmpty(section) {
                return !this.doesSectionHaveData(section);
            },

            doesRowHaveNoLabels(section, rowIndex) {
                if (!Array.isArray(section?.rows) || rowIndex >= section.rows.length) {
                    return false;
                }
                else {
                    let row = section.rows[rowIndex];
                    let hasAtLeastOneLabel = false;
                    if (Array.isArray(row.fields)) {
                        for (let field of row.fields) {
                            if (field.label !== undefined && field.label !== null) {
                                hasAtLeastOneLabel = true;
                                break;
                            }
                        }
                    }
                    return !hasAtLeastOneLabel;
                }
            },

            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            }

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }


</style>

