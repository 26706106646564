import DConsole from '../util/DConsole';
import ExpUtil from "../util/ExpUtil.js";

class FidoWebAuthN {
    async createCredential(optsFromServer) {
        DConsole.log(optsFromServer);

        let publicKeyOpts = {
            challenge: Uint8Array.from(window.atob(optsFromServer.challenge), c => c.charCodeAt(0)),
            rp: {
                name: optsFromServer.relyingPartyName,
                id: optsFromServer.relyingPartyID,
            },
            user: {
                name: optsFromServer.username,
                displayName: optsFromServer.userDisplayName,
                id: Uint8Array.from(window.atob(optsFromServer.userHandle), c => c.charCodeAt(0))

            },
            //supported algorithms in order of preference. Not sure about the order of preference here, this is pretty arbitrary.
            //TODO: This probably ought to be passed down from the server as part of the options.
            pubKeyCredParams: [
                { type: "public-key", alg: -7 }, //ES256
                { type: "public-key", alg: -8 }, //EDDSA
                { type: "public-key", alg: -257 } //RS256

            ],
            authenticatorSelection: {
                authenticatorAttachment: "cross-platform",
                residentKey: "required",
                requireResidentKey: true,
                userVerification: optsFromServer.userVerificationRequired ? "required" : "discouraged",
            },
            excludeCredentials: optsFromServer.excludeCredentials?.map(x => {
                return {
                    type: "public-key",
                    id: Uint8Array.from(window.atob(x), c => c.charCodeAt(0)),
                    transports: ["usb", "nfc", "ble"]
                };
            }),
            attestation: "direct"
        };

        if (optsFromServer.timeoutSeconds > 0) {
            publicKeyOpts.timeout = optsFromServer.timeoutSeconds * 1000.0; //convert to milliseconds
        }

        let credential = await navigator.credentials.create({
            publicKey: publicKeyOpts
        });

        if (!credential) throw new Error("Unable to create FIDO2 credential.");

        let response = credential.response;

        let output = {
            CredentialID: ExpUtil.convertBase64UrlToBase64(credential.id), //base64 url encoded
            AuthData: ExpUtil.coerceToBase64(response.getAuthenticatorData()),
            ClientDataJSON: ExpUtil.coerceToBase64(response.clientDataJSON),
            AttestationObject: ExpUtil.coerceToBase64(response.attestationObject)
        };

        return output;
    }

    async requestAssertion(optsFromServer) {
        DConsole.log(optsFromServer);

        let publicKeyOpts = {
            challenge: Uint8Array.from(window.atob(optsFromServer.challenge), c => c.charCodeAt(0)),
            rpId: optsFromServer.relyingPartyID,
            userVerification: optsFromServer.userVerificationRequired ? "required" : "discouraged",
            allowCredentials: optsFromServer.allowedCredentialIDs?.map(x => {
                return {
                    type: "public-key",
                    id: Uint8Array.from(window.atob(x), c => c.charCodeAt(0)),
                    transports: ["usb", "nfc", "ble"]
                };
            }),
        };

        if (optsFromServer.timeoutSeconds > 0) {
            publicKeyOpts.timeout = optsFromServer.timeoutSeconds * 1000.0; //convert to milliseconds
        }

        let credential = await navigator.credentials.get({
            publicKey: publicKeyOpts
        });

        let response = credential.response;

        let output = {
            AuthData: ExpUtil.coerceToBase64(response.authenticatorData),
            Signature: ExpUtil.coerceToBase64(response.signature),
            CredentialID: ExpUtil.convertBase64UrlToBase64(credential.id), //base64 url encoded
            UserID: ExpUtil.coerceToBase64(response.userHandle),
            ClientDataJSON: ExpUtil.coerceToBase64(response.clientDataJSON)
        };

        return output;
    }

}

export default FidoWebAuthN;