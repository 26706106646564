<template >

    <b-row class="token-view" no-gutters>
        <b-col>
            <b-overlay :show="isBusy">
                <b-card-body class="h-100 w-100">
                    <b-row>
                        <b-col md="2" class="ep-card-icon">
                            <b-card-img v-if="token.badgeImageFront" :src="makePhotoDataUrl(token.badgeImageFront)" class="rounded-0"></b-card-img>
                            <b-card-img v-else-if="token.photo" :src="makePhotoDataUrl(token.photo)" class="rounded-0"></b-card-img>
                            <b-card-img v-else-if="token.family && token.family.startsWith('YubiKey')" :src="icons.yubiKey" class="rounded-0 "></b-card-img>
                            <b-card-img v-else-if="token.family && token.family.startsWith('IDOne')" :src="icons.smartCard" class="rounded-0 "></b-card-img>
                            <b-card-img v-if="token.badgeImageBack" :src="makePhotoDataUrl(token.badgeImageBack)" class="rounded-0"></b-card-img>

                            <div class="text-danger" v-if="token.isRevoked"><b-icon icon="x-circle" variant="danger" />REVOKED</div>

                        </b-col>

                        <b-col md="7" no-gutters>
                            <b-card no-body>
                                <b-tabs card :nav-wrapper-class="hasTokenAdditionalDetails ? undefined : 'd-none'">
                                    <b-tab title="Basic Info">
                                        <dl>
                                            <dt>Token Family</dt>
                                            <dd>{{token.family}}</dd>
                                            <dt>Token ID</dt>
                                            <dd>{{token.tokenID}}</dd>
                                            <dt v-if="tokenModel">Token Model</dt>
                                            <dd v-if="tokenModel">{{tokenModel.name}}</dd>
                                            <dt v-if="token.serialNumber">Serial Number</dt>
                                            <dd v-if="token.serialNumber">{{token.serialNumber}}</dd>
                                            <dt v-if="token.isRevoked && token.revocationReason">Revocation Reason</dt>
                                            <dd v-if="token.isRevoked && token.revocationReason">{{token.revocationReason}}</dd>
                                            <dt v-if="token.isRevoked && token.revocationTimeString">Revocation Time</dt>
                                            <dd v-if="token.isRevoked && token.revocationTimeString">{{token.revocationTimeString}}</dd>
                                            <dt v-if="policy && policy.allowGetPUK && token.canGetPUK">PIN Unlock Key</dt>
                                            <dd v-if="policy && policy.allowGetPUK && token.canGetPUK">
                                                <div v-if="puk">
                                                    <span>{{puk}}&nbsp;&nbsp;</span>
                                                    <b-button size="sm" variant="outline-primary"
                                                              @click="onHidePUK">Hide</b-button>
                                                </div>
                                                <b-button v-if="! token.puk" size="sm" variant="outline-primary"
                                                          @click="onGetPUK">View</b-button>

                                            </dd>
                                            <dt v-if="policy && policy.allowInventoryManagement">Physical Status</dt>
                                            <dd v-if="policy && policy.allowInventoryManagement">
                                                <span v-if="tokenPhysicalStatus">{{tokenPhysicalStatus}}&nbsp;&nbsp;</span>
                                                <b-button size="sm" variant="outline-primary" @click="onUpdatePhysicalStatus">Update</b-button>
                                            </dd>
                                            <dt v-if="token.creationTimeString">Created</dt>
                                            <dd v-if="token.creationTimeString">{{token.creationTimeString}}</dd>
                                            <dt>Expiration</dt>
                                            <dd>
                                                {{token.expirationTime ? token.expirationTimeString : "None"}}
                                                <b-button v-if="allowEditExpiration"
                                                          size="sm" variant="outline-primary"
                                                          @click="onEditExpiration">Edit</b-button>
                                            </dd>
                                        </dl>
                                        <dl v-if="token.pivChuid">
                                            <dt>PIV FASC-N</dt>
                                            <dd>{{token.pivFascn}}</dd>
                                            <dt>PIV Card GUID</dt>
                                            <dd>{{token.pivCardGuid}}</dd>
                                            <dt>PIV Expiration</dt>
                                            <dd>{{token.pivExpirationDate}}</dd>
                                        </dl>
                                    </b-tab>
                                    <b-tab v-if="hasTokenAdditionalDetails" :title="tokenAdditionalDetails && (tokenAdditionalDetails.title || 'Additional Details')">
                                        <ReadOnlyFormDisplay :form="tokenAdditionalDetails" :model="tokenAdditionalDetails.dataModel || token"
                                                             :showTitle="false" />
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </b-col>
                        <b-col md="3">
                            <slot name="buttons" />
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    import Url from "../util/Url.js";
    import ReadOnlyFormDisplay from "../components/ReadOnlyFormDisplay.vue";

    import iconYubiKey from "../assets/icons/yubikey.svg";
    import iconSmartCard from "../assets/icons/card.svg";
    import iconPhone from "../assets/icons/phone.svg";

    export default {
        props: {
            token: Object,
            tokenAdditionalDetails: Object,
            policy: Object,
            allowEditExpiration: Boolean,
            puk: String,
            isBusy: Boolean,
            tokenModels: Object
        },
        emits: ["error", "editExpiration", "getPUK", "hidePUK", "updatePhysicalStatus"],
        data() {
            return {

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone
                },
            }
        },
        components: {
            ReadOnlyFormDisplay
        },
        computed: {
            tokenModel() {
                let output = null;

                if (Array.isArray(this.tokenModels) && this.tokenModels.length > 0 && this.token?.modelID) {
                    output = this.tokenModels.find((x) => x.id === this.token.modelID);
                }

                return output;
            },

            tokenPhysicalStatus() {
                return ExpUtil.convertCamelCaseToTitleCase(this.token?.physicalStatus);
            },

            hasTokenAdditionalDetails() {
                //see if tokenAdditionalDetails actually has any info in it.
                //Note: this isn't actually very effective. We need to go through and see if any of the sections has title/instructions,
                //and then also if the dataModel has any values. It's kind of complicated. Might be better if the server just return it.
                let t = this.tokenAdditionalDetails;
                let output = false;
                if (!t) {
                    output = false;
                }
                else if (t.instructions) {
                    output = true;
                }
                else if (Array.isArray(t.sections) && t.sections.length > 0) {
                    for (let section of t.sections) {
                        if (this.doesSectionHaveData(section, t.dataModel)) {
                            output = true;
                            break;
                        }

                    }
                }
                return output;
            }
        },
        async mounted() {

        },
        methods: {

            doesSectionHaveData(section, dataModel) {
                let hasData = false;
                if (Array.isArray(section.rows)) {
                    for (let row of section.rows) {
                        if (Array.isArray(row.fields)) {
                            for (let field of row.fields) {
                                let val = this.formatFieldValue(field, dataModel);
                                if (val !== undefined && val !== null && val !== "") {
                                    hasData = true;
                                    break;
                                }
                            }
                        }
                        if (hasData) break;
                    }
                }
                return hasData;
            },

            formatFieldValue(field, model) {
                if (!field || !model) return;

                let val = field.propertyName === undefined ? null : model[field.propertyName];
                let output = val;


                if (val === undefined || val === null) {
                    output = "";
                }
                else if (Array.isArray(field.options) && field.options.length > 0) {
                    output = field.options.find(x => x.value === val)?.text ?? val;
                }
                return output;
            },


            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            onEditExpiration() {
                this.$emit("editExpiration", this.token);
            },

            onGetPUK() {
                this.$emit("getPUK", this.token);
            },

            onHidePUK() {
                this.$emit("hidePUK", this.token);
            },

            onUpdatePhysicalStatus() {
                this.$emit("updatePhysicalStatus", this.token);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (typeof this.client?.handleError === "function") {
                    this.client.handleError(ex);
                }
                else {
                    if (ex.status === 401) {
                        this.raiseNeedsAuthEvent();
                    }
                    else {
                        this.raiseErrorEvent(ex);
                    }
                }
            },

            clearError() {
                this.raiseErrorEvent(null);
            },


            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },

            makePhotoDataUrl(base64Image) {
                return Url.makeDataUrl(base64Image, "image/jpeg");
            },

        }
    };
</script>

<style scoped>
</style>