import Vue from 'vue';
import * as VueRouter from 'vue-router';

import App from './App.vue';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueRouter)

//Vue.config.productionTip = true; //removed in Vue 3


const routes = [
    //we'll use the first part as the page, and let App figure it out. Maybe not the preferred strategy, but I think it is easier to retrofit into my existing approach.
    { path: '/', component: App },
    { path: '/:page', component: App },
    { path: '/:page/:subpage', component: App },
    //{ path: '/login', component: App },
    //{path: '/reports', component: App },
    //{ path: '/register', component: App }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes, // short for `routes: routes`
    //routes: routes,
    //mode: "history"
})

// 5. Create and mount the root instance.
const app = Vue.createApp({ render: h => h(App)})
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)


app.mount('#app')
