<template>
    <!--<footer>
        <div class="left"><slot name="left"></slot></div>
        <div class="center"><slot></slot></div>
        <div class="right"><slot name="right"></slot></div>
    </footer>-->
    <b-container class="ep-footer">
        <b-row class="p-0">
            <b-col class="p-0">
                <b-navbar toggleable="sm" type="dark">
                    <b-navbar-nav class="mr-auto">
                        <b-nav-text><slot name="left"></slot></b-nav-text>
                    </b-navbar-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-text><slot name="right"></slot></b-nav-text>
                    </b-navbar-nav>
                    <b-navbar-brand class="ml-2"><img src="../assets/branding/footer-logo.png" alt="EntryPoint" class="ep-footer-logo"></b-navbar-brand>
                </b-navbar>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
    export default {
        props: {
            title: String
        }
    };
</script>

<style scoped>
    .ep-footer-logo {
        height: 48px;
    }

    .ep-footer {
        font-weight: bold;
    }

    footer {
        display: flex;
        background: url(../assets/branding/footer-logo.png) no-repeat right center;
        background-size: contain;
        background-position: right 10px top 50%; /*put the background image 10 px from the right edge and centered vertically.*/
        height: 48px;
        margin: 10px 0;
        /*color: var(--color-text-light);*/
        justify-content: center; /*space-between;*/ /*center;*/
        align-items: center;
        text-align: center;
        padding: 0 48px;
        font-weight: bold;
        font-style: normal;
        font-size: 100%;
        letter-spacing: normal;
        width: 100%;
    }

    div {
        vertical-align: middle;
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .left {
        text-align: left;
        /*margin-right: auto;*/
    }

    .right {
        text-align: right;
        /*margin-left: auto;*/
    }

    @media screen and (max-width: 768px) {
    }

    @media screen and (max-width: 576px) {
        .ep-footer-logo {
            height: 32px;
        }

        .ep-footer {
            font-size: 12px;
        }
    }

</style>