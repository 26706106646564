import ExpUtil from "../util/ExpUtil.js";
import DConsole from "../util/DConsole.js";

import * as x509 from "@peculiar/x509";
//import X509Certificate from "@peculiar/x509";

class CertificateSearchResult {
    constructor(options) {
        Object.assign(this, options);
    }

    get certX509() {
        let cert = this._certX509;

        if (!cert && this.certificate) {
            let rawData = this.certificate.rawData || this.certificate;
            try {
                cert = new x509.X509Certificate(rawData);
                this._certX509 = cert;

                DConsole.log(cert);
            }
            catch (ex) {
                DConsole.log(ex);
            }
        }
        return cert;
    }

    get subjectUPN() {
        let output = null;
        let ext = this.subjectAlternateName;
        if (ext) {
            let values = ext.upn;
            if (Array.isArray(values) && values.length > 0) {
                output = values[0];
            }
        }
        return output;
    }

    get subjectEmail() {
        let output = null;
        let ext = this.subjectAlternateName;
        if (ext) {
            let values = ext.email;
            if (Array.isArray(values) && values.length > 0) {
                output = values[0];
            }
        }
        return output;
    }

    get subjectGuid() {
        let output = null;
        let ext = this.subjectAlternateName;
        if (ext) {
            let values = ext.guid;
            if (Array.isArray(values) && values.length > 0) {
                output = values[0];
            }
        }
        return output;
    }

    get subjectAlternateName() {
        let output = null;
        let cert = this.certX509;
        if (Array.isArray(cert?.extensions)) {
            for (let ext of cert.extensions) {
                if (ext.type === "2.5.29.17") { //subject alternative name extension
                    output = ext;
                    break;
                }
            }
        }
        return output;
    }

    get notBeforeString() {
        let cert = this.certX509;
        return ExpUtil.formatEventTime(cert.notBefore);
    }

    get notAfterString() {
        let cert = this.certX509;
        return ExpUtil.formatEventTime(cert.notAfter);
    }

    get lastConfirmationTimeString() {
        let output = null;
        let time = this.status?.lastConfirmationTime;
        if (time) {
            output = ExpUtil.formatEventTime(time);
        }
        return output;
    }

    get importTimeString() {
        let output = null;
        let time = this.importTime;
        if (time) {
            output = ExpUtil.formatEventTime(time);
        }
        return output;
    }

    get isValid() {
        let now = new Date();
        return !this.status?.isRevoked && this.certX509 && this.certX509.notAfter > now && this.certX509.notBefore < now;
    }

    // Returns a friendly, short name for the cert.
    get name() {
        let cert = this.certX509;
        let name = CertificateSearchResult.getCommonNameFromDistinguishedName(cert.subjectName);
        if (name === null || name === "") {
            name = CertificateSearchResult.getLastPartOfDistinguishedName(cert.subjectName);
        }
        if (name === null || name === "") {
            name = cert.subjectName?.toString() || "";
        }

        if (Array.isArray(this.associations) && this.associations.length > 0) {
            let slot = this.shortPivSlotName;
            if (slot) {
                name = slot + ": " + name;
            }
        }

        return name;
    }

    get shortPivSlotName() {
        let name = null;

        if (Array.isArray(this.associations) && this.associations.length > 0) {
            let slot = this.associations[0].pivKeySlotShortLabel;
            let slotNumber = this.hexPivSlot;
            if (slot) {
                name = slot;
                if (slotNumber) {
                    name = `${slot} (${slotNumber})`;
                }
            }

        }

        return name;
    }

    get longPivSlotName() {
        let name = null;

        if (Array.isArray(this.associations) && this.associations.length > 0) {
            let slot = this.associations[0].pivKeySlotLongLabel;
            let slotNumber = this.hexPivSlot;
            if (slot) {
                name = slot;
                if (slotNumber) {
                    name = `${slot} (${slotNumber})`;
                }
            }

        }

        return name;
    }

    get hexPivSlot() {
        let hex = "";
        if (Array.isArray(this.associations) && this.associations.length > 0) {
            let slotNumber = this.associations[0].pivKeySlot;
            if (slotNumber) {
                hex = Number(slotNumber).toString(16).padStart(2, 0);
            }
        }
        return hex;
    }

    get role() {
        let output = null;

        if (Array.isArray(this.associations) && this.associations.length > 0) {
            output = this.associations[0].role;

        }

        return output;
    }

    get isPivDerivation() {
        return typeof this.role === "string" && this.role.toLowerCase() === "pivderivation";
    }

    //True if these refer to the same certificate (by thumbprint)
    equals(other) {
        let output = false;
        if (other && other instanceof CertificateSearchResult) {
            if (this.certificate && other.certificate) {
                output = this.certificate.thumbprint === other.certificate.thumbprint;
            }
        }

        return output ? true : false;
    }


    //get thumbprint() {
    //    let cert = this.certX509;
    //    let thumb = await cert.getThumbprint();
    //    return thumb;
    //}

    _certX509 = null;

    static getLastPartOfDistinguishedName(dn) {
        let name = "";

        let json = dn.toJSON();
        if (Array.isArray(json) && json.length > 0) {
            //get the last element only, so it's like the CN.
            DConsole.log(json);
            let part = json[json.length - 1];

            let keys = Object.keys(part);
            if (keys.length > 0) {
                let firstKey = keys[0];  //this should be "CN" or something like that.
                let names = part[firstKey];
                if (Array.isArray(names)) {
                    name = names.join(" ");
                }
                else {
                    name = names;
                }
            }
        }
        return name;
    }

    static getCommonNameFromDistinguishedName(dn) {
        let name = "";

        let json = dn.toJSON();
        if (Array.isArray(json) && json.length > 0) {
            //get the last element only, so it's like the CN.
            DConsole.log(json);
            for (let part of json) {
                let keys = Object.keys(part);
                if (keys.length > 0) {
                    for (let key of keys) {
                        if (key === "CN") {
                            let names = part[key];
                            if (Array.isArray(names)) {
                                name = names.join(" ");
                            }
                            else {
                                name = names;
                            }
                            break;
                        }
                    }
                }
                if (name !== null && name !== "") {
                    break;
                }
            }
        }
        return name;
    }
}

export default CertificateSearchResult;