import Url from '../util/Url';
//import Guid from '../util/Guid';
import DConsole from '../util/DConsole';
import BaseClient from '../library/BaseClient';

class ManageClient extends BaseClient {
    constructor(baseUrl) {
        super();

        this.session = null;
        this.baseUrl = baseUrl || Url.combine(Url.removePathPart(window.location.href), "manage");
        if (process.env.NODE_ENV === "development") {
            //when in dev mode (say, on my laptop in a debugger) point to server running in a different debug process.
            let url = new URL(window.location.href);
            this.baseUrl = `https://${url.hostname}:5001/manage`; //"https://localhost:5001/";

        }

        DConsole.log("baseURL: " + this.baseUrl);
    }

    async ping() {
        return await this.get("ping");
    }

    async startSession() {
        return await this.postJson("start", {});
    }

    async endSession() {
        return await this.postJson("end", {});
    }

    async getManagerPolicy() {
        return await this.postJson("getManagerPolicy", {});
    }

    async listIdentities(searchString, mustHaveToken, searchForTokenSerialNumber, includeTokensWithoutIdentity, pageSize, nextPageID) {
        let input = {
            SearchString: searchString,
            MustHaveToken: mustHaveToken,
            PageSize: pageSize,
            NextPageID: nextPageID,
            SearchForTokenSerialNumber: searchForTokenSerialNumber,
            IncludeTokensWithoutIdentity: includeTokensWithoutIdentity
        };
        return await this.postJson("listIdentities", input);
    }

    async getIdentity(id) {
        let input = {
            ID: id
        };
        return await this.postJson("getIdentity", input);
    }

    async getIdentityDetails(id) {
        let input = {
            ID: id
        };
        return await this.postJson("getIdentityDetails", input);
    }

    async getIdentityOverview(id) {
        let input = {
            ID: id
        };
        return await this.postJson("getIdentityOverview", input);
    }

    async getHistoryForCardholder(cardholderID, pageSize, nextPageID) {
        let input = {
            CardholderID: cardholderID,
            PageSize: pageSize,
            NextPageID: nextPageID
        };
        return await this.postJson("getHistoryForCardholder", input);
    }

    async getHistoryForAuthority(authorityID, pageSize, nextPageID) {
        let input = {
            AuthorityID: authorityID,
            PageSize: pageSize,
            NextPageID: nextPageID
        };
        return await this.postJson("getHistoryForAuthority", input);
    }

    async getAuthorizedActionsForCardholder(cardholderID) {
        let input = {
            CardholderID: cardholderID
        };
        return await this.postJson("getAuthorizedActionsForCardholder", input);
    }

    async enableIdentity(id) {
        let input = {
            ID: id
        };
        return await this.postJson("enableIdentity", input);
    }

    async disableIdentity(id) {
        let input = {
            ID: id
        };
        return await this.postJson("disableIdentity", input);
    }

    async provisionIdentity(id, target, tokenFamily, tokenID) {
        let input = {
            CardholderID: id,
            Target: target,
            TokenFamily: tokenFamily,
            TokenID: tokenID
        };
        return await this.postJson("provisionIdentity", input);
    }

    async deprovisionIdentity(id, target, tokenFamily, tokenID) {
        let input = {
            CardholderID: id,
            Target: target,
            TokenFamily: tokenFamily,
            TokenID: tokenID
        };
        return await this.postJson("deprovisionIdentity", input);
    }

    async getIdentityFromAssociatedDataStore(id, dataStoreName) {
        let input = {
            IdentityID: id,
            DataStoreName: dataStoreName
        };
        return await this.postJson("getIdentityFromAssociatedDataStore", input);
    }

    async getTokenDetails(family, tokenID, getPUK, includeIdentity) {
        let input = {
            Family: family,
            TokenID: tokenID,
            GetPUK: getPUK ? true : false,
            IncludeIdentity: includeIdentity ? true : false
        };
        let tokenDetails = await this.postJson("getTokenDetails", input);

        //Go through and make sure that if the TokenInfo objects are duplicated, change them so they are the same exact object.
        if (tokenDetails) {
            if (Array.isArray(tokenDetails?.Identity?.Tokens)) {
                let unfiltered = tokenDetails.Identity.Tokens;
                let filtered = unfiltered.filter((x) => x.Family !== tokenDetails.Token.Family || x.TokenID !== tokenDetails.Token.TokenID);
                if (filtered.length < unfiltered.length) {
                    filtered.unshift(tokenDetails.Token); //insert the main Token object a the beginning of the array.
                    tokenDetails.Identity.Tokens = filtered;
                }
            }
        }
        return tokenDetails;
    }

    async getTokenPUK(family, tokenID) {
        let puk = null;
        let details = await this.getTokenDetails(family, tokenID, true, false);
        if (details) {
            puk = details.puk;
        }
        return puk;
    }

    async saveTokenDetails(family, tokenID, locationTrackingID, photo) {
        let input = {
            Family: family,
            TokenID: tokenID,
            LocationTrackingID: locationTrackingID,
            Photo: photo
        };
        return await this.postJson("saveTokenDetails", input);
    }

    async saveTokenExpiration(family, tokenID, expirationTime) {
        let input = {
            Family: family,
            TokenID: tokenID,
            ExpirationTime: expirationTime,
            SetExpirationTime: true
        };
        return await this.postJson("saveTokenDetails", input);
    }

    async saveTokenPhysicalStatus(family, tokenID, physicalStatus) {
        let input = {
            Family: family,
            TokenID: tokenID,
            PhysicalStatus: physicalStatus,
            SetPhysicalStatus: true
        };
        return await this.postJson("saveTokenDetails", input);
    }

    async revokeToken(family, tokenID, reason, alsoDelete, revokeCertificates, deleteCertificates) {
        let input = {
            Family: family,
            TokenID: tokenID,
            Reason: reason,
            RevokeCertificates: revokeCertificates ? true : false,
            DeleteCertificates: deleteCertificates ? true : false,
            Delete: alsoDelete ? true : false
        };
        return await this.postJson("revokeToken", input);
    }

    async getFidoKeysForToken(family, tokenID) {
        let input = {
            Family: family,
            TokenID: tokenID,
        };
        return await this.postJson("getFidoKeys", input);
    }

    async getFidoKeysForCardholder(cardholderID) {
        let input = {
            CardholderID: cardholderID,
        };
        return await this.postJson("getFidoKeys", input);
    }

    async saveFidoKeyExpiration(credentialID, expirationTime) {
        let input = {
            CredentialID: credentialID,
            ExpirationTime: expirationTime,
            SetExpirationTime: true
        };
        return await this.postJson("saveFidoKeyDetails", input);
    }

    async revokeFidoKey(credentialID, reason, alsoDelete) {
        let input = {
            CredentialID: credentialID,
            Reason: reason,
            Delete: alsoDelete ? true : false
        };
        return await this.postJson("revokeFidoKey", input);
    }

    async unrevokeFidoKey(credentialID, reason) {
        let input = {
            CredentialID: credentialID,
            Reason: reason,
            Delete: false
        };
        return await this.postJson("unrevokeFidoKey", input);
    }

    async getCertificatesForToken(family, tokenID, validate) {
        let input = {
            Family: family,
            TokenID: tokenID,
            ValidateCertificates: validate ? true : false
        };
        return await this.postJson("getCertificates", input);
    }

    async getCertificatesForCardholder(cardholderID, validate) {
        let input = {
            CardholderID: cardholderID,
            ValidateCertificates: validate ? true : false
        };
        return await this.postJson("getCertificates", input);
    }

    async getCredentialsForCardholder(cardholderID) {
        let input = {
            CardholderID: cardholderID,
        };
        return await this.postJson("getCredentials", input);
    }

    async deleteCertificateAssociations(thumbprint, cardholderID) {
        let input = {
            Thumbprint: thumbprint,
            Associations: [
                {
                    IdentityID: cardholderID
                }
            ],
            DeleteCertificateIfNoMoreAssociations: true
        }
        return await this.postJson("deleteCertificateAssociations", input);
    }

    async revokeCertificate(thumbprint, cardholderID, reason) {
        let input = {
            Thumbprint: thumbprint,
            CardholderID: cardholderID,
            Reason: reason
        }
        return await this.postJson("revokeCertificate", input);
    }

    async getPIVChuidForToken(family, tokenID) {
        let input = {
            TokenFamily: family,
            TokenID: tokenID
        };
        return await this.postJson("getPIVChuid", input);
    }

    async getDevicesForIdentity(identityID) {
        let input = {
            CardholderID: identityID,  //message uses CardholderID because we are reusing the same message class.
        };
        return await this.postJson("getUserDevices", input);
    }

    async revokeDevice(deviceID, reason, alsoDelete) {
        let input = {
            DeviceID: deviceID,
            Reason: reason,
            Delete: alsoDelete ? true : false
        };
        return await this.postJson("revokeUserDevice", input);
    }

    async saveDeviceExpiration(deviceID, expirationTime) {
        let input = {
            DeviceID: deviceID,
            ExpirationTime: expirationTime,
            SetExpirationTime: true
        };
        return await this.postJson("saveUserDeviceDetails", input);
    }




    async getAvailableActions() {
        return await this.postJson("getAvailableActions");
    }

    async getAvailableRemoteActions() {
        return await this.postJson("getAvailableRemoteActions");
    }

    async getGroups() {
        return await this.postJson("getGroups");
    }

    async getBadgePolicy() {
        return await this.postJson("getBadgePolicy");
    }

    async authorizeSelfServiceActionForCardholder(action, cardholderID) {
        let input = {
            Action: action,
            CardholderID: cardholderID
        };
        return await this.postJson("schedule", input);
    }

    async authorizeSelfServiceActionForGroup(action, groupID) {
        let input = {
            Action: action,
            GroupID: groupID
        };
        return await this.postJson("schedule", input);
    }

    async authorizeSelfServiceActionForToken(action, cardholderID, tokenFamily, tokenID) {
        let input = {
            Action: action,
            CardholderID: cardholderID,
            TokenFamily: tokenFamily,
            TokenID: tokenID
        };
        return await this.postJson("schedule", input);
    }

    async deleteSelfServiceAction(scheduledActionID) {
        let input = {
            ID: scheduledActionID
        };
        return await this.postJson("deleteScheduledAction", input);
    }

    async getHistory(reportName, query) {
        let input = {
            ReportName: reportName,
            Query: query
        };
        let output = await this.postJson("getHistory", input);

        ManageClient.cacheHistoryQuery(input);

        return output;
    }

    async getReportOverview() {
        return await this.postJson("getReportOverview");
    }

    async getLicenseInfo() {
        return await this.postJson("getLicenseInfo");
    }

    async getInventoryManagementDescriptor() {
        return await this.postJson("getInventoryManagementDescriptor");
    }

    async getTokenModels() {
        return await this.postJson("getTokenModels");
    }

    async getTokenInventoryStatistics() {
        return await this.postJson("getTokenInventoryStatistics");
    }

    async getTokenInventorySummary() {
        return await this.postJson("getTokenInventorySummary");
    }


    async getTokenBatch(batchID) {
        return await this.postJson("getTokenBatch", batchID);
    }

    async saveTokenBatch(tokenBatch) {
        return await this.postJson("saveTokenBatch", tokenBatch);
    }

    async deleteTokenBatch(batchID) {
        return await this.postJson("deleteTokenBatch", batchID);
    }

    async getAllTokenBatches() {
        return await this.postJson("getAllTokenBatches");
    }


    async findTokens(tokenQuery) {
        return await this.postJson("findTokens", tokenQuery);
    }

    async doEditTokenBatchStep(batchID, stepInput) {
        DConsole.log("doEditTokenBatchStep");
        DConsole.log(stepInput);

        let input = {
            BatchID: batchID,
            StepInput: stepInput
        };
        return await this.postJson("doEditTokenBatchStep", input);
    }

    async doEditTokenStep(identityID, tokenFamily, tokenID, stepInput) {

        let input = {
            IdentityID: identityID,
            TokenFamily: tokenFamily,
            TokenID: tokenID,
            StepInput: stepInput
        };
        return await this.postJson("doEditTokenStep", input);
    }

    async doAssignTokenStep(identityID, tokenFamily, tokenID, stepInput) {
        let input = {
            IdentityID: identityID,
            TokenFamily: tokenFamily,
            TokenID: tokenID,
            StepInput: stepInput
        };
        return await this.postJson("doAssignTokenStep", input);
            
    }

    async postPing() {
        return await this.postJson("pingWithCert");
    }

    async getCachedIdentity() {
        let output = null;
        let id = ManageClient.getCachedIdentityID();
        if (id && this.session) {
            output = await this.getIdentity(id);
        }
        return output;
    }

    static cacheIdentityID(identityID) {
        try {
            if (identityID) {
                DConsole.log("Caching identity ID...");
                DConsole.log(identityID);

                sessionStorage.setItem("identity", identityID);
            }
            else {
                DConsole.log("Clearing identity cache...");
                sessionStorage.removeItem("identity");
            }
        }
        catch (error) {
            DConsole.log("Unable to cache identity: " + ((error && error.message) || ""));
        }
    }

    static clearCachedIdentityID() {
        ManageClient.cacheIdentityID(null);
    }

    static hasCachedIdentity() {
        let id = ManageClient.getCachedIdentityID();
        return id ? true : false;
    }

    static getCachedIdentityID() {
        DConsole.log("Checking for cached identity...");
        let cached = null;
        try {
            cached = sessionStorage.getItem("identity");
            if (cached) {
                DConsole.log('cached identity:');
                DConsole.log(cached);
            }
        }
        catch (error) {
            DConsole.log("Error trying to get cached identity: " + ((error && error.message) || ""));
        }
        return cached;
    }

    async getCachedHistory() {
        let output = null;
        let query = ManageClient.getCachedHistoryQuery();
        if (query && this.session) {
            output = await this.getHistory(query.Name, query.Query);
        }
        return output;
    }

    static cacheHistoryQuery(historyQuery) {
        try {
            if (historyQuery) {
                DConsole.log("Caching history query...");
                DConsole.log(historyQuery);

                sessionStorage.setItem("historyQuery", historyQuery);
            }
            else {
                DConsole.log("Clearing history query cache...");
                sessionStorage.removeItem("historyQuery");
            }
        }
        catch (error) {
            DConsole.log("Unable to cache history query: " + ((error && error.message) || ""));
        }
    }

    static clearCachedHistoryQuery() {
        ManageClient.cacheHistoryQuery(null);
    }

    static getCachedHistoryQuery() {
        DConsole.log("Checking for cached history query...");
        let cached = null;
        try {
            cached = sessionStorage.getItem("historyQuery");
            if (cached) {
                DConsole.log('cached history query:');
                DConsole.log(cached);
            }
        }
        catch (error) {
            DConsole.log("Error trying to get cached history query: " + ((error && error.message) || ""));
        }
        return cached;
    }
}

export default ManageClient;
