import DConsole from "../util/DConsole.js";
import FidoWebAuthN from "../library/FidoWebAuthN.js";


class WizardHandler {

    constructor(options) {
        if (options) {
            Object.assign(this, options);
        }

        //this.start = this.start.bind(this);
        //this.onNext = this.onNext.bind(this);
        //this.onCancel = this.onCancel.bind(this);
        //this.onPrevious = this.onPrevious.bind(this);
        //this.onSkip = this.onSkip.bind(this);
    }

    isVisible = false;
    step = null;
    action = null;
    error = null;
    isBusy = false;
    busyMessage = null;
    busyDetails = null;
    showNextByDefault = true;
    showCancelByDefault = true;
    showPreviousByDefault = false;
    showSkipByDefault = false;

    //Set this to a function taking a stepInput object set up based on the form.
    doStep = (stepInput) => {
        throw new Error(`doStep callback was not set on WizardHandler. step = ${JSON.stringify(stepInput)}`);
    };

    onFinish = () => { //should take a boolean parameter that indicates whether "ok" was pressed (true) or "cancel" (false)
    };

    async start(action) {
        try {
            this.clearError();
            this.showBusy();

            this.action = action;

            DConsole.log(`Starting action ${action?.name}:`);
            DConsole.log(action);

            this.step = await this.doStep({ Start: true, Action: action?.name });

            this.isVisible = true;
            await this.checkIfFinished();
        }
        catch (error) {
            if (this.isVisible) {
                this.handleError(error);
            }
            else {
                this.hideBusy();
                throw error;
            }
        }
        this.hideBusy();

    }

    async onNext() {
        if (!this.isVisible) return;

        try {
            this.clearError();
            this.showBusy();

            if (this.step?.isFinished) {
                await this.finished(true);
            }
            else if (this.step?.createFIDOKey && this.step?.fidoCreationOptions) {
                this.showBusy("Please follow prompts in the popup.");
                let fidoInput = await this.createFidoKey(this.step.fidoCreationOptions);
                this.showBusy();

                this.step = await this.doStep({ FidoInput: fidoInput });
            }
            else if (this.step?.registerCertificate) {
                this.step = await this.doStep({ RegisterCertificate: true });
            }
            else if (this.step?.form) {
                this.step = await this.doStep({ Form: this.step.form.dataModel });
            }
            else if (this.step?.promptToRegisterDevice) {
                this.showNextButtonByDefault = false;
                this.hideBusy();
                this.step = await this.doStep({ WaitForDeviceRegistration: true });
            }
            else if (this.step?.launchEncoder && this.step?.encoderLaunchUrl) {
                this.showNextButtonByDefault = false;
                //this.hideBusy();
                window.location.href = this.step?.encoderLaunchUrl;
                this.step = await this.doStep({ WaitForEncoder: true });
            }
            else {
                this.step = await this.doStep({});
            }
            await this.checkIfFinished();
        }
        catch (error) {
            DConsole.log(`WizardHandler.onNext caught exception: ${JSON.stringify(error)}`);
            this.handleError(error);
        }
        this.hideBusy();
    }

    async onSkip() {
        if (!this.isVisible) return;

        try {
            this.clearError();
            this.showBusy();

            this.step = await this.doStep({ Skip: true });
            await this.checkIfFinished();
        }
        catch (error) {
            this.handleError(error);
        }

        this.hideBusy();
    }

    async onCancel() {
        try {
            this.clearError();
            this.showBusy();

            await this.finished(false);
        }
        catch (error) {
            this.handleError(error);
        }
        this.hideBusy();
    }

    async onPrevious() {
        try {
            this.clearError();
            this.showBusy();

            if (this.step?.form) {
                this.step = await this.doStep({ Previous: true, Form: this.step.form.dataModel });
            }
            else {
                this.step = await this.doStep({ Previous: true});
            }
            await this.checkIfFinished();

        }
        catch (error) {
            this.handleError(error);
        }
        this.hideBusy();
    }

    async onError(err) {
        this.handleError(err);
    }

    async checkIfFinished() {
        let nextStep = this.step;
        if (nextStep?.isFinished && !nextStep?.label && !nextStep?.description) {
            //We're done and there are no messages, so just end it.
            await this.finished(true);
        }
    }

    closeWizard() {
        this.isVisible = false;
        this.step = null;
        this.action = null;
    }

    async finished(ok) {
        this.closeWizard();

        if (typeof this.onFinish === "function") {
            this.onFinish(ok);
        }
    }

    handleError(ex) {
        DConsole.log(`WizardHandler: handleError( ${JSON.stringify(ex)} )`);
        if (typeof ex === "string") {
            this.error = { message: ex };
        }
        else {
            this.error = ex;
        }
    }

    clearError() {
        this.error = null;
    }

    showBusy(message, details) {
        this.busyMessage = message;
        this.busyDetails = details;
        this.isBusy = true;
    }

    hideBusy() {
        this.isBusy = false;
        this.busyMessage = null;
        this.busyDetails = null;
    }

    async createFidoKey(fidoCreationOptions) {

        let fidoInputForServer = {};
        let fidoClient = new FidoWebAuthN();

        try {
            let fidoResult = await fidoClient.createCredential(fidoCreationOptions);

            fidoInputForServer.FidoCreationResult = fidoResult;
        }
        catch (ex) {
            DConsole.log(ex);
            fidoInputForServer.FidoErrorResult = {
                Message: ex.message,
                Details: null
            };
        }
        return fidoInputForServer;
    }

}

export default WizardHandler;