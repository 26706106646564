import Url from '../util/Url';
//import Guid from '../util/Guid';
import DConsole from '../util/DConsole';

/* eslint no-dupe-class-members: "warn" */  //prevent eslint bug where it thinks two private async members with different names are duplicates.

class BaseClient {

    baseUrl = null;
    session = null;
    useWindowsAuth = false;

    async get(inputUrl, query) {
        return await this.doFetch(inputUrl, "GET", null, null, query);
    }

    async postJson(inputUrl, body, query) {
        return await this.doFetch(inputUrl, "POST", JSON.stringify(body), "application/json", query);
 
    }

    async postForm(inputUrl, form, query) {
        return await this.doFetch(inputUrl, "POST", new URLSearchParams(form), "application/x-www-form-urlencoded", query);
    }

    // file should be a file object. "query" is optional.
    async postFile(inputUrl, file, query) {
        let body = new FormData();
        body.append('file', file, file.name);

        return await this.doFetch(inputUrl, "POST", body, null, query);
    }

    async postJsonAndFiles(inputUrl, inputName, input, files, query) {
        let body = new FormData();

        DConsole.log("*************************postJsonAndFiles: files:")
        DConsole.log(files);

        if (files) {
            for (const x in files) {
                let file = files[x];
                if (file && file.name) {
                    body.append(x, file, file.name);
                }
            }
        }

        body.append(inputName, JSON.stringify(input));

        return await this.doFetch(inputUrl, "POST", body, null, query);
    }

    async doFetch(inputUrl, method, body, contentType, query) {

        let fetchInit = this._getFetchParams(method, body, contentType);

        let url = this._fixUrl(inputUrl, query);

        let response = await this._fetch(url, fetchInit);

        return await this._handleResponse(response);

    }

    // A thin wrapper around the built-in fetch function. All it does is
    // catch exceptions and, in some cases, change the message to make them more 
    // helpful. It will re-throw the exception. It doesn't do any processing of the response.
    async _fetch(url, fetchInit) {
        try {
            return await fetch(url, fetchInit);
        }
        catch (ex) {
            if (ex.message?.startsWith("Failed to fetch")) {
                ex.message = "Unable to reach the server. Please try again or reload the page.";
            }
            throw ex;
        }
    }

    _fixUrl(inputUrl, query) {
        let url = Url.isAbsolute(inputUrl) ? inputUrl : Url.combine(this.baseUrl, inputUrl);
        if (query) {
            url += '?' + (new URLSearchParams(query)).toString();
        }
        DConsole.log(url);
        return url;
    }

    _getFetchParams(method, body, contentType) {
        let headerInit = {
        };

        if (contentType) {
            headerInit["Content-Type"] = contentType;
        }

        if (this.session && this.session.token_type && this.session.access_token) {
            headerInit.Authorization = this.session.token_type + " " + this.session.access_token;
        }


        let fetchInit = {
            method: method,
            mode: "cors",
            credentials: "include",
            headers: new Headers(headerInit)
        };

        if (body !== undefined && body !== null) {
            fetchInit.body = body;
        }

        DConsole.log("fetchInit:");
        DConsole.log(fetchInit);

        return fetchInit;
    }

    async _handleResponse(response) {
        DConsole.log(response);

        if (response.ok) {
            let contentType = response?.headers.get("Content-Type");
            let mimeType = contentType?.split(";")[0]?.trim();

            let contentDisp = response?.headers.get("Content-Disposition");
            let filename = null;

            if (response.status === 204) {
                //204 means no data in response, but still success.
                return Promise.resolve(null);
            }
            else if (typeof contentDisp === "string" && contentDisp.startsWith("attachment")) {
                //it's a file download.
                //Extract the filename part. Normally this header for downloads is 
                //  Content-Disposition: attachment; filename="myfile.txt"
                let matches = contentDisp.match(/filename="?([^"]*)"?$/);
                if (matches.length > 1) {
                    filename = matches[1];
                    DConsole.log("filename: " + filename);
                }
                let blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = objectURL;
                if (filename) {
                    a.download = filename;
                }
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                URL.revokeObjectURL(objectURL);
                return Promise.resolve(filename);
            }
            else if (mimeType === "application/json" || mimeType.endsWith("json")) {
                DConsole.log(response);
                return response.json();
            }
            else if (mimeType.startsWith("image/")) {
                let blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                return Promise.resolver(objectURL);
            }
            else {
                throw new Error(`Unsupported Content-Type: ${mimeType}`);
            }
        }
        else {
            return await this._throwErrorResponse(response);
        }
    }

    async _throwErrorResponse(response) {
        DConsole.log("throw error response...");

        let error = null;

        let contentType = response?.headers.get("Content-Type");
        let mimeType = contentType?.split(";")[0]?.trim();


        if (mimeType === "application/json") {
            let errorResponse = await response.json();
            DConsole.log(errorResponse);

            if (errorResponse?.error) {
                error = new Error(errorResponse.error_description || errorResponse.error || `Error: ${(response?.status || "Unknown")}.`);
                error.body = errorResponse;
                error.error = errorResponse.error;
                error.state = errorResponse.state;
            }
        }
        else if (mimeType === "application/problem+json") {
            let r = await response.json();
            DConsole.log(r);

            error = new Error(r.title || r.detail || `Error: ${response.status}.`);
            error.body = r;
            error.error = r.detail;
        }

        if (!error) {
            error = new Error(`${response?.statusText || "Error"}`);
        }

        error.response = response;
        error.status = response.status;
        error.statusText = response.statusText;

        throw error;
    }
}

export default BaseClient;