<template>
    <b-container fluid>
        <b-row align-v="start">
            <b-col align-v="start">
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy" rounded="sm" opacity="0.6">

                            <b-jumbotron :header="(policy && policy.title) || 'Inventory'" :lead="(policy && policy.text) || 'Manage your organization\'s inventory of security tokens and badges.'" border-variant="secondary">
                                <b-row>
                                    <b-col>
                                        <h1 v-if="policy && policy.summaryTitle">{{policy.summaryTitle}}</h1>

                                        <b-card v-if="summary && policy && policy.showSummary" border-variant="dark" no-body>
                                            <b-tabs card>
                                                <b-tab v-if="summary.summaryByBatch" title="By Batch">
                                                    <b-card-title>Token Batches</b-card-title>
                                                    <b-card-text></b-card-text>
                                                    <b-card-body>
                                                        <b-table id="summaryTableBatches"
                                                                    sticky-header="70vh" striped hover
                                                                    :items="summary.summaryByBatch"
                                                                    :fields="summaryByBatchFields"
                                                                    primary-key="batchID"
                                                                    :busy="isLoadingSummary"
                                                                    sort-by="batchLabel" :sort-desc="true"
                                                                    :current-page="summaryByBatchPage" :per-page="summaryRowsPerPage"
                                                                    responsive outlined>
                                                        </b-table>

                                                    </b-card-body>

                                                </b-tab>
                                                <b-tab v-if="summary.summaryByLocation" title="By Location">
                                                    <b-card-title>Tokens by Location</b-card-title>
                                                    <b-card-text></b-card-text>
                                                    <b-card-body>
                                                        <b-table id="summaryTableLocations"
                                                                    sticky-header="70vh" striped hover
                                                                    :items="summary.summaryByLocation"
                                                                    :fields="summaryByLocationFields"
                                                                    primary-key="batchLocationName"
                                                                    :busy="isLoadingSummary"
                                                                    sort-by="batchLocationName" :sort-desc="true"
                                                                    :current-page="summaryByLocationPage" :per-page="summaryRowsPerPage"
                                                                    responsive outlined>
                                                        </b-table>

                                                    </b-card-body>

                                                </b-tab>
                                                <b-tab v-if="summary.summaryByModel" title="By Model">
                                                    <b-card-title>Tokens by Model</b-card-title>
                                                    <b-card-text></b-card-text>
                                                    <b-card-body>
                                                        <b-table id="summaryTableModels"
                                                                    sticky-header="70vh" striped hover
                                                                    :items="summary.summaryByModel"
                                                                    :fields="summaryByModelFields"
                                                                    primary-key="modelID"
                                                                    :busy="isLoadingSummary"
                                                                    sort-by="modelLabel" :sort-desc="true"
                                                                    :current-page="summaryByModelPage" :per-page="summaryRowsPerPage"
                                                                    responsive outlined>
                                                        </b-table>

                                                    </b-card-body>

                                                </b-tab>
                                            </b-tabs>
                                        </b-card>

                                        <b-card v-if="batches && policy && policy.showBatches" border-variant="dark">
                                            <b-card-title></b-card-title>
                                            <b-card-text></b-card-text>
                                            <b-card-body>
                                                <b-table id="batchesTable"
                                                         sticky-header="70vh" striped hover
                                                         :items="batches"
                                                         :fields="batchesFields"
                                                         primary-key="id"
                                                         :busy="isLoadingBatches"
                                                         sort-by="label" :sort-desc="true"
                                                         :current-page="batchesPage" :per-page="batchesRowsPerPage"
                                                         responsive outlined>
                                                    <!--
                <template #cell(cardholder)="data">
                    <a v-if="data.item.cardholderID"
                       href="#" @click.prevent="raiseGoHomeEvent(data.item.cardholderID, data.item.cardholderName)">{{ data.item.cardholderName }}</a>
                </template>

                <template #cell(authority)="data">
                    <a v-if="data.item.authorityID"
                       href="#" @click.prevent="raiseGoHomeEvent(data.item.authorityID, data.item.authorityName)">{{ data.item.authorityName }}</a>
                </template>
                -->
                                                </b-table>

                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-card v-if="policy && Array.isArray(policy.newBatchActions) && policy.newBatchActions.length > 0"
                                                border-variant="dark">
                                            <b-card-title>Add Inventory</b-card-title>
                                            <b-button-group>
                                                <b-button v-for="a in policy.newBatchActions" :key="a.name" @click="onAddWizard(a)" class="mr-2">{{a.label}}</b-button>
                                            </b-button-group>
                                        </b-card>

                                    </b-col>
                                </b-row>
                                <b-modal v-model="isAdding" no-close-on-backdrop size="lg" scrollable centered>
                                    <template #default>
                                        <b-alert v-model="isError" variant="danger" dismissible class="mx-auto text-center alert">
                                            {{error ? (error.message || error) : null}}
                                        </b-alert>

                                        <Wizard :step="addWizardStep" :model="addWizardModel"
                                                @next="onAddWizardNext" @skip="onAddWizardSkip" @previous="onAddWizardPrevious" @cancel="onAddWizardCancel" />
                                    </template>

                                    <template #modal-header>

                                    </template>
                                    <template #modal-footer>

                                    </template>
                                </b-modal>
                            </b-jumbotron>

                            <template #overlay>
                                <b-card class="text-center">
                                    <b-card-text class="mx-auto">{{busyMessage || "Please wait..."}}</b-card-text>
                                    <b-spinner class="mx-auto"></b-spinner>
                                </b-card>
                            </template>

                        </b-overlay>
                    </b-col>
                </b-row>

            </b-col>

        </b-row>

    </b-container>
</template>

<script>
    //import SearchBox from "./SearchBox.vue";
    import ManageClient from "../library/ManageClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubikey.svg";
    import iconSmartCard from "../assets/icons/card.svg";
    import iconPhone from "../assets/icons/phone.svg";
    import FidoWebAuthN from "../library/FidoWebAuthN.js";
    import Wizard from "../components/Wizard.vue";
    //import ReadOnlyFormDisplay from "../components/ReadOnlyFormDisplay.vue";

    export default {
        props: {
            msg: String,
            auth: Object
        },
        emits: ['needsAuth', 'error', 'interface', 'goHome'],
        data() {
            return {
                showConfirm: false,
                client: new ManageClient(),

                policy: null,

                summary: null,
                batches: null,
                batchesFields: null,
                batchesPage: 0,
                batchesRowsPerPage: 20,
                isLoadingBatches: false,

                isLoadingSummary: false,
                summaryByBatchFields: null,
                summaryByBatchPage: 0,
                summaryByLocationFields: null,
                summaryByLocationPage: 0,
                summaryByModelFields: null,
                summaryByModelPage: 0,
                summaryRowsPerPage: 20,

                isBusy: false,
                busyMessage: "Please wait...",
                busyDetails: null,

                isError: false,
                error: null,

                isAdding: false,
                addWizardActionName: null,
                addWizardStep: null,
                addWizardModel: null,

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone
                },
            }
        },
        components: {
            //ReadOnlyFormDisplay
            Wizard
        },
        computed: {
        },
        async mounted() {
            try {

                DConsole.log("Admin component mounted.");
                DConsole.log(this.auth);

                this.client.session = this.auth;

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                await this.reload();

            }
            catch (ex) {
                this.handleError(ex);

            }
            this.hideBusy();

        },
        methods: {

            async reload() {

                this.showBusy("Loading...");
                try {
                    this.policy = await this.client.getInventoryManagementDescriptor();



                    if (this.policy?.showSummary) {
                        //Use a local variable and assign it to the member below so that Vue notices the changes that we
                        //are making to properties of the object.
                        
                        let summary = await this.client.getTokenInventorySummary();
                        summary.summaryByBatch = ExpUtil.getCaseInsensitiveProxy(summary.summaryByBatch);
                        summary.summaryByLocation = ExpUtil.getCaseInsensitiveProxy(summary.summaryByLocation);
                        summary.summaryByModel = ExpUtil.getCaseInsensitiveProxy(summary.summaryByModel);

                        this.summaryByBatchFields = this.convertFormToTableFields(this.policy?.summaryByBatch);
                        this.summaryByLocationFields = this.convertFormToTableFields(this.policy?.summaryByLocation);
                        this.summaryByModelFields = this.convertFormToTableFields(this.policy?.summaryByModel);

                        this.summary = summary;
                    }
                    else {
                        this.summary = null;
                    }

                    if (this.policy?.showBatches) {

                        this.batchesFields = this.convertFormToTableFields(this.policy?.batchInfoForm);

                        let batches = await this.client.getAllTokenBatches();

                        //Case insensitive proxy will make the forms work even if the property name case is wrong.
                        this.batches = ExpUtil.getCaseInsensitiveProxy(batches);
                    }
                    else {
                        this.batches = null;
                    }
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.hideBusy();
            },


            convertFormToTableFields(form) {
                let output = null;
                if (form && Array.isArray(form.sections) && form.sections.length > 0) {
                    output = [];
                    for (let section of form.sections) {
                        for (let row of section.rows) {
                            for (let f of row.fields) {
                                let x = {};
                                x.key = f.propertyName;
                                x.label = f.label;
                                x.sortable = true; //could make this a settable in the FormDescriptor
                                output.push(x);
                            }
                        }
                    }
                    if (output.length > 1) {
                        output[0].stickyColumn = true;
                        output[0].isRowHeader = true;
                    }
                }
                return output;

            },


            async onAddWizard(action) {
                try {
                    this.clearError();

                    DConsole.log("onAddWizard:");
                    DConsole.log(action);
                    this.addWizardActionName = action.Name;

                    this.addWizardModel = {};

                    this.addWizardStep = await this.client.doEditTokenBatchStep(this.addWizardModel?.id || null, { Action: action.name, Start: true });

                    this.isAdding = true;
                    await this.checkIfAddWizardFinished();
                }
                catch (error) {
                    this.handleError(error);
                }

            },

            async onAddWizardNext() {
                if (!this.isAdding) return;

                try {
                    this.clearError();

                    if (this.addWizardStep?.form && this.addWizardModel) {

                        this.addWizardStep = await this.client.doEditTokenBatchStep(this.addWizardModel?.id || null, { Form: this.addWizardStep.form.dataModel });
                    }
                    else {
                        this.addWizardStep = await this.client.doEditTokenBatchStep(this.addWizardModel?.id || null, {});
                    }
                    await this.checkIfAddWizardFinished();
                }
                catch (error) {
                    this.handleError(error);
                }

            },

            async onAddWizardSkip() {
                if (!this.isAdding) return;

                try {
                    this.clearError();

                    this.addWizardStep = await this.client.doEditTokenBatchStep(this.addWizardModel?.id || null, { Skip: true });
                    await this.checkIfAddWizardFinished();
                }
                catch (error) {
                    this.handleError(error);
                }
            },

            async onAddWizardCancel() {
                try {
                    this.clearError();

                    await this.addWizardFinished();
                }
                catch (error) {
                    this.handleError(error);
                }
            },

            async onAddWizardPrevious() {
                try {
                    this.clearError();

                }
                catch (error) {
                    this.handleError(error);
                }
            },

            async checkIfAddWizardFinished() {
                let nextStep = this.addWizardStep;
                if (nextStep?.isFinished && !nextStep?.label && !nextStep?.description) {
                    //We're done and there are no messages, so just end it.
                    await this.addWizardFinished();
                }
            },

            async closeWizard() {
                this.isAdding = false;
                this.addWizardModel = null;
                this.addWizardStep = null;
                this.addWizardActionName = null;
            },

            async addWizardFinished() {
                await this.closeWizard();

                await this.reload();

            },

            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    //goHome: () => this.goHome()
                });
            },

            raiseNeedsAuthEvent() {
                this.$emit("needsAuth");

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (ex.status === 401) {
                    this.raiseNeedsAuthEvent();
                }
                else if (this.isAdding) {
                    this.error = ex;
                    this.isError = true;
                }
                else {
                    this.raiseErrorEvent(ex);
                }

            },

            clearError() {
                this.raiseErrorEvent(null);
                this.error = null;
                this.isError = false;
            },

            onShowConfirm() {
                this.showConfirm = true;
            },

            raiseGoHomeEvent(identityID, identityName) {
                let identity = null;
                if (identityID) {
                    identity = {
                        name: identityName,
                        id: identityID
                    };
                }
                this.$emit("goHome", identity);
            },

            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },


            async onRegister(action) {
                if (!action || !action.name) return;

                DConsole.log(action);

                let input = {
                    Action: action.name
                };

                this.registerAction = action;
                this.registerClient = this;

                this.isBusy = true;
                try {
                    this.registerStep = await this.client.register(input);
                    this.isRegistering = true;

                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isBusy = false;

            },

            showBusy(message, details) {
                this.isBusy = true;
                this.busyMessage = message;
                this.busyDetails = details;
            },

            hideBusy() {
                this.isBusy = false;
                this.busyMessage = null;
                this.busyDetails = null;
            },

            async submitActionForm(dataModel) {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ Form: dataModel });
                return this.registerStep;

            },

            async submitEmptyStep() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({});
                return this.registerStep;
            },

            async skipActionStep() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ Skip: true });
                return this.registerStep;

            },

            async submitCertificate() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ RegisterCertificate: true });
                return this.registerStep;

            },

            async createAndSubmitFidoKey(fidoCreationOptions) {
                if (!this.isRegistering) return;

                let input = {};
                let fidoClient = new FidoWebAuthN();

                try {
                    let fidoResult = await fidoClient.createCredential(fidoCreationOptions);

                    input.FidoCreationResult = fidoResult;
                }
                catch (ex) {
                    DConsole.log(ex);
                    input.FidoErrorResult = {
                        Message: ex.message,
                        Details: null
                    };
                }
                this.registerStep = await this.client.register({ FidoInput: input });

                return this.registerStep;
            },

            async waitForDeviceRegistration() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ WaitForDeviceRegistration: true });
                return this.registerStep;

            },

            async registrationFinished() {
                if (!this.isRegistering) return;

                this.showBusy("Please wait...");

                try {
                    await this.reload();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isRegistering = false;
                this.registerAction = null;
                this.registerStep = null;

                this.hideBusy();

            },

            async registrationCanceled() {
                if (!this.isRegistering) return;

                this.showBusy("Please wait...");

                //Set this even though the action was canceled. It causes
                //the login client to just refresh and go back to the step it was on.
                this.client.didUserCompleteRegistrationAction = true;

                try {
                    await this.reload();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isRegistering = false;
                this.registerAction = null;
                this.registerStep = null;

                this.hideBusy();

            },


        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

</style>

