<template>
    <b-form @submit.prevent="onSubmit" v-on:keyup.enter="onSubmit">
        <div v-if="form">
            <b-row v-for="(section, sectionIndex) in form.sections" :key="'section' + sectionIndex">
                <b-col cols="12">
                    <b-card-title v-if="section.title">{{section.title}}</b-card-title>
                    <b-card-text v-if="section.instructions">{{section.instructions}}</b-card-text>
                    <b-row v-for="(row, rowIndex) in section.rows" :key="'row' + rowIndex">

                        <b-col v-for="(field, fieldIndex) in row.fields" :key="'field' + fieldIndex"
                               :cols="field.width">
                            <b-form-group :label="field.label !== undefined && field.label !== null && field.controlType !== 'CheckBox' ? ((field.label || field.propertyName) + (field.isRequired ? ' *' : '')) : null"
                                          :label-for="'field_' + field.propertyName"
                                          :valid-feedback="field.validFeedback" :invalid-feedback="field.invalidFeedback"
                                          :description="field.instructions"
                                          :state="field.isValid">
                                <b-form-input v-if="field.controlType === 'Text'" :id="'field_' + field.propertyName"
                                              trim
                                              v-model="dataModel[field.propertyName]"
                                              :autofocus="field.focus || (fieldIndex === 0 && rowIndex === 0 && sectionIndex === 0) ? true : null"
                                              :name="field.propertyName"
                                              :type="field.controlSubType || 'text'"
                                              autocomplete="off"
                                              :placeholder="field.placeholderText"></b-form-input>
                                <b-form-select v-if="field.controlType === 'Select'" :id="'field_' + field.propertyName"
                                               v-model="dataModel[field.propertyName]"
                                               :autofocus="field.focus || (fieldIndex === 0 && rowIndex === 0 && sectionIndex === 0) ? true : null"
                                               :name="field.propertyName"
                                               autocomplete="off"
                                               :placeholder="field.placeholderText"
                                               :options="field.options">
                                </b-form-select>
                                <b-form-checkbox v-if="field.controlType === 'CheckBox'" :id="'field_' + field.propertyName"
                                                 v-model="dataModel[field.propertyName]"
                                                 :autofocus="field.focus || (fieldIndex === 0 && rowIndex === 0 && sectionIndex === 0) ? true : null"
                                                 :name="field.propertyName"
                                                 autocomplete="off"
                                                 :placeholder="field.placeholderText">{{field.label}}</b-form-checkbox>
                                <b-form-datepicker v-if="field.controlType === 'Date'" :id="'field_' + field.propertyName"
                                                   v-model="dataModel[field.propertyName]"
                                                   :autofocus="field.focus || (fieldIndex === 0 && rowIndex === 0 && sectionIndex === 0) ? true : null"
                                                   :name="field.propertyName"
                                                   autocomplete="off"
                                                   :placeholder="field.placeholderText" />
                                <div v-if="field.controlType === 'Camera'">
                                    <camera :id="'field_' + field.propertyName"
                                            ref="camera">
                                        <div>
                                            <b-img v-if="field.propertyName && dataModel[field.propertyName]" fluid :src="dataModel[field.propertyName]" />
                                            <b-button v-if="! (field.propertyName && dataModel[field.propertyName])" class="mx-auto" @click="onCameraClick(field)">Take Photo</b-button>
                                            <b-button v-if="field.propertyName && dataModel[field.propertyName]" class="mx-auto" @click="onShowVideo(field)">Show Video</b-button>

                                        </div>
                                    </camera>

                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-form>
</template>

<script>
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    import Camera from "simple-vue-camera";

    import iconYubiKey from "../assets/icons/yubi-600x600.png";
    import iconSmartCard from "../assets/icons/Card.png";

    export default {
        props: {
            model: Object,
            form: Object,
        },
        emits: ["error", "submit"],
        data() {
            return {

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard
                },
            }
        },
        components: {
            Camera
        },
        computed: {
            dataModel() {
                return ExpUtil.getCaseInsensitiveProxy(this.model ?? this.form?.dataModel ?? {});
            },

        },
        async mounted() {

        },
        methods: {

            onSubmit(evt) {
                this.$emit("submit", evt);
            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (typeof this.client?.handleError === "function") {
                    this.client.handleError(ex);
                }
                else {
                    if (ex.status === 401) {
                        this.raiseNeedsAuthEvent();
                    }
                    else {
                        this.raiseErrorEvent(ex);
                    }
                }
            },

            clearError() {
                this.raiseErrorEvent(null);
            },


            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },

            async onCameraClick(field) {
                try {
                    DConsole.log("Register this.$refs:");
                    DConsole.log(this.$refs);

                    let camera = null;
                    if (Array.isArray(this.$refs.camera)) {
                        camera = this.$refs.camera.find(x => x.value?.id === ('field_' + field.propertyName));
                        if (!camera && this.$refs.camera.length > 0) {
                            camera = this.$refs.camera[0];
                        }
                    }
                    else if (this.$refs.camera) {
                        camera = this.$refs.camera;
                    }

                    if (!camera) throw new Error("Unable to access camera.");

                    DConsole.log("taking snapshot...");
                    let blob = await camera.snapshot();

                    DConsole.log(blob);

                    //let imageUrl = URL.createObjectURL(blob);

                    let imageUrl;

                    let reader = new window.FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        imageUrl = reader.result;
                        if (field.propertyName) {
                            this.dataModel[field.propertyName] = imageUrl;
                        }

                    };


                }
                catch (ex) {
                    this.handleError(ex);

                }

            },

            async onShowVideo(field) {
                try {
                    //This is just a quick way to do this. Delete the photo that we took and it will show the camera again.
                    //It's not great, because it doesn't give us a second chance if we decide we want the first photo after all.
                    if (field.propertyName) {
                        this.dataModel[field.propertyName] = null;
                    }
                }
                catch (ex) {
                    this.handleError(ex);

                }

            },


        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

</style>

