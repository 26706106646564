import Url from '../util/Url';
//import Guid from '../util/Guid';
import DConsole from '../util/DConsole';
//import ExpUtil from "../util/ExpUtil.js";

import BaseClient from "../library/BaseClient.js";

import CryptoJS from "crypto-js";
import FidoWebAuthN from './FidoWebAuthN';
//import base64url from "base64-url";


class LoginAuthStatus {
    constructor() {
    }

    get needsData() {
        return this.dataOptions && ! this.dataOptions?.none;
    }

    //object that indicates which data options our login server supports.
    dataOptions = null;

    //a LoginTokens object, if authentication was successful.
    tokens = null;
}

class LoginTokens {
    constructor(options) {
        Object.assign(this, options);
    }

    token_type = null;
    access_token = null;
    id_token = null;
    id_parsed = null;  //the id_token parsed into an object. Use id_parsed.payload for the useful stuff.
    expires_in = 0;
    created_at = Date.now() / 1000; //Date.now returns milliseconds since epoch, we want seconds.
    redirect_uri = null;
    scope = null; //space-delimited scopes granted.

    get expiration() {
        //"expires_in" is in seconds. Add that (as milliseconds) to now to get the actual expiration time.
        //the expiration is also embedded in the tokens, but this is easier.
        return new Date((this.created_at + this.expires_in) * 1000);
    }

    get isTimeValid() {

        return Date.now() < (this.created_at + this.expires_in) * 1000;
    }

    // Returns true if the specified scope was granted. This just uses the "scope"
    // value returned by the token_endpoint when retrieving a token.
    hasScope(input) {
        let hasIt = false;
        if (typeof this.scope === "string") {
            let parts = this.scope.split(" ");
            if (parts.includes(input)) {
                hasIt = true;
            }
        }
        return hasIt;
    }
}

/// <summary>
/// This is returned by Authenticate to indicate if any additional actions are needed by the 
/// the client in order to authenticate.
/// </summary>
class LoginAuthDataFlags {
    constructor(options) {
        Object.assign(this, options);
    }

    email = false;
    phone = false;
    password = false;
    accessCode = false;
    fido2 = false;
    mobileAuth = false;
    skip = false;
    username = false;
    allowRegistration = false;
    registrationEndpoint = null;
    registrationActions = null;
    mobileAuthOTP = null;
    identityFound = false;

    clear() {
        this.email = false;
        this.phone = false;
        this.password = false;
        this.accessCode = false;
        this.fido2 = false;
        this.mobileAuth = false;
        this.skip = false;
        this.username = false;
        this.allowRegistration = false;
        this.registrationEndpoint = null;
        this.registrationActions = null;
        this.mobileAuthOTP = null;
        this.identityFound = false;
    }

    get none() {
        return !this.email && !this.phone && !this.password && !this.accessCode && !this.fido2 && ! this.mobileAuth && ! this.skip && ! this.username;
    }

    // Use this instead of just doing if (x.mobileAuthOTP) because it's possible that the OTP will be "00" which would 
    // be converted to 0 by javascript's implicit typecasting and would register as false.
    get hasMobileAuthOTP() {
        return typeof (this.mobileAuthOTP) === "string" && this.mobileAuthOTP.length > 0;
    }

    /*
    None: 0,
    Email: 0x02,
    Phone: 0x04,
    FIDO2: 0x08,
    AccessCode: 0x10,
    Password: 0x20
    */
}

class LoginClient extends BaseClient {
    constructor(baseUrl) {

        super();

        this.baseUrl = baseUrl || Url.combine(Url.removePathPart(window.location.href));
        if (process.env.NODE_ENV === "development") {
            //when in dev mode (say, on my laptop in a debugger) point to server running in a different debug process.
            let url = new URL(window.location.href);
            this.baseUrl = `https://${url.hostname}:5001`; //"https://localhost:5001/";
        }

        DConsole.log("baseURL: " + this.baseUrl);

    }

    //text to display.
    title = null;
    instructions = null;

    fidoClient = new FidoWebAuthN();
    fidoAssertionOptions = null;

    userEmail = null;
    userPhone = null;

    userAccessCode = null;

    //for password login.
    username = null;
    password = null;

    //UI should set to true if the user selects mobile auth (or it gets auto-selected because it's the only option)
    isMobileAuthRequested = false;

    mobileAuthOTP = null;

    //UI should set to true if the user selects to skip a step (or it gets auto-selected because it's the only option)
    isSkipRequested = false;

    didUserCompleteRegistrationAction = false;

    clientID = "EntryPoint";
    clientCertificateDescriptor;

    // Array of requested scopes. openid needs to be one. "manage" is standard for EntryPoint manage UI.
    requestedScopes = ["openid"];

    //entrypoint temporary auth session identifier. Used when we are authenticating for another client.
    sessionEpRef = null;

    stateParameter = null;

    /// <summary>
    /// Code returned by Authenticate endpoint of server so we can retrieve the access token (and ID token).
    /// </summary>
    authorizationCode = null;

    codeVerifier = null;

    nonce = null;

    redirectCallback = null;

    openIdConfig = null;

    allowedAuthMethods = null; //allowed auth methods for this step in the authentication process

    registrationActions = null; //allowed registration actions for this step in the authentication process

    tokens = null;

    /// <summary>
    /// Parsed ID Token. This basically assumes we are working with the EntryPoint OpenID Connect server.
    /// </summary>
    idToken = null;

    status = new LoginAuthStatus();

    //Current state within the authentication process.
    state = LoginClient.AuthState.Discovery;

    identityFound = false;

    authMethodsTried = [];

    static AuthState = {
        Discovery: 0,
        NextFactor: 1,
        Authenticating: 2,
        WaitingForUser: 3,
        WaitingForUserAccessCode: 4
    };

    static AuthMethods = {
        None: "None",

        /// <summary>
        /// Negotiate is the HTTP name for Windows Auth
        /// </summary>
        Negotiate: "Negotiate",

        /// <summary>
        /// One-Time Password will be emailed.
        /// </summary>
        EmailAccessCode: "EmailAccessCode",

        /// <summary>
        /// One-Time Password will be texted.
        /// </summary>
        PhoneAccessCode: "PhoneAccessCode",

        /// <summary>
        /// Use FIDO2 to logon.
        /// </summary>
        FIDO2: "FIDO2",

        /// <summary>
        /// As defined in https://datatracker.ietf.org/doc/html/rfc8705
        /// </summary>
        MutualTLS: "MutualTLS",

        /// Old school username password. Trying to avoid, but hard to completely get away from, especially in development.
        Password: "Password",

        /// Authentication with the EntryPoint Mobile Auth app.
        MobileAuth: "MobileAuth",

        //Skip this step.
        Skip: "Skip",

        //Use our cached Access Code as a credential.
        Delegated: "Delegated",

        //Username only. Can be first step for multi-factor.
        Username: "Username"
    };

    static AuthScopes = {
        Manage: "manage",
        SelfService: "selfService",
        OpenID: "openid",
        MobileRegister: "mobileRegister",
        MobileSelfService: "mobileSelfService"
    };

    async getOpenIDConfig() {
        //First get the config for the authority. This will give us the other endpoints.
        this.openIdConfig = await this.get(".well-known/openid-configuration");

        if (!this.openIdConfig?.authorization_endpoint) throw new Error("Unable to determine Authorization endpoint.");
        if (!this.openIdConfig?.token_endpoint) throw new Error("Unable to determine Token endpoint.");

    }

    /// <summary>
    /// Authenticates. This will throw an exception if it can't authenticate and there are no ways
    /// to authenticate. If it fails, but there are possible ways to authenticate, then this will
    /// succeed and return AuthStatus indicating what info the client needs to get. That data
    /// should be requested from the user, then set on this object before calling Authenticate again.
    /// The end goal here is to get an Access Token that can be used to authenticate to the API.
    /// </summary>
    /// <returns></returns>
    /// <exception cref="Exception"></exception>
    async authenticate() {
        if (!this.baseUrl) throw new Error("No Authority URL.");

        //Note: we probably should include logic to get a new access token if the current one has expired.
        //If we have a refresh token, we can just use that.

        let keepLooping = false;

        do {
            keepLooping = false;
            let state = this.state;
            let authMethods = this.allowedAuthMethods;

            switch (state) {
                case LoginClient.AuthState.Discovery:
                    {
                        this.resetSingleUseAuthData();

                        //First get the config for the authority. This will give us the other endpoints.
                        await this.getOpenIDConfig();

                        if (!this.openIdConfig.ti_entry_point) {
                            //TODO: Our authenticator is not EntryPoint, so we need to redirect to the url at
                            //      this.openIdConfig.authorization_endpoint
                            throw new Error("Non-EntryPoint authentication service is not currently supported.");
                        }

                        this.checkForEpRefInUrl();

                        await this.requestInitialAuthFactors();

                        if (!this.authorizationCode) {
                            this.state = LoginClient.AuthState.NextFactor;
                            keepLooping = true;
                        }
                    }

                    break;

                case LoginClient.AuthState.NextFactor:
                    {
                        if ((!Array.isArray(authMethods) || authMethods.length === 0) && (!Array.isArray(this.registrationActions) || this.registrationActions.length === 0)) throw new Error("No authentication methods are available.", 401);

                        let success = false;

                        if (!this.authorizationCode && authMethods.includes(LoginClient.AuthMethods.MutualTLS)) {
                            // see https://datatracker.ietf.org/doc/html/rfc8705

                            //TODO: In the thick client, we have to explicitly configure the client to use a certificate.
                            //That doesn't work here, so we will have to base it on whether or not the server is configured
                            //to support it. Unfortunately I think that means it's either all or nothing, unless we can filter
                            //based on their IP address or something. Or, they could click a button to choose.

                            success = await this.loginWithCertAndGetAuthCode();
                        }

                        if (!this.authorizationCode && authMethods.includes(LoginClient.AuthMethods.Delegated)) {
                            success = await this.loginWithExistingAccessTokenAndGetAuthCode();
                        }

                        if (!this.authorizationCode && authMethods.includes(LoginClient.AuthMethods.Negotiate)) {
                            success = await this.loginWithWindowsAndGetAuthCode();
                        }

                        if (!success && !this.authorizationCode
                            && (authMethods.some(x => x === LoginClient.AuthMethods.EmailAccessCode || x === LoginClient.AuthMethods.PhoneAccessCode || x === LoginClient.AuthMethods.FIDO2 || x === LoginClient.AuthMethods.Password || x === LoginClient.AuthMethods.MobileAuth || x === LoginClient.AuthMethods.Username || x == LoginClient.AuthMethods.Skip)
                                || (Array.isArray(this.registrationActions) && this.registrationActions.length > 0)))
                        {
                            this.state = LoginClient.AuthState.WaitingForUser;

                            //Set the options to our plausible login options. This way, the application can 
                            //prompt the user to Enter their phone or email, or insert their FIDO2 token if they have one (and if those options are enabled).
                            let options = new LoginAuthDataFlags();
                            options.email = authMethods.includes(LoginClient.AuthMethods.EmailAccessCode) ? true : false;
                            options.phone = authMethods.includes(LoginClient.AuthMethods.PhoneAccessCode) ? true : false;
                            options.password = authMethods.includes(LoginClient.AuthMethods.Password) ? true : false;
                            options.fido2 = authMethods.includes(LoginClient.AuthMethods.FIDO2) ? true : false;
                            options.mobileAuth = authMethods.includes(LoginClient.AuthMethods.MobileAuth) ? true : false; //for now, mobile auth will be presented as an option to the user.
                            options.skip = authMethods.includes(LoginClient.AuthMethods.Skip) ? true : false;
                            options.username = authMethods.includes(LoginClient.AuthMethods.Username) ? true : false;

                            options.allowRegistration = Array.isArray(this.registrationActions) && this.registrationActions.length > 0;
                            //options.registrationEndpoint = this.openIdConfig?.registration_endpoint;  //no longer used. Now using the fixed "connect/register" that is part of our oauth controller.
                            options.registrationActions = this.registrationActions;

                            options.mobileAuthOTP = this.mobileAuthOTP;

                            options.identityFound = this.identityFound;

                            this.status.dataOptions = options;

                            this.userEmail = null;
                            this.userPhone = null;
                            this.username = null;
                            this.password = null;
                            this.userAccessCode = null;
                            this.isMobileAuthRequested = false;
                            this.isSkipRequested = false;
                            this.didUserCompleteRegistrationAction = false;

                            if (options.none && (!Array.isArray(options.registrationActions) || options.registrationActions.length == 0)) {
                                throw new Error("Unable to log on with available methods.", 401);
                            }
                        }
                        else if (success && this.hasMoreStepsToGo()) {
                            keepLooping = true;
                            this.state = LoginClient.AuthState.NextFactor;
                        }
                        else if (!success) {
                            throw new Error("Cannot log on with available methods.", 401);
                        }
                    }
                    break;

                case LoginClient.AuthState.WaitingForUser:
                    {
                        let needMoreFromUser = false;
                        this.userAccessCode = null;

                        if (!this.authorizationCode
                            && (this.userEmail || this.userPhone)
                            && authMethods.some(x => x === LoginClient.AuthMethods.EmailAccessCode || x === LoginClient.AuthMethods.PhoneAccessCode)
                        ) {
                            await this.submitEmailOrPhoneToSendAccessCode();

                            //succeeded, so tell app to wait for access code.
                            this.state = LoginClient.AuthState.WaitingForUserAccessCode;
                            this.status.dataOptions = new LoginAuthDataFlags({ accessCode: true });
                            needMoreFromUser = true;
                        }
                        else if (!this.authorizationCode
                            && (this.username && this.password)
                            && authMethods.some(x => x === LoginClient.AuthMethods.Password)
                        ) {
                            await this.submitUsernameAndPassword(true);
                        }
                        else if (!this.authorizationCode
                            && this.username
                            && authMethods.some(x => x === LoginClient.AuthMethods.Username)
                        ) {
                            await this.submitUsernameAndPassword(false);
                        }
                        else if (authMethods.includes(LoginClient.AuthMethods.MobileAuth) && this.isMobileAuthRequested) {
                            await this.requestMobileAuth();

                        }
                        else if (authMethods.includes(LoginClient.AuthMethods.Skip) && this.isSkipRequested) {
                            await this.requestSkip();

                        }
                        else if (Array.isArray(this.registrationActions) && this.registrationActions.length > 0 && this.didUserCompleteRegistrationAction) {
                            await this.requestInitialAuthFactors();

                            if (!this.authorizationCode) {
                                this.state = LoginClient.AuthState.NextFactor;
                                keepLooping = true;
                            }
                        }
                        else if (authMethods.includes(LoginClient.AuthMethods.FIDO2) && this.fidoClient) {
                            await this.requestFidoOptions();
                            await this.submitFidoAssertion(this.fidoAssertionOptions);
                        }
                        else {
                            throw new Error("Please provide the requested authentication credentials.");
                        }

                        if (!needMoreFromUser && this.hasMoreStepsToGo()) 
                        {
                            keepLooping = true;
                            this.state = LoginClient.AuthState.NextFactor;
                        }

                    }
                    break;

                case LoginClient.AuthState.WaitingForUserAccessCode:
                    {
                        if (this.userAccessCode === undefined || this.userAccessCode === null || this.userAccessCode === "") throw new Error("No access code was entered.");

                        await this.submitAccessCode();
                        if (this.hasMoreStepsToGo()) {
                            keepLooping = true;
                            this.state = LoginClient.AuthState.NextFactor;
                        }


                    }
                    break;

            }
        } while (keepLooping);

        if (this.authorizationCode) {
            //Once we've got our auth code, we can get the access and ID tokens.
            let tokens = await this.getTokens();  

            if (tokens) {
                this.status.tokens = tokens;
                this.status.dataOptions = null;

                //Reset state.
                this.state = LoginClient.AuthState.Discovery;
            }
        }


        return this.status;
    }



    async getTokens() {
        let tokenResponse = null;
        let openIDConfig = this.openIdConfig;

        if (this.codeVerifier) {
            let tokenInput = {
                client_id: this.clientID,
                code: this.authorizationCode,
                code_verifier: this.codeVerifier,
                grant_type: "code"
            };

            if (this.redirectCallback) {
                tokenInput.redirect_uri = this.redirectCallback;
            }

            tokenResponse = await this.postForm(openIDConfig.token_endpoint, tokenInput);
        }
        else if (this.redirectCallback) {
            tokenResponse = await this.get(this.redirectCallback);
            this.redirectCallback = null;
        }

        if (!tokenResponse?.access_token) throw new Error("Identity server did not return an access token.");

        DConsole.log(`access_token: ${tokenResponse?.access_token}`);

        this.tokens = new LoginTokens(tokenResponse);
        this.tokens.redirect_uri = this.redirectCallback;

        if (tokenResponse.id_token) {
            let idToken = this.parseToken(tokenResponse.id_token);

            //TODO: verify signature. We can get the key from the jwk endpoint, which we do have.
            this.tokens.id_parsed = idToken;
        }

        //Clear out all the temporary data that was only used as part of a multi-step authorization process.
        this.resetSingleUseAuthData();

        return this.tokens;
    }

    resetSingleUseAuthData() {
        this.nonce = null;
        this.codeVerifier = null;
        this.authorizationCode = null;
        this.redirectCallback = null;
        this.stateParameter = this.generatePKCECodeVerifier();  //this is just a random string.
        this.mobileAuthOTP = null;
        this.sessionEpRef = null;
        this.session = null;
        this.identityFound = false;
    }

    checkForEpRefInUrl() {
        let requestUrl = new URL(window.location.href);
        let epRef = requestUrl?.searchParams?.get("ep_ref");
        if (epRef) {
            this.sessionEpRef = epRef;
        }

        let state = requestUrl?.searchParams?.get("state");
        if (state) {
            this.stateParameter = state;
        }

    }

    //Returns true if the URL contains various query string elements that indicate we are specifically
    //requesting a delegated login. This is designed so that thick clients can use the browser
    //to authenticate, and take advantage of cases where the browser is already authenticated for single-sign on.
    static checkUrlForDelegatedLoginRequest() {
        let requestUrl = new URL(window.location.href);
        let epRef = requestUrl?.searchParams?.get("ep_ref");
        return epRef ? true : false;
    }


    hasMoreStepsToGo() {
        return !this.authorizationCode &&
            ((Array.isArray(this.allowedAuthMethods) && this.allowedAuthMethods.length > 0) || (Array.isArray(this.registrationActions) && this.registrationActions.length > 0));
    }

    getCommonAuthEndpointParams() {

        let epRef = this.sessionEpRef;
        let hasEpRef = epRef !== undefined && epRef !== null && epRef !== "";
        let codeChallenge = null;

        //ep_ref means the app (which is separate from us) generated the codeVerifier and nonce and the server
        //already has them. We can pass ep_ref to allow the server to retrieve the in-progress session.
        if (!this.nonce) {
            this.nonce = this.generatePKCECodeVerifier(); //Nonce just needs to be random. We'll use the same function we use to generate a PKCE Code Verifier.
        }
        if (!this.codeVerifier) {
            this.codeVerifier = this.generatePKCECodeVerifier();
        }

        codeChallenge = this.computePKCECodeChallenge(this.codeVerifier);

        if (!this.requestedScopes.includes("openid")) {
            this.requestedScopes.push("openid");
        }

        let auth = {
            response_type: "code",
            response_mode: "query", //responses are included in the query string (can also be "fragment" which we don't want.
            state: this.stateParameter,  //we don't really need this in our simple client
            nonce: this.nonce,  //optional, used to prevent replay attacks.
            scope: this.requestedScopes.join(" "),
        };
        if (hasEpRef) {
            auth.ep_ref = epRef;
        }
        auth.client_id = this.clientID;
        auth.code_challenge = codeChallenge;
        auth.code_challenge_method = "S256";

        return auth;
    }

    async loginWithCertAndGetAuthCode() {
        let succeeded = false;
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        try {
            //Tell it to use the certificate.
            let post = {
                UseCertificate: true
            };
            let response = await this.postJson(url, post, input); //args are: url, body, query_string

            succeeded = this.handleAuthResponseForMethodsWithNoUserInteraction(response);
        }
        catch (ex) {
            if (ex.StatusCode !== 401 && ex.status !== 401) { //401 = unauthorized.
                throw ex;
            }
        }
        return succeeded;
    }


    async loginWithWindowsAndGetAuthCode() {
        let succeeded = false;

        let url = this.openIdConfig?.authorization_winauth_endpoint ?? this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        try {
            //For now, this will try Negotiate (i.e., Windows Integrated Authentication) first. If that fails,
            //we'll get a 401 (SendRequest throws an HttpException) and we can then try the next approach,
            //based on the error code.
            this.useWindowsAuth = true;

            let response = await this.postJson(url, {}, input);

            succeeded = this.handleAuthResponseForMethodsWithNoUserInteraction(response);
        }
        catch (ex) {
            if (ex.StatusCode !== 401 && ex.status !== 401) { //401 = unauthorized.
                throw ex;
            }
        }
        return succeeded;
    }

    async loginWithExistingAccessTokenAndGetAuthCode() {
        let succeeded = false;

        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        try {
            let session = await this.getCachedAuthTokens();
            if (session) {
                this.session = session;

                //Tell it to use the certificate.
                let post = {
                    UseBearerToken: true
                };

                let response = await this.postJson(url, post, input);

                succeeded = this.handleAuthResponseForMethodsWithNoUserInteraction(response);
            }
        }
        catch (ex) {
            this.session = null;
            if (ex.StatusCode !== 401 && ex.status !== 401) { //401 = unauthorized.
                throw ex;
            }
        }
        this.session = null;

        return succeeded;
    }

    async requestFidoOptions() {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        let post = {
            GetFidoAssertionOptions: true
        };

        let response = await this.postJson(url, post, input); //args are: url, body, query_string

        this.fidoAssertionOptions = response.fido_assertion_options;
        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;
    }

    async requestMobileAuth() {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        let post = {
            RequestMobileAuth: true
        };

        let response = await this.postJson(url, post, input); //args are: url, body, query_string

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;

    }

    async requestSkip() {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        let post = {
            RequestSkip: true
        };

        let response = await this.postJson(url, post, input); //args are: url, body, query_string

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;

    }

    async requestInitialAuthFactors() {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();

        //the way our Authorize endpoint works, if you post an empty object (along with the
        //query string from endpoint params) it will return the list allowed authentication methods
        //for the next step.
        let post = {

        };

        let response = await this.postJson(url, post, input); //args are: url, body, query_string

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;

    }



    handleAuthResponseForMethodsWithNoUserInteraction(response) {
        let succeeded = false;
        if (response.state !== this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        if (response.error) {
            if (response.error !== LoginClient.OAuthErrorCodes.login_required) {
                throw new Error(response.error_description ?? response.error, 400, response.error);
            }
        }
        else if (response.code) {
            this.authorizationCode = response.code;
            this.redirectCallback = response.redirect_uri;
            this.registrationActions = response.entrypoint_registration_actions;
            this.title = response.entrypoint_title;
            this.instructions = response.entrypoint_instructions;
            this.mobileAuthOTP = response.entrypoint_mobile_otp;
            this.identityFound = response.entrypoint_identity_found;

            succeeded = true;
        }
        else if ((Array.isArray(response.entrypoint_next_auth_methods) && response.entrypoint_next_auth_methods.length > 0)
            || (Array.isArray(response.entrypoint_registration_actions) && response.entrypoint_registration_actions.length > 0))
        {
            this.allowedAuthMethods = response.entrypoint_next_auth_methods;
            this.redirectCallback = response.redirect_uri;
            this.registrationActions = response.entrypoint_registration_actions;
            this.title = response.entrypoint_title;
            this.instructions = response.entrypoint_instructions;
            this.mobileAuthOTP = response.entrypoint_mobile_otp;
            this.identityFound = response.entrypoint_identity_found;

            succeeded = true;
        }
        else {
            throw new Error("Login did not return anything useful.");
        }
        return succeeded;
    }

    async submitFidoAssertion(fidoAssertionOptions) {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let fidoResult = await this.fidoClient.requestAssertion(fidoAssertionOptions);

        let input = this.getCommonAuthEndpointParams();
        let post = this.getFormParametersForFidoLogin(fidoResult);

        let response = await this.postJson(url, post, input);

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;

        return response;
    }

    getFormParametersForFidoLogin(fidoAssertion) {

        let post = {
            FidoAssertionResult: fidoAssertion
        };

        return post;
    }

    getFormParametersForAccessCodeLogin() {
        let authMethods = this.allowedAuthMethods;

        let post = {};

        if (this.userEmail && authMethods.includes(LoginClient.AuthMethods.EmailAccessCode)) {
            post.Email = this.userEmail.trim();
        }
        if (this.userPhone && authMethods.includes(LoginClient.AuthMethods.PhoneAccessCode)) {
            post.Phone = this.userPhone.trim(); // TryParseToPhoneNumberE164Format();
        }

        if (this.userAccessCode) {
            post.AccessCode = this.userAccessCode;
        }

        if (!post.Email && !post.Phone) throw new Error("No email or phone number was supplied.");

        return post;
    }

    getFormParametersForPasswordLogin(requirePassword) {
        let authMethods = this.allowedAuthMethods;

        let post = {};

        if (this.username && (authMethods.includes(LoginClient.AuthMethods.Password) || authMethods.includes(LoginClient.AuthMethods.Username))) {
            post.Username = this.username.trim();
        }

        if (typeof this.password === "string" && this.password !== "" && authMethods.includes(LoginClient.AuthMethods.Password)) {
            post.Password = this.password;
        }

        if (!post.Username || (requirePassword && (post.Password === undefined || post.Password === null || post.Password === ""))) throw new Error(`No username ${(requirePassword ? "or password was" : "was")} supplied.`);

        return post;
    }


    async submitEmailOrPhoneToSendAccessCode() {
        //Post the email/phone to the server so it can send an access code.
        //if it fails, it will throw an exception.
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();
        let post = this.getFormParametersForAccessCodeLogin();

        //Post to the server, along with the oauth endpoint params, 
        this.useWindowsAuth = false;

        let response = await this.postJson(url, post, input);

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.registrationActions = response.entrypoint_registration_actions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;
    }

    async submitAccessCode() {
        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();
        let post = this.getFormParametersForAccessCodeLogin();

        let response = await this.postJson(url, post, input);

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;
    }

    async submitUsernameAndPassword(requirePassword) {

        let url = this.openIdConfig?.authorization_endpoint;
        if (!url) throw new Error("Authorization Endpoint not specified.");

        let input = this.getCommonAuthEndpointParams();
        let post = this.getFormParametersForPasswordLogin(requirePassword);

        //Post to the server, along with the oauth endpoint params, 
        this.useWindowsAuth = false;

        let response = await this.postJson(url, post, input);

        if (response.state != this.stateParameter) throw new Error("state returned by Authorize does not match.", 401);

        this.title = response.entrypoint_title;
        this.instructions = response.entrypoint_instructions;
        this.authorizationCode = response.code;
        this.allowedAuthMethods = response.entrypoint_next_auth_methods;
        this.redirectCallback = response.redirect_uri;
        this.registrationActions = response.entrypoint_registration_actions;
        this.mobileAuthOTP = response.entrypoint_mobile_otp;
        this.identityFound = response.entrypoint_identity_found;

    }


    computePKCECodeChallenge(codeVerifier) {
        return CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(codeVerifier));
    }

    /// <summary>
    /// Generates a random Code Verifier suitable for use in PKCE Code Auth flow of OAuth2.
    /// </summary>
    /// <returns></returns>
    generatePKCECodeVerifier() {
        return CryptoJS.enc.Base64url.stringify(CryptoJS.lib.WordArray.random(32))
    }

    parseToken(token) {
        DConsole.log("parseToken");
        DConsole.log(token);

        token = token ?? "";

        let parts = token.split('.');
        let i = 0;

        let decoded = {};

        //decoded.header = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64url.parse(parts[i])));
        decoded.header = JSON.parse(this.decodeBase64Url(parts[i]));

        i++;
        if (i < parts.length) {
            //decoded.payload = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64url.parse(parts[i])));
            decoded.payload = JSON.parse(this.decodeBase64Url(parts[i]));
            i++;
        }

        if (i < parts.length) {
            //This will be a CryptoJS.lib.WordArray, which is an array of 32-bit values.
            //Note: there is a bug in CryptoJS where this parse function fails the second time around with 
            //      a call stack overflow.
            //decoded.signature = CryptoJS.enc.Base64url.parse(parts[i]);

        }

        DConsole.log(decoded);

        return decoded;
    }

    decodeBase64Url(input) {
        // Replace non-url compatible chars with base64 standard chars
        input = input
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        // Pad out with standard base64 required padding characters
        let pad = input.length % 4;
        if (pad) {
            if (pad === 1) {
                throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
            }
            input += new Array(5 - pad).join('=');
        }

        return atob(input);
    }

    async getCache() {
        //this will return either sessionStorage or localStorage, depending on
        //how you want to store tokens. sessionStorage is local to this browser tab and disappears
        //when the browser closes. localStorage is global to the browser (although only accessible
        //to this origin/domain) and persists when the browser is closed. some online sources recommend
        //storing the Refresh Token in local storage, and not the Access Token.

        if (!this.openIdConfig) {
            await this.getOpenIDConfig();
        }

        let useLocalStorage = this.openIdConfig?.persist_auth;

        if (useLocalStorage) {
            return localStorage;
        }
        else {
            return sessionStorage;
        }
    }

    async cacheAuthTokens(authTokens) {
        try {

            let cache = await this.getCache();
            if (authTokens) {

                DConsole.log("Caching auth token...");
                DConsole.log(authTokens);

                let toCache = JSON.parse(JSON.stringify(authTokens));
                toCache.redirect_uri = null; //don't cache the redirect. It's not relevant any more.
                cache.setItem("authTokens", JSON.stringify(toCache));
            }
            else {
                cache.removeItem("authTokens");                
            }
        }
        catch (error) {
            DConsole.log("Unable to cache auth tokens: " + ((error && error.message) || ""));
        }
    }

    static clearSessionCookie() {
        DConsole.log("cookie:");
        DConsole.log(document.cookie);
    }

    static clearCachedAuthTokens() {
        DConsole.log("Clearing auth token cache...");
        sessionStorage.removeItem("authTokens");
        localStorage.removeItem("authTokens");
    }

    async hasCachedAuthTokens() {
        return await this.getCachedAuthTokens() ? true : false;
    }

    async getCachedAuthTokens() {
        DConsole.log("Checking for cached auth tokens...");
        let cached = null;
        let cache = await this.getCache();
        try {
            cached = cache.getItem("authTokens");
            if (cached) {
                //Items are stored as strings in authTokens storage.
                cached = new LoginTokens(JSON.parse(cached));
                cached.redirect_uri = null; //in case it got saved. We don't want to cache this because it's not relevant past the initial request.
                DConsole.log('cached auth tokens:');
                DConsole.log(cached);

                if (cached && !cached.isTimeValid) {
                    DConsole.log("cached token is expired. clearing.");
                    //clear the cache.
                    LoginClient.clearCachedAuthTokens();
                    cached = null;
                }
            }
        }
        catch (error) {
            DConsole.log("Error trying to get cached authTokens: " + ((error && error.message) || ""));
        }
        return cached;
    }

    async register(identityID, tokenFamily, tokenID, deviceID, stepInput) {
        let registerInput = {
            IdentityID: identityID,
            TokenFamily: tokenFamily,
            TokenID: tokenID,
            DeviceID: deviceID,
            StepInput: stepInput
        };

        let oauthInput = this.getCommonAuthEndpointParams();

        //This returns a RegisterStepResponse.
        let response = await this.postJson("/connect/register", registerInput, oauthInput); //args are: url, body, query_string

        return response;
    }

    /// <summary>
    /// Defined in https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2
    /// and also other sections in that doc.
    /// </summary>
    static OAuthErrorCodes = {
        /// <summary>
        ///  The request is missing a required parameter, includes an
        ///  invalid parameter value, includes a parameter more than
        ///  once, or is otherwise malformed.
        /// </summary>
        invalid_request: "invalid_request",

        /// <summary>
        /// Client authentication failed (e.g., unknown client, no
        /// client authentication included, or unsupported
        /// authentication method).  The authorization server MAY
        /// return an HTTP 401 (Unauthorized) status code to indicate
        /// which HTTP authentication schemes are supported.If the
        /// client attempted to authenticate via the "Authorization"
        /// request header field, the authorization server MUST
        /// respond with an HTTP 401 (Unauthorized) status code and
        /// include the "WWW-Authenticate" response header field
        /// matching the authentication scheme used by the client.
        /// </summary>
        invalid_client: "invalid_client",

        /// <summary>
        ///  The provided authorization grant (e.g., authorization
        /// code, resource owner credentials) or refresh token is
        /// invalid, expired, revoked, does not match the redirection
        /// URI used in the authorization request, or was issued to
        /// another client.
        /// </summary>
        invalid_grant: "invalid_grant",

        /// <summary>
        /// The client is not authorized to request an authorization
        /// code using this method.
        /// </summary>
        unauthorized_client: "unauthorized_client",

        /// <summary>
        /// The resource owner or authorization server denied the request.
        /// </summary>
        access_denied: "access_denied",

        /// <summary>
        /// The authorization grant type is not supported by the authorization server.
        /// </summary>
        unsupported_grant_type: "unsupported_grant_type",

        /// <summary>
        /// The authorization server does not support obtaining an authorization code using this method.
        /// </summary>
        unsupported_response_type: "unsupported_response_type",

        /// <summary>
        /// The requested scope is invalid, unknown, or malformed.
        /// </summary>
        invalid_scope: "invalid_scope",

        /// <summary>
        /// The authorization server encountered an unexpected
        /// condition that prevented it from fulfilling the request.
        /// (This error code is needed because a 500 Internal Server
        /// Error HTTP status code cannot be returned to the client
        /// via an HTTP redirect.)
        /// </summary>
        server_error: "server_error",

        /// <summary>
        /// The authorization server is currently unable to handle
        /// the request due to a temporary overloading or maintenance
        /// of the server.  (This error code is needed because a 503
        /// Service Unavailable HTTP status code cannot be returned
        /// to the client via an HTTP redirect.)
        /// </summary>
        temporarily_unavailable: "temporarily_unavailable",

        // Open ID Connect error codes

        /// <summary>
        /// The Authorization Server requires End-User interaction of some form to proceed. This error MAY 
        /// be returned when the prompt parameter value in the Authentication Request is none, but the Authentication Request 
        /// cannot be completed without displaying a user interface for End-User interaction.
        /// </summary>
        interaction_required: "interaction_required",
        /// <summary>
        /// The Authorization Server requires End-User authentication.This error MAY be returned when the prompt 
        /// parameter value in the Authentication Request is none, but the Authentication Request cannot be completed 
        /// without displaying a user interface for End-User authentication.
        /// </summary>
        login_required: "login_required",
        /// <summary>
        /// The End-User is REQUIRED to select a session at the Authorization Server.The End-User MAY be authenticated 
        /// at the Authorization Server with different associated accounts, but the End-User did not select a session.
        /// This error MAY be returned when the prompt parameter value in the Authentication Request is none, but the 
        /// Authentication Request cannot be completed without displaying a user interface to prompt for a session to use.
        /// </summary>
        account_selection_required: "account_selection_required",
        /// <summary>
        /// The Authorization Server requires End-User consent.This error MAY be returned when the prompt parameter 
        /// value in the Authentication Request is none, but the Authentication Request cannot be completed without 
        /// displaying a user interface for End-User consent.
        /// </summary>
        consent_required: "consent_required",
        /// <summary>
        /// The request_uri in the Authorization Request returns an error or contains invalid data.
        /// </summary>
        invalid_request_uri: "invalid_request_uri",
        /// <summary>
        /// The request parameter contains an invalid Request Object.
        /// </summary>
        invalid_request_object: "invalid_request_object",
        /// <summary>
        /// The OP does not support use of the request parameter defined in Section 6.
        /// </summary>
        request_not_supported: "request_not_supported",
        /// <summary>
        /// The OP does not support use of the request_uri parameter defined in Section 6.
        /// </summary>
        request_uri_not_supported: "request_uri_not_supported",
        /// <summary>
        /// The OP does not support use of the registration parameter defined in Section 7.2.1.
        /// </summary>
        registration_not_supported: "registration_not_supported",
    }


}

export { LoginAuthStatus, LoginAuthDataFlags };
export default LoginClient;
