<template>
    <b-container>
        <b-row align-v="start">
            <b-col align-v="start">
                <b-row>
                    <b-col>
                        <b-form-group id="messageTextFieldSet"
                                      description=""
                                      label="Search Message Text"
                                      label-for="messageSearchBox">
                            <b-form-input id="messageSearchBox" v-model="messageSearch" trim
                                          :autofocus="true"
                                          name="searchBox"
                                          type="search"
                                          autocomplete="off"
                                          :incremental="false"
                                          @search="getReport"
                                          placeholder="Search Message Text"
                                          @keydown.enter.prevent="onSearchEnterKey"></b-form-input>

                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="cardholderFieldSet"
                                      description=""
                                      label="Cardholder Name Match"
                                      label-for="cardholderSearchBox">
                            <b-form-input id="cardholderSearchBox" v-model="cardholderNameSearch" trim
                                          :autofocus="true"
                                          name="searchBox"
                                          type="search"
                                          autocomplete="off"
                                          :incremental="false"
                                          @search="getReport"
                                          placeholder="Search Cardholder Name"
                                          @keydown.enter.prevent="onSearchEnterKey"></b-form-input>

                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="authorityFieldSet"
                                      description=""
                                      label="Authority Name Match"
                                      label-for="authoritySearchBox">
                            <b-form-input id="authoritySearchBox" v-model="authorityNameSearch" trim
                                          :autofocus="true"
                                          name="searchBox"
                                          type="search"
                                          autocomplete="off"
                                          :incremental="false"
                                          @search="getReport"
                                          placeholder="Search Authority Name"
                                          @keydown.enter.prevent="onSearchEnterKey"></b-form-input>

                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="earliestDateFieldSet"
                                      description=""
                                      label="Earliest Date"
                                      label-for="earliestDatePicker">
                            <b-form-datepicker id="earliestDatePicker" v-model="earliestDate" value-as-date
                                               @keydown.enter.prevent="onSearchEnterKey"></b-form-datepicker>

                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="latestDateFieldSet"
                                      description=""
                                      label="Latest Date"
                                      label-for="latestDatePicker">
                            <b-form-datepicker id="latestDatePicker" v-model="latestDate" value-as-date
                                               @keydown.enter.prevent="onSearchEnterKey"></b-form-datepicker>

                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="eventTypeFieldSet"
                                      description=""
                                      label="Event Type" label-for="eventTypeSelect">
                            <b-select v-model="eventTypes" :options="eventTypeOptions"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-pagination v-if="reportRows > rowsPerPage"
                                      v-model="page"
                                      :total-rows="reportRows"
                                      :per-page="rowsPerPage"
                                      aria-controls="reportTable" 
                                      />
                    </b-col>
                    <b-col>
                        <b-button-group id="fieldset-buttons">

                            <b-button @click="getReport">Get Report</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <b-card v-if="isBusySearching" class="mx-auto text-center" bg-variant="transparent">
                    <b-card-text class="mx-auto">Searching...</b-card-text>
                    <b-spinner class="mx-auto"></b-spinner>
                </b-card>
            </b-col>
        </b-row>
        <b-row align-v="start" v-if="report && report.events">
            <b-col>
                <b-card>
                    <b-overlay :show="isLoadingReport">
                        <b-table id="reportTable"
                                 sticky-header="70vh" striped hover
                                 :items="report && report.events"
                                 :fields="historyFields"
                                 primary-key="id"
                                 :busy="isLoadingReport"
                                 sort-by="eventTime" :sort-desc="true"
                                 :current-page="page" :per-page="rowsPerPage"
                                 responsive outlined>

                            <template #cell(cardholder)="data">
                                <a v-if="data.item.cardholderID"
                                   href="#" @click.prevent="raiseGoHomeEvent(data.item.cardholderID, data.item.cardholderName)">{{ data.item.cardholderName }}</a>
                            </template>

                            <template #cell(authority)="data">
                                <a v-if="data.item.authorityID"
                                   href="#" @click.prevent="raiseGoHomeEvent(data.item.authorityID, data.item.authorityName)">{{ data.item.authorityName }}</a>
                            </template>

                        </b-table>
                    </b-overlay>


                </b-card>
            </b-col>

        </b-row>

    </b-container>
</template>

<script>
    //import SearchBox from "./SearchBox.vue";
    import ManageClient from "../library/ManageClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubi-600x600.png";
    import iconSmartCard from "../assets/icons/Card.png";

    export default {
        props: {
            msg: String,
            auth: Object
        },
        emits: ['needsAuth', 'error', 'interface', 'goHome'],
        data() {
            return {
                showConfirm: false,
                client: new ManageClient(),
                //policy: null,
                overview: null,
                eventTypeOptions: null,
                isBusySearching: false, //just for the search results
                searchText: null,
                searchResults: null,
                searchResultFields: [
                    {
                        key: "name",
                        sortable: true
                    },
                    {
                        key: "email",
                        sortable: true
                    },
                    {
                        key: "phone",
                        sortable: true,
                        // Variant applies to the whole column, including the header and footer
                        //variant: 'danger'
                    }
                ],
                isLoadingReport: false,
                isLoadingOverview: false,
                historyFields: [
                    {
                        key: "eventTime",
                        tdClass: "no-wrap",
                        thClass: "no-wrap",
                        sortable: true,
                        sortDirection: "desc",
                        formatter: (value) => { //, key, item) => {
                            try {
                                return ExpUtil.formatEventTime(value, false, false);
                            }
                            catch (ex) {
                                DConsole.log(ex);
                                return value;
                            }
                        }
                    },
                    { key: "message" },
                    {
                        key: "eventType",
                        formatter: (value) => {
                            try {
                                return ExpUtil.convertCamelCaseToTitleCase(value);
                            }
                            catch (ex) {
                                DConsole.log(ex);
                                return value;
                            }
                        }
                    },
                    {
                        key: "cardholder",
                        sortable: true,
                        sortDirection: "asc"
                    },
                    {
                        key: "authority",
                        sortable: true,
                        sortDirection: "asc"
                    }

                ],
                historyQuery: null,

                report: null,
                reportName: null,

                cardholderNameSearch: null,
                authorityNameSearch: null,
                messageSearch: null,
                eventTypes: null,
                sortOptions: null,
                earliestDate: null,
                latestDate: null,

                page: 1,
                rowsPerPage: 25,

                rowsPerRequest: 500,

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard
                },
            }
        },
        components: {
        },
        computed: {
            reportRows() {
                return (this.report && this.report.events && this.report.events.length) || 0;
            },
            hasRows() {
                return this.reportRows > 0;
            }
        },
        async mounted() {
            try {

                DConsole.log("History component mounted.");
                DConsole.log(this.auth);

                this.client.session = this.auth;

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                this.overview = await this.client.getReportOverview();

                this.eventTypeOptions = this.overview.eventTypes.map(x => {
                    return {
                        value: x,
                        text: ExpUtil.convertCamelCaseToTitleCase(x)
                    };
                });

                DConsole.log("Event types:");
                DConsole.log(this.eventTypeOptions);

            }
            catch (ex) {
                DConsole.log(ex);
                this.handleError(ex);

            }

        },
        methods: {

            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    //goHome: () => this.goHome()
                });
            },

            raiseNeedsAuthEvent() {
                this.$emit("needsAuth");

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (ex.status === 401) {
                    this.raiseNeedsAuthEvent();
                }
                else {
                    this.raiseErrorEvent(ex);
                }

            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            onShowConfirm() {
                this.showConfirm = true;
            },

            raiseGoHomeEvent(identityID, identityName) {
                let identity = null;
                if (identityID) {
                    identity = {
                        name: identityName,
                        id: identityID
                    };
                }
                this.$emit("goHome", identity);
            },

            clearReport() {
                ManageClient.clearCachedIdentityID();

                this.isLoadingReport = false;
                this.isLoadingOverview = false;

                this.report = null;
            },

            async onSearchEnterKey() {
                await this.getReport();
            },

            async getReport() {

                try {

                    let client = this.client || new ManageClient();

                    this.isLoadingReport = true;

                    let reportName = "";

                    let sortOptions = this.sortOptions;
                    if (sortOptions == null || (Array.isArray(sortOptions) && sortOptions.length === 0)) {
                        sortOptions = [
                            { Field: "EventTime", SortDescending: true }
                        ]
                    }
                    let latestDate = this.latestDate;
                    DConsole.log(latestDate);
                    if (latestDate && latestDate.getDate !== undefined) {
                        //Add 1 day so we include events that occurred on the specified last day.
                        let date = new Date(latestDate);
                        date.setDate(date.getDate() + 1);
                        latestDate = date;
                    }

                    let eventTypes = null;
                    if (Array.isArray(this.eventTypes)) {
                        if (this.eventTypes.length > 0) {
                            eventTypes = this.eventTypes;
                        }
                    }
                    else if (typeof this.eventTypes === "string") {
                        eventTypes = [this.eventTypes];
                    }

                    let query = {
                        PageSize: this.rowsPerRequest,
                        CardholderNameSearch: this.cardholderNameSearch,
                        MessageSearch: this.messageSearch,
                        AuthorityNameSearch: this.authorityNameSearch,
                        Sort: sortOptions,
                        EarliestEventTime: this.earliestDate,
                        LatestEventTime: latestDate,
                        EventTypes: eventTypes
                    };

                    this.reportName = reportName;
                    this.historyQuery = query;

                    let report = await client.getHistory(reportName, query);
                    this.report = report;

                    this.page = 1;
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isLoadingReport = false;

            },


            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            }

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

</style>

