//Debug console, that allows you to set a flag if we are in  Debug mode or not. If we aren't, it will disable
//logging. This doesn't replace regular console.log, though.
class DConsole {
    static log(...any) {
        if (DConsole.debug) {
            console.log(...any);
        }
    }

    static trace(...any) {
        if (DConsole.debug) {
            console.trace(...any);
        }
    }


    static debug = (process.env.NODE_ENV === "development") ? true : false;
}

export default DConsole;