<template>
    <b-container>
        <b-row align-v="start" v-if="policy && ! isIdentitySelected && ! isTokenSelected">
            <b-col align-v="start">
                <b-form-group id="fieldset-1"
                              description=""
                              label=""
                              label-for="searchBox">
                    <b-form-input id="searchBox" v-model="searchText" trim
                                  :autofocus="true"
                                  name="searchBox"
                                  type="search"
                                  autocomplete="off"
                                  :incremental="true"
                                  @search="onSearch"
                                  placeholder="Search by name or serial number"
                                  @keydown.enter.prevent="onSearchEnterKey"></b-form-input>
                </b-form-group>

                <div v-if="searchResults && searchResults.identities">
                    <b-table striped hover
                             :items="searchResults && searchResults.identities"
                             :fields="searchResults && searchResultFields"
                             primary-key="id || name"
                             @row-clicked="onRowClicked"
                             responsive
                             sticky-header
                             thead-tr-class="d-none"></b-table>
                </div>
                <b-card v-else-if="isBusySearching" class="mx-auto text-center" bg-variant="transparent">
                    <b-card-text class="mx-auto">Searching...</b-card-text>
                    <b-spinner class="mx-auto"></b-spinner>
                </b-card>
            </b-col>
        </b-row>
        <b-row align-v="start" v-if="isIdentitySelected">
            <b-col>
                <b-breadcrumb v-if="false" :items="breadcrumbs"></b-breadcrumb>
                <b-card :title="selectedIdentityName" class="h-100">
                    <div v-if="identityDetails.identity.email">{{identityDetails.identity.email}}</div>
                    <div v-if="identityDetails.identity.phone">{{identityDetails.identity.phone}}</div>
                    <b-card no-body>
                        <b-navbar toggleable="md">
                            <b-navbar-nav>
                                <b-nav-item to="/home">Overview</b-nav-item>
                                <b-nav-item-dropdown text="Credentials">
                                    <b-dropdown-item to="/home/creds">Credentials</b-dropdown-item>
                                    <b-dropdown-item to="/home/tokens">Tokens</b-dropdown-item>
                                    <b-dropdown-item to="/home/devices">Devices</b-dropdown-item>
                                    <b-dropdown-item to="/home/fido">Fido Keys</b-dropdown-item>
                                    <b-dropdown-item to="/home/certs">Certificates</b-dropdown-item>
                                </b-nav-item-dropdown>
                            </b-navbar-nav>

                            <b-navbar-toggle target="home-nav-collapse">
                                <template #default="{ expanded }">
                                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                                </template>

                            </b-navbar-toggle>

                            <b-collapse id="home-nav-collapse" is-nav>
                                <b-navbar-nav>
                                    <b-nav-item to="/home/actions">Actions</b-nav-item>
                                    <b-nav-item to="/home/history">History</b-nav-item>
                                    <b-nav-item-dropdown text="Data Stores">
                                        <b-dropdown-item v-for="ds in dataStoreDetails"
                                                            :to="'/home/d-' + (ds && ds.details && ds.details.dataStore && ds.details.dataStore.name)"
                                                            :key="((ds && ds.details && ds.details.dataStore && ds.details.dataStore.name) || '') + (ds && ds.details && ds.details.identity ? 'found' : '')">
                                            {{ds && ds.details && ds.details.dataStore && (ds.details.dataStore.label || ds.details.dataStore.name)}}
                                        </b-dropdown-item>
                                    </b-nav-item-dropdown>
                                    <b-nav-item to="/home/advanced">Advanced</b-nav-item>
                                </b-navbar-nav>
                            </b-collapse>
                        </b-navbar>
                        <div>
                            <b-card v-if="! subpage" :title="(identityOverview && identityOverview.title) || 'Overview'">
                                <b-overlay :show="isLoadingOverview || isLoadingPage">
                                    <b-row v-if="identityOverview" no-gutters>

                                        <b-card v-for="item in identityOverview.items" :key="item.title" no-body class="overflow-hidden col-12 col-md-6" style="max-width: 100%;">
                                            <b-row no-gutters>
                                                <!-- no-gutters   add no-gutters as an attribute if we don't want gutters.-->
                                                <b-col md="2">
                                                    <!--<b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img>-->
                                                    <b-icon v-if="item.status === 'Valid'" icon="check-circle-fill" font-scale="5" class="rounded-2 text-success p-3" />
                                                    <b-icon v-if="item.status === 'Invalid'" icon="x-circle-fill" font-scale="5" class="rounded-2 text-danger p-3" />
                                                    <b-icon v-if="item.status === 'None'" icon="dash-circle-fill" font-scale="5" class="rounded-2 text-secondary p-3" />
                                                </b-col>
                                                <b-col md="10">
                                                    <b-card-body :title="item.title">
                                                        <b-card-text>{{item.details}}</b-card-text>
                                                        <dl v-if="item.data">
                                                            <div v-for="(value, key) in item.data" :key="key">
                                                                <dt>{{key}}</dt>
                                                                <dd>{{formatDataValue(value)}}</dd>
                                                            </div>
                                                        </dl>
                                                    </b-card-body>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-row>
                                </b-overlay>
                            </b-card>

                            <b-card v-if="subpage === 'creds'" title="Credentials">
                                <b-overlay :show="isLoadingCredentials">
                                    <b-card no-body class="p-0">
                                        <FlexibleTabs :items="credentials" :titleProperty="shortName" :getIcon="getIconForCredential">
                                            <template #tab-description="{item: cred}">
                                                <div>
                                                    <h6>{{cred.shortName}}</h6>
                                                    <dl class="style-as-rows">
                                                        <dt :class="cred.isValid ? 'text-success' : 'text-danger'">
                                                            <b-icon v-if="cred.isValid" icon="check-lg" variant="success" />
                                                            <b-icon v-else icon="x-circle" variant="danger" />
                                                            {{cred.isValid ? "Valid" : cred.isRevoked ? "Revoked" : "Not Valid"}}
                                                        </dt>
                                                        <dd />
                                                    </dl>
                                                </div>
                                            </template>

                                            <template #default="{item: cred}">
                                                {{ (token = cred.token, device = cred.device, null) }}

                                                <b-row>
                                                    <b-col>
                                                        <b-overlay :show="isSavingCredential && editedCredential === cred">

                                                            <TokenView v-if="token"
                                                                       :token="token" :policy="policy"
                                                                       :tokenAdditionalDetails="token && token.additionalDetails"
                                                                       :allowEditExpiration="true"
                                                                       :puk="token.puk"
                                                                       :isBusy="isSavingToken && editedToken === token"
                                                                       :tokenModels="tokenModels"
                                                                       @editExpiration="onTokenEditExpiration"
                                                                       @getPUK="onGetPUK"
                                                                       @hidePUK="onHidePUK"
                                                                       @updatePhysicalStatus="onTokenUpdatePhysicalStatus">
                                                                <template #buttons>
                                                                    <div>
                                                                        <b-button-group vertical class="w-100">

                                                                            <b-button class="mt-auto mb-2" block @click="evt => onRevokeToken(token)" v-if="! token.isRevoked">Revoke</b-button>
                                                                            <b-button class="mt-auto mb-2" block @click="evt => onRevokeToken(token, true)">Delete</b-button>
                                                                            <b-button v-if="showDisableButton" block class="mt-auto mb-2" @click="evt => onDisableAccount(token)">Disable Account</b-button>
                                                                            <b-button v-if="showEnableButton" block class="mt-auto mb-2" @click="evt => onEnableAccount(token)">Enable Account</b-button>
                                                                        </b-button-group>
                                                                        <div v-if="identityOverview" class="w-100">
                                                                            <button-group vertical v-for="target in identityOverview.targetDetails" :key="target.name" class="w-100">
                                                                                <b-button v-if="showProvisionButtons && target.allowProvision" class="mt-auto mb-2" block @click="evt => onProvisionAccount(token, target)">Provision {{target.label}}</b-button>
                                                                                <b-button v-if="showProvisionButtons && target.allowDeprovision" class="mt-auto mb-2" block @click="evt => onDeprovisionAccount(token, target)">Deprovision {{target.label}}</b-button>
                                                                            </button-group>
                                                                        </div>
                                                                    </div>
                                                                </template>

                                                            </TokenView>

                                                            <b-row v-else-if="device">
                                                                <b-col>
                                                                    <b-overlay :show="isSavingDevice && editedDevice === device">
                                                                        <b-card-body class="h-100 d-flex flex-column">
                                                                            <b-row>
                                                                                <b-col md="2" class="ep-card-icon">
                                                                                    <b-card-img v-if="device.photo" :src="makePhotoDataUrl(device.photo)" class="rounded-0"></b-card-img>
                                                                                    <b-card-img v-else :src="icons.phone" class="rounded-0"></b-card-img>
                                                                                    <div class="text-danger" v-if="device.isRevoked"><b-icon icon="x-circle" variant="danger" />REVOKED</div>

                                                                                </b-col>

                                                                                <b-col md="7">
                                                                                    <dl>
                                                                                        <dt>Device Family</dt>
                                                                                        <dd>{{device.family}}</dd>
                                                                                        <dt>Name</dt>
                                                                                        <dd>{{device.name}}</dd>
                                                                                        <dt>Platform</dt>
                                                                                        <dd>{{device.platform}} {{device.platformVersion}}</dd>
                                                                                        <dt>Manufacturer</dt>
                                                                                        <dd>{{device.manufacturer}}</dd>

                                                                                        <dt v-if="device.isRevoked && device.revocationReason">Revocation Reason</dt>
                                                                                        <dd v-if="device.isRevoked && device.revocationReason">{{device.revocationReason}}</dd>
                                                                                        <dt v-if="device.isRevoked && device.revocationTimeString">Revocation Time</dt>
                                                                                        <dd v-if="device.isRevoked && device.revocationTimeString">{{device.revocationTimeString}}</dd>
                                                                                        <dt v-if="device.creationTimeString">Created</dt>
                                                                                        <dd v-if="device.creationTimeString">{{device.creationTimeString}}</dd>
                                                                                        <dt>Expiration</dt>
                                                                                        <dd>
                                                                                            {{device.expirationTime ? device.expirationTimeString : "None"}}
                                                                                            <b-button size="sm" variant="outline-primary"
                                                                                                      @click="isEditingDeviceExpiration = true; editedDevice = device; editedDeviceExpiration = device.expirationTime">Edit</b-button>
                                                                                        </dd>
                                                                                        <!--
                                                                <dt>Device Token for Push</dt>
                                                                <dd><small>{{device.formattedPushDeviceToken}}</small></dd>
                                                                -->
                                                                                    </dl>
                                                                                </b-col>
                                                                                <b-col md="3">
                                                                                    <div>
                                                                                        <b-button-group vertical class="w-100">
                                                                                            <b-button class="mt-auto mb-2" @click="evt => onRevokeDevice(device)" v-if="! device.isRevoked">Revoke</b-button>
                                                                                            <b-button class="mt-auto mb-2" @click="evt => onRevokeDevice(device, true)">Delete</b-button>
                                                                                        </b-button-group>
                                                                                    </div>
                                                                                </b-col>
                                                                            </b-row>

                                                                        </b-card-body>
                                                                    </b-overlay>
                                                                </b-col>
                                                            </b-row>

                                                            <b-card-body v-else class="h-100 d-flex flex-column">
                                                                <b-row>
                                                                    <b-col md="2" class="ep-card-icon">
                                                                        <b-card-img :src="getIconForCredential(cred)" class="rounded-0"></b-card-img>
                                                                        <div class="text-danger" v-if="cred.isRevoked">REVOKED</div>
                                                                    </b-col>

                                                                    <b-col md="7">
                                                                        <dl>
                                                                            <dt v-if="cred.name">Name</dt>
                                                                            <dd v-if="cred.name">{{cred.name}}</dd>
                                                                        </dl>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-card-body>

                                                            <b-row v-if="Array.isArray(cred.fidoKeys) && cred.fidoKeys.length > 0">
                                                                <b-col>
                                                                    <b-card class="m-2" title="FIDO Keys">
                                                                        <FlexibleTabs :items="cred.fidoKeys" :titleProperty="shortName" :getIcon="getIconForFidoKey">
                                                                            <template #tab-description="{item: key}">
                                                                                <div>
                                                                                    <h6>{{key.shortName}}</h6>
                                                                                    <dl class="style-as-rows">
                                                                                        <dt>Relying Party</dt>
                                                                                        <dd>{{key.relyingPartyID}}</dd>
                                                                                        <dt :class="key.isValid ? 'text-success' : 'text-danger'">
                                                                                            <b-icon v-if="key.isValid" icon="check-lg" variant="success" />
                                                                                            <b-icon v-else icon="x-lg" variant="danger" />
                                                                                            {{key.isValid ? "Valid" : key.isRevoked ? "Revoked" : "Not Valid"}}
                                                                                        </dt>
                                                                                        <dd />
                                                                                    </dl>
                                                                                </div>
                                                                            </template>

                                                                            <template #default="{item: key}">
                                                                                <b-row>
                                                                                    <b-col>
                                                                                        <b-overlay :show="isSavingFidoKey && (editedFidoKey && editedFidoKey.equals(key))">
                                                                                            <b-card-body class="h-100 d-flex flex-column">
                                                                                                <b-row>
                                                                                                    <b-col md="2" class="ep-card-icon">
                                                                                                        <b-card-img v-if="key.token && key.token.photo" :src="makePhotoDataUrl(token.photo)" class="rounded-0"></b-card-img>
                                                                                                        <b-card-img v-else-if="(key.token && key.token.family.startsWith('YubiKey')) || (key.attestationCertName && key.attestationCertName.includes('Yubi'))" :src="icons.yubiKey" class="rounded-0 "></b-card-img>
                                                                                                        <b-card-img v-else-if="key.token && key.token.family.startsWith('IDOne')" :src="icons.smartCard" class="rounded-0 "></b-card-img>
                                                                                                        <b-card-img v-else-if="key.userDeviceID" :src="icons.phone" class="rounded-0"></b-card-img>
                                                                                                        <div class="text-danger font-weight-bold text-center" v-if="key.isRevoked">
                                                                                                            <b-icon icon="x-lg" variant="danger" v-if="key.isRevoked" />
                                                                                                            <span> REVOKED</span>
                                                                                                        </div>
                                                                                                        
                                                                                                    </b-col>

                                                                                                    <b-col md="7">
                                                                                                        <dl>
                                                                                                            <dt v-if="key.token || ! key.userDeviceID">Token</dt>
                                                                                                            <dd v-if="key.token || ! key.userDeviceID">{{key.token ? key.token.shortName : "Web Registered"}}</dd>
                                                                                                            <dt v-if="key.userDeviceID">Device</dt>
                                                                                                            <dd v-if="key.userDeviceID">Mobile</dd> <!--TODO: Make this more dynamic.-->
                                                                                                            <!--<dd v-if="key.userDeviceID"><small>{{ key.userDeviceID }}</small></dd>-->
                                                                                                            <dt v-if="key.isRevoked && key.revocationReason">Revocation Reason</dt>
                                                                                                            <dd v-if="key.isRevoked && key.revocationReason">{{key.revocationReason}}</dd>
                                                                                                            <dt v-if="key.isRevoked && key.revocationTimeString">Revocation Time</dt>
                                                                                                            <dd v-if="key.isRevoked && key.revocationTimeString">{{key.revocationTimeString}}</dd>
                                                                                                            <dt>Created</dt>
                                                                                                            <dd>{{key.creationTimeString}}</dd>
                                                                                                            <dt>Expiration</dt>
                                                                                                            <dd>
                                                                                                                {{key.expirationTime ? key.expirationTimeString : "None"}}
                                                                                                                <b-button size="sm" variant="outline-primary"
                                                                                                                          @click="isEditingFidoKeyExpiration = true; editedFidoKey = key; editedFidoKeyExpiration = key.expirationTime">Edit</b-button>
                                                                                                            </dd>
                                                                                                            <dt>Attestation</dt>
                                                                                                            <dd>
                                                                                                                {{key.attestationCertX509 ? key.attestationCertName : "Self"}}
                                                                                                                <b-button v-if="key.attestationCertX509" size="sm" variant="outline-primary" @click="evt => saveCertificate(key.attestationCertX509)">Download</b-button>
                                                                                                            </dd>
                                                                                                            <dt>Relying Party Name (and ID)</dt>
                                                                                                            <dd>{{key.relyingPartyName ? key.relyingPartyName + " (" + key.relyingPartyID + ")" : "Unspecified"}}</dd>

                                                                                                            <!--
                                                                                    <dt>Credential ID</dt>
                                                                                    <dd class="text-truncate"><small>{{key.credentialID}}</small></dd>
                                                                                    <dt>User Handle</dt>
                                                                                    <dd class="text-truncate"><small>{{key.userHandle}}</small></dd>
                                                                                    -->
                                                                                                            <dt v-if="key.counter !== undefined && key.counter !== null">Signature Counter</dt>
                                                                                                            <dd v-if="key.counter !== undefined && key.counter !== null">{{key.counter}}</dd>
                                                                                                        </dl>
                                                                                                    </b-col>
                                                                                                    <b-col md="3">
                                                                                                        <div>
                                                                                                            <b-button-group vertical class="w-100">
                                                                                                                <b-button class="mt-auto mb-2" @click="evt => onRevokeFidoKey(key)" v-if="! key.isRevoked">Revoke</b-button>
                                                                                                                <b-button class="mt-auto mb-2" @click="evt => onUnrevokeFidoKey(key)" v-if="key.isRevoked">Unrevoke</b-button>
                                                                                                                <b-button class="mt-auto" @click="evt => onDeleteFidoKey(key)">Delete</b-button>
                                                                                                            </b-button-group>
                                                                                                        </div>
                                                                                                    </b-col>
                                                                                                </b-row>
                                                                                            </b-card-body>
                                                                                        </b-overlay>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </template>
                                                                        </FlexibleTabs>
                                                                    </b-card>
                                                                </b-col>
                                                            </b-row>

                                                            <b-row v-if="Array.isArray(cred.certificates) && cred.certificates.length > 0">
                                                                <b-col>
                                                                    <b-card class="m-2" title="Certificates">
                                                                        <FlexibleTabs :items="cred.certificates" :titleProperty="name" :getIcon="() => icons.certificate">
                                                                            <template #tab-description="{item: cert}">
                                                                                <div>
                                                                                    <h6>{{(cert.isPivDerivation ? "PIV " : "") + cert.name}}</h6>
                                                                                    <dl class="style-as-rows">
                                                                                        <dt :class="cert.isValid ? 'text-success' : 'text-danger'">
                                                                                            <b-icon v-if="cert.isValid" icon="check-lg" variant="success" />
                                                                                            <b-icon v-else icon="x-circle" variant="danger" />
                                                                                            {{cert.isValid ? "Valid" : cert.isRevoked ? "Revoked" : "Not Valid"}}
                                                                                        </dt>
                                                                                        <dd />
                                                                                    </dl>
                                                                                </div>
                                                                            </template>
                                                                            <template v-slot:title>
                                                                                <div :class="cert.isPivDerivation ? 'mt-2 pt-2 border-top border-primary' : ''">
                                                                                    <b-icon v-if="cert.isValid" icon="check-lg" variant="success" />
                                                                                    <b-icon v-else icon="x-circle" variant="danger" />
                                                                                    {{cert.name}}
                                                                                </div>
                                                                            </template>

                                                                            <template #default="{item: cert}">
                                                                                <b-row>
                                                                                    <b-col>
                                                                                        <b-overlay :show="isSavingCertificate && editedCertificate === cert">
                                                                                            <b-card-body class="h-100 d-flex flex-column">
                                                                                                <b-row>
                                                                                                    <b-col md="2" class="ep-card-icon">
                                                                                                        <b-card-img :src="icons.certificate" class="rounded-0"></b-card-img>
                                                                                                        <div class="text-danger" v-if="cert.isRevoked"><b-icon icon="x-circle" variant="danger" />REVOKED</div>

                                                                                                    </b-col>


                                                                                                    <b-col md="7">
                                                                                                        <dl>
                                                                                                            <dt v-if="cert.longPivSlotName">PIV Slot</dt>
                                                                                                            <dd v-if="cert.longPivSlotName"><span v-if="cert.isPivDerivation">From PIV: </span>{{cert.longPivSlotName}}</dd>

                                                                                                            <dt>Subject</dt>
                                                                                                            <dd>{{cert.certX509.subject}}</dd>
                                                                                                            <dt>Issuer</dt>
                                                                                                            <dd>{{cert.certX509.issuer}}</dd>
                                                                                                            <dt>Issued</dt>
                                                                                                            <dd>{{cert.notBeforeString}}</dd>
                                                                                                            <dt>Expiration</dt>
                                                                                                            <dd>{{cert.notAfterString}}</dd>
                                                                                                            <dt v-if="cert.subjectUPN">UPN</dt>
                                                                                                            <dd>{{cert.subjectUPN}}</dd>
                                                                                                            <dt v-if="cert.subjectEmail">Email</dt>
                                                                                                            <dd>{{cert.subjectEmail}}</dd>
                                                                                                            <dt v-if="cert.subjectGuid">Subject GUID</dt>
                                                                                                            <dd>{{cert.subjectGuid}}</dd>
                                                                                                            <dt>Serial Number</dt>
                                                                                                            <dd>{{cert.certX509.serialNumber}}</dd>
                                                                                                            <dt>Status</dt>
                                                                                                            <dd>{{cert.status && cert.status.isRevoked ? "Revoked" : "Valid"}}</dd>
                                                                                                            <dt>Last Revocation Check</dt>
                                                                                                            <dd>{{cert.lastConfirmationTimeString}}</dd>
                                                                                                            <dt v-if="cert.importTimeString">Imported</dt>
                                                                                                            <dd v-if="cert.importTimeString">{{cert.importTimeString}}</dd>
                                                                                                        </dl>
                                                                                                    </b-col>
                                                                                                    <b-col md="3">
                                                                                                        <div>
                                                                                                            <b-button-group vertical class="w-100">
                                                                                                                <b-button class="mt-auto mb-2" @click="evt => saveCertificate(cert.certX509)">Download</b-button>
                                                                                                                <b-button v-if="cert.canRevoke" class="mt-auto mb-2" @click="evt => onRevokeCertificate(cert)">Revoke</b-button>
                                                                                                                <b-button class="mt-auto" @click="evt => onDeleteCertificate(cert)">Delete</b-button>
                                                                                                            </b-button-group>
                                                                                                        </div>
                                                                                                    </b-col>
                                                                                                </b-row>
                                                                                            </b-card-body>
                                                                                        </b-overlay>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </template>
                                                                        </FlexibleTabs>

                                                                    </b-card>
                                                                </b-col>
                                                            </b-row>

                                                        </b-overlay>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </FlexibleTabs>
                                    </b-card>
                                </b-overlay>
                            </b-card>

                            <b-card v-if="subpage === 'tokens'" title="Tokens">
                                <b-overlay :show="isLoadingTokens">
                                    <b-card no-body class="p-0">
                                        <FlexibleTabs :items="tokens" :titleProperty="shortName" :getIcon="getIconForToken">
                                            <template #default="{item: token}">
                                                <TokenView :token="token" :policy="policy"
                                                           :tokenAdditionalDetails="token && token.additionalDetails"
                                                           :allowEditExpiration="true"
                                                           :puk="token.puk"
                                                           :isBusy="isSavingToken && editedToken === token"
                                                           :tokenModels="tokenModels"
                                                           @editExpiration="onTokenEditExpiration"
                                                           @getPUK="onGetPUK"
                                                           @hidePUK="onHidePUK"
                                                           @updatePhysicalStatus="onTokenUpdatePhysicalStatus">
                                                    <template #buttons>
                                                        <div>
                                                            <b-button-group vertical class="w-100">

                                                                <b-button class="mt-auto mb-2" block @click="evt => onRevokeToken(token)" v-if="! token.isRevoked">Revoke</b-button>
                                                                <b-button class="mt-auto mb-2" block @click="evt => onRevokeToken(token, true)">Delete</b-button>
                                                                <b-button v-if="showDisableButton" block class="mt-auto mb-2" @click="evt => onDisableAccount(token)">Disable Account</b-button>
                                                                <b-button v-if="showEnableButton" block class="mt-auto mb-2" @click="evt => onEnableAccount(token)">Enable Account</b-button>
                                                            </b-button-group>
                                                            <div v-if="identityOverview" class="w-100">
                                                                <button-group vertical v-for="target in identityOverview.targetDetails" :key="target.name" class="w-100">
                                                                    <b-button v-if="showProvisionButtons && target.allowProvision" class="mt-auto mb-2" block @click="evt => onProvisionAccount(token, target)">Provision {{target.label}}</b-button>
                                                                    <b-button v-if="showProvisionButtons && target.allowDeprovision" class="mt-auto mb-2" block @click="evt => onDeprovisionAccount(token, target)">Deprovision {{target.label}}</b-button>
                                                                </button-group>
                                                            </div>
                                                        </div>
                                                    </template>

                                                </TokenView>
                                            </template>

                                            <template #tab-description="{item: token}">
                                                <div>
                                                    <h6>{{token.shortName}}</h6>
                                                    <dl class="style-as-rows">
                                                        <dt v-if="token.modelName">Model</dt>
                                                        <dd v-if="token.modelName">{{token.modelName}}</dd>
                                                        <dt :class="token.isValid ? 'text-success' : 'text-danger'">
                                                            <b-icon v-if="token.isValid" icon="check-lg" variant="success" />
                                                            <b-icon v-else icon="x-circle" variant="danger" />
                                                            {{token.isValid ? "Valid" : token.isRevoked ? "Revoked" : "Not Valid"}}
                                                        </dt>
                                                        <dd />
                                                    </dl>
                                                </div>
                                            </template>

                                            <!-- New Tab Button (Using tabs-end slot) -->
                                            <template #tabs-end v-if="policy.allowInventoryManagement">
                                                <b-nav-item role="presentation" @click.prevent="onAssignToken" href="#"><b>+</b> Assign a Token</b-nav-item>
                                            </template>

                                            <!-- Render this if no tabs -->
                                            <template #empty v-if="policy.allowInventoryManagement && ! isLoadingTokens">
                                                <div class="text-center text-muted">
                                                    There are no tokens for this user.<br>
                                                    You can manually assign a token using the <b>+</b> button to the left.
                                                </div>
                                            </template>

                                        </FlexibleTabs>

                                    </b-card>
                                </b-overlay>

                            </b-card>

                            <b-card v-if="subpage === 'devices'" title="Devices">
                                <b-overlay :show="isLoadingDevices">
                                    <b-card no-body class="p-0">
                                        <FlexibleTabs :items="devices" :titleProperty="name" :getIcon="() => icons.phone">
                                            <template #tab-description="{item: device}">
                                                <div>
                                                    <h6>{{device.name}}</h6>
                                                    <dl class="style-as-rows">
                                                        <dt>Platform</dt>
                                                        <dd>{{device.platform}}</dd>
                                                        <dt :class="device.isValid ? 'text-success' : 'text-danger'">
                                                            <b-icon v-if="device.isValid" icon="check-lg" variant="success" />
                                                            <b-icon v-else icon="x-circle" variant="danger" />
                                                            {{device.isValid ? "Valid" : device.isRevoked ? "Revoked" : "Not Valid"}}
                                                        </dt>
                                                        <dd />
                                                    </dl>
                                                </div>
                                            </template>

                                            <template #default="{item: device}">
                                                <b-row>
                                                    <b-col>
                                                        <b-overlay :show="isSavingDevice && editedDevice === device">
                                                            <b-card-body class="h-100 d-flex flex-column">
                                                                <b-row>
                                                                    <b-col md="2" class="ep-card-icon">
                                                                        <b-card-img v-if="device.photo" :src="makePhotoDataUrl(device.photo)" class="rounded-0"></b-card-img>
                                                                        <b-card-img v-else :src="icons.phone" class="rounded-0"></b-card-img>
                                                                        <div class="text-danger" v-if="device.isRevoked"><b-icon icon="x-circle" variant="danger" />REVOKED</div>

                                                                    </b-col>

                                                                    <b-col md="7">
                                                                        <dl>
                                                                            <dt>Device Family</dt>
                                                                            <dd>{{device.family}}</dd>
                                                                            <dt>Name</dt>
                                                                            <dd>{{device.name}}</dd>
                                                                            <dt>Platform</dt>
                                                                            <dd>{{device.platform}} {{device.platformVersion}}</dd>
                                                                            <dt>Manufacturer</dt>
                                                                            <dd>{{device.manufacturer}}</dd>

                                                                            <dt v-if="device.isRevoked && device.revocationReason">Revocation Reason</dt>
                                                                            <dd v-if="device.isRevoked && device.revocationReason">{{device.revocationReason}}</dd>
                                                                            <dt v-if="device.isRevoked && device.revocationTimeString">Revocation Time</dt>
                                                                            <dd v-if="device.isRevoked && device.revocationTimeString">{{device.revocationTimeString}}</dd>
                                                                            <dt v-if="device.creationTimeString">Created</dt>
                                                                            <dd v-if="device.creationTimeString">{{device.creationTimeString}}</dd>
                                                                            <dt>Expiration</dt>
                                                                            <dd>
                                                                                {{device.expirationTime ? device.expirationTimeString : "None"}}
                                                                                <b-button size="sm" variant="outline-primary"
                                                                                          @click="isEditingDeviceExpiration = true; editedDevice = device; editedDeviceExpiration = device.expirationTime">Edit</b-button>
                                                                            </dd>
                                                                            <!--
                                                    <dt>Device Token for Push</dt>
                                                    <dd><small>{{device.formattedPushDeviceToken}}</small></dd>
                                                    -->
                                                                        </dl>
                                                                    </b-col>
                                                                    <b-col md="3">
                                                                        <div>
                                                                            <b-button-group vertical class="w-100">
                                                                                <b-button class="mt-auto mb-2" @click="evt => onRevokeDevice(device)" v-if="! device.isRevoked">Revoke</b-button>
                                                                                <b-button class="mt-auto mb-2" @click="evt => onRevokeDevice(device, true)">Delete</b-button>
                                                                            </b-button-group>
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>

                                                            </b-card-body>
                                                        </b-overlay>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </FlexibleTabs>
                                    </b-card>
                                </b-overlay>
                            </b-card>
                            <b-card v-if="subpage === 'fido'" title="FIDO Keys">
                                <b-overlay :show="isLoadingFidoKeys">
                                    <b-card no-body class="p-0">
                                        <FlexibleTabs :items="fidoKeys" :titleProperty="shortName" :getIcon="getIconForFidoKey">
                                            <template #tab-description="{item: key}">
                                                <div>
                                                    <h6>{{key.shortName}}</h6>
                                                    <dl class="style-as-rows">
                                                        <dt>Relying Party</dt>
                                                        <dd>{{key.relyingPartyID}}</dd>
                                                        <dt :class="key.isValid ? 'text-success' : 'text-danger'">
                                                            <b-icon v-if="key.isValid" icon="check-lg" variant="success" />
                                                            <b-icon v-else icon="x-lg" variant="danger" />
                                                            {{key.isValid ? "Valid" : key.isRevoked ? "Revoked" : "Not Valid"}}
                                                        </dt>
                                                        <dd />
                                                    </dl>
                                                </div>
                                            </template>

                                            <template #default="{item: key}">
                                                <b-row>
                                                    <b-col>
                                                        <b-overlay :show="isSavingFidoKey && editedFidoKey === key">
                                                            <b-card-body class="h-100 d-flex flex-column">
                                                                <b-row>
                                                                    <b-col md="2" class="ep-card-icon">
                                                                        <b-card-img v-if="key.token && key.token.photo" :src="makePhotoDataUrl(token.photo)" class="rounded-0"></b-card-img>
                                                                        <b-card-img v-else-if="(key.token && key.token.family.startsWith('YubiKey')) || (key.attestationCertName && key.attestationCertName.includes('Yubi'))" :src="icons.yubiKey" class="rounded-0 "></b-card-img>
                                                                        <b-card-img v-else-if="key.token && key.token.family.startsWith('IDOne')" :src="icons.smartCard" class="rounded-0 "></b-card-img>
                                                                        <b-card-img v-else-if="key.userDeviceID" :src="icons.phone" class="rounded-0"></b-card-img>
                                                                        <div class="text-danger" v-if="key.isRevoked">REVOKED</div>
                                                                    </b-col>

                                                                    <b-col md="7">
                                                                        <dl>
                                                                            <dt v-if="key.token || ! key.userDeviceID">Token</dt>
                                                                            <dd v-if="key.token || ! key.userDeviceID">{{key.token ? key.token.shortName : "Web Registered"}}</dd>
                                                                            <dt v-if="key.userDeviceID">Device</dt>
                                                                            <dd v-if="key.userDeviceID">Mobile</dd> <!--TODO: Make this more dynamic.-->
                                                                            <!--<dd v-if="key.userDeviceID"><small>{{ key.userDeviceID }}</small></dd>-->
                                                                            <dt v-if="key.isRevoked && key.revocationReason">Revocation Reason</dt>
                                                                            <dd v-if="key.isRevoked && key.revocationReason">{{key.revocationReason}}</dd>
                                                                            <dt v-if="key.isRevoked && key.revocationTimeString">Revocation Time</dt>
                                                                            <dd v-if="key.isRevoked && key.revocationTimeString">{{key.revocationTimeString}}</dd>
                                                                            <dt>Created</dt>
                                                                            <dd>{{key.creationTimeString}}</dd>
                                                                            <dt>Expiration</dt>
                                                                            <dd>
                                                                                {{key.expirationTime ? key.expirationTimeString : "None"}}
                                                                                <b-button size="sm" variant="outline-primary"
                                                                                          @click="isEditingFidoKeyExpiration = true; editedFidoKey = key; editedFidoKeyExpiration = key.expirationTime">Edit</b-button>
                                                                            </dd>
                                                                            <dt>Attestation</dt>
                                                                            <dd>
                                                                                {{key.attestationCertX509 ? key.attestationCertName : "Self"}}
                                                                                <b-button v-if="key.attestationCertX509" size="sm" variant="outline-primary" @click="evt => saveCertificate(key.attestationCertX509)">Download</b-button>
                                                                            </dd>
                                                                            <dt>Relying Party Name (and ID)</dt>
                                                                            <dd>{{key.relyingPartyName ? key.relyingPartyName + " (" + key.relyingPartyID + ")" : "Unspecified"}}</dd>

                                                                            <!--
                                                    <dt>Credential ID</dt>
                                                    <dd class="text-truncate"><small>{{key.credentialID}}</small></dd>
                                                    <dt>User Handle</dt>
                                                    <dd class="text-truncate"><small>{{key.userHandle}}</small></dd>
                                                    -->
                                                                            <dt v-if="key.counter !== undefined && key.counter !== null">Signature Counter</dt>
                                                                            <dd v-if="key.counter !== undefined && key.counter !== null">{{key.counter}}</dd>
                                                                        </dl>
                                                                    </b-col>
                                                                    <b-col md="3">
                                                                        <div>
                                                                            <b-button-group vertical class="w-100">
                                                                                <b-button class="mt-auto mb-2" @click="evt => onRevokeFidoKey(key)" v-if="! key.isRevoked">Revoke</b-button>
                                                                                <b-button class="mt-auto mb-2" @click="evt => onUnrevokeFidoKey(key)" v-if="key.isRevoked">Unrevoke</b-button>
                                                                                <b-button class="mt-auto" @click="evt => onDeleteFidoKey(key)">Delete</b-button>
                                                                            </b-button-group>
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-card-body>
                                                        </b-overlay>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </FlexibleTabs>
                                    </b-card>
                                </b-overlay>

                            </b-card>
                            <b-card v-if="subpage === 'certs'" title="Certificates">
                                <b-overlay :show="isLoadingCertificates">
                                    <b-card no-body class="p-0">
                                        <FlexibleTabs :items="certificates" :titleProperty="name" :getIcon="() => icons.certificate">
                                            <template #tab-description="{item: cert}">
                                                <div>
                                                    <h6>{{(cert.isPivDerivation ? "PIV " : "") + cert.name}}</h6>
                                                    <dl class="style-as-rows">
                                                        <dt :class="cert.isValid ? 'text-success' : 'text-danger'">
                                                            <b-icon v-if="cert.isValid" icon="check-lg" variant="success" />
                                                            <b-icon v-else icon="x-circle" variant="danger" />
                                                            {{cert.isValid ? "Valid" : cert.isRevoked ? "Revoked" : "Not Valid"}}
                                                        </dt>
                                                        <dd />
                                                    </dl>
                                                </div>
                                            </template>
                                            <template v-slot:title>
                                                <div :class="cert.isPivDerivation ? 'mt-2 pt-2 border-top border-primary' : ''">
                                                    <b-icon v-if="cert.isValid" icon="check-lg" variant="success" />
                                                    <b-icon v-else icon="x-circle" variant="danger" />
                                                    {{cert.name}}
                                                </div>
                                            </template>

                                            <template #default="{item: cert}">
                                                <b-row>
                                                    <b-col>
                                                        <b-overlay :show="isSavingCertificate && editedCertificate === cert">
                                                            <b-card-body class="h-100 d-flex flex-column">
                                                                <b-row>
                                                                    <b-col md="2" class="ep-card-icon">
                                                                        <b-card-img :src="icons.certificate" class="rounded-0"></b-card-img>
                                                                        <div class="text-danger" v-if="cert.isRevoked"><b-icon icon="x-circle" variant="danger" />REVOKED</div>

                                                                    </b-col>


                                                                    <b-col md="7">
                                                                        <dl>
                                                                            <dt v-if="cert.longPivSlotName">PIV Slot</dt>
                                                                            <dd v-if="cert.longPivSlotName"><span v-if="cert.isPivDerivation">From PIV: </span>{{cert.longPivSlotName}}</dd>

                                                                            <dt>Subject</dt>
                                                                            <dd>{{cert.certX509.subject}}</dd>
                                                                            <dt>Issuer</dt>
                                                                            <dd>{{cert.certX509.issuer}}</dd>
                                                                            <dt>Issued</dt>
                                                                            <dd>{{cert.notBeforeString}}</dd>
                                                                            <dt>Expiration</dt>
                                                                            <dd>{{cert.notAfterString}}</dd>
                                                                            <dt v-if="cert.subjectUPN">UPN</dt>
                                                                            <dd>{{cert.subjectUPN}}</dd>
                                                                            <dt v-if="cert.subjectEmail">Email</dt>
                                                                            <dd>{{cert.subjectEmail}}</dd>
                                                                            <dt v-if="cert.subjectGuid">Subject GUID</dt>
                                                                            <dd>{{cert.subjectGuid}}</dd>
                                                                            <dt>Serial Number</dt>
                                                                            <dd>{{cert.certX509.serialNumber}}</dd>
                                                                            <dt>Status</dt>
                                                                            <dd>{{cert.status && cert.status.isRevoked ? "Revoked" : "Valid"}}</dd>
                                                                            <dt>Last Revocation Check</dt>
                                                                            <dd>{{cert.lastConfirmationTimeString}}</dd>
                                                                            <dt v-if="cert.importTimeString">Imported</dt>
                                                                            <dd v-if="cert.importTimeString">{{cert.importTimeString}}</dd>
                                                                        </dl>
                                                                    </b-col>
                                                                    <b-col md="3">
                                                                        <div>
                                                                            <b-button-group vertical class="w-100">
                                                                                <b-button class="mt-auto mb-2" @click="evt => saveCertificate(cert.certX509)">Download</b-button>
                                                                                <b-button v-if="cert.canRevoke" class="mt-auto mb-2" @click="evt => onRevokeCertificate(cert)">Revoke</b-button>
                                                                                <b-button class="mt-auto" @click="evt => onDeleteCertificate(cert)">Delete</b-button>
                                                                            </b-button-group>
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-card-body>
                                                        </b-overlay>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </FlexibleTabs>
                                    </b-card>
                                </b-overlay>
                            </b-card>
                            <b-card v-if="subpage === 'actions'" title="Self-Service Actions">
                                <b-card>
                                    <b-card-body>
                                        <b-overlay :show="isLoadingRemoteActions || isSavingAction || isLoadingActions">
                                            <b-row>
                                                <b-col>
                                                    <h5>Authorized Self-Service Actions</h5>
                                                    <p>{{selectedIdentityName}} will currently see these actions as options when opening the self-service Activator app.</p>
                                                    <b-table sticky-header striped hover
                                                             :items="cardholderActions"
                                                             :fields="actionFields"
                                                             primary-key="id"
                                                             :busy="isLoadingActions"
                                                             sort-by="action" :sort-desc="false"
                                                             responsive outlined>
                                                        <template v-slot:cell(cancel)="{ item }">
                                                            <span><b-button v-if="item.scheduledActionID" @click="evt => onCancelAction(item)" size="sm" variant="outline-primary">Cancel</b-button></span>
                                                        </template>

                                                    </b-table>
                                                    <h5>Available Self-Service Actions</h5>
                                                    <p>Click authorize to enable {{selectedIdentityName}} to perform an action with the self-service Activator app.</p>
                                                    <b-table sticky-header striped hover
                                                             :items="availableRemoteActionsForCardholder"
                                                             :fields="availableActionFields"
                                                             primary-key="id"
                                                             :busy="isLoadingRemoteActions"
                                                             sort-by="action" :sort-desc="false"
                                                             responsive outlined>
                                                        <template v-slot:cell(authorize)="{ item }">
                                                            <span><b-button @click="evt => onAuthorizeActionForCardholder(item)" size="sm" variant="outline-primary">Authorize</b-button></span>
                                                        </template>

                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-overlay>
                                    </b-card-body>
                                </b-card>
                            </b-card>
                            <b-card v-if="subpage === 'history'" title="History">
                                <b-overlay :show="isLoadingHistory">
                                    <b-row>
                                        <b-col>
                                            <b-pagination v-if="historyReportRows > historyRowsPerPage"
                                                          v-model="historyPage"
                                                          :total-rows="historyReportRows"
                                                          :per-page="historyRowsPerPage"
                                                          aria-controls="historyReportTable" />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-table sticky-header="60vh" striped hover
                                                     :items="cardholderHistory && cardholderHistory.events"
                                                     :fields="historyFields"
                                                     primary-key="id"
                                                     :busy="isLoadingHistory"
                                                     sort-by="eventTime" :sort-desc="true"
                                                     :current-page="historyPage" :per-page="historyRowsPerPage"
                                                     responsive outlined />
                                        </b-col>
                                    </b-row>
                                </b-overlay>
                            </b-card>
                            <b-card v-for="ds in dataStoreDetails.filter(x => x && x.details && x.details.dataStore && subpage === 'd-' + x.details.dataStore.name)" :title="ds && ds.details && ds.details.dataStore && (ds.details.dataStore.label || ds.details.dataStore.name)"
                                    :key="(ds && ds.details && ds.details.dataStore && ds.details.dataStore.name && '') + (ds && ds.details && ds.details.identity ? 'found' : '')">
                                <b-overlay :show="ds.isLoading">
                                    <b-card :bg-variant="ds && ds.error ? 'danger' : null" :text-variant="ds && ds.error ? 'white' : null">
                                        <b-card-title v-if="ds && ds.details && ds.details.dataStore">{{ds.details.dataStore.label || ds.details.dataStore.name}}</b-card-title>
                                        <b-card-text v-if="ds && ds.details && ds.details.dataStore && ds.details.dataStore.description">{{ds.details.dataStore.description}}</b-card-text>
                                        <b-card-text v-if="ds && ds.error"><b-icon icon="exclamation-triangle" class="mr-3" font-scale="3" /><span>{{ds.error}}</span></b-card-text>
                                        <b-card-body class="h-100 d-flex flex-column">
                                            <dl v-if="ds && ds.details && ds.details.identity">
                                                <div v-for="(value, key) in ds.details.identity" :key="key">
                                                    <template v-if="key !== 'additional' && key !== 'groupListIsValid'">
                                                        <dt>{{key}}</dt>
                                                        <dd>{{value}}</dd>
                                                    </template>
                                                </div>
                                                <template v-if="ds.details.identity && ds.details.identity.additional">
                                                    <div v-for="(value, key) in ds.details.identity.additional" :key="key">
                                                        <template>
                                                            <dt>{{key}}</dt>
                                                            <dd v-if="value && value.type === 'Json' && Array.isArray(value.value)"><b-table :items="value.value" stacked /></dd>
                                                            <dd v-else-if="value && value.type === 'Json'"><pre>{{value.value}}</pre></dd>
                                                            <dd v-else-if="value && value.value !== undefined">{{value.value}}</dd>
                                                        </template>
                                                    </div>
                                                </template>
                                            </dl>
                                        </b-card-body>
                                    </b-card>
                                </b-overlay>
                            </b-card>
                            <b-card v-if="subpage === 'advanced'" title="Advanced">
                                <b-overlay :show="isLoadingTokens">
                                    <b-card>
                                        <b-card-body class="h-100 d-flex flex-column">
                                            <dl>
                                                <dt>Name</dt>
                                                <dd>{{identityDetails.identity.name}}</dd>
                                                <dt>ID</dt>
                                                <dd>{{identityDetails.identity.id}}</dd>
                                            </dl>
                                        </b-card-body>
                                    </b-card>
                                </b-overlay>
                            </b-card>

                            <b-modal id="confirmRevokeToken" :title="revokeTokenConfirmTitle" :centered="true" @ok="onRevokeTokenConfirmed" @cancel="onRevokeTokenCanceled" @close="onRevokeTokenCanceled">
                                <p>{{revokeTokenConfirmMessage}}</p>
                                <b-form-checkbox v-if="revokeTokenConfirmShowRevokeCheckbox" v-model="revokeTokenRevokesCerts">Revoke certificates</b-form-checkbox>
                                <b-form-checkbox v-if="revokeTokenConfirmShowDeleteCheckbox" v-model="revokeTokenDeletesCerts">Delete certificates</b-form-checkbox>
                            </b-modal>

                            <b-modal v-model="isEditingFidoKeyExpiration"
                                     @ok="onSaveFidoKeyExpiration"
                                     title="Edit Expiration Date"
                                     header-bg-variant="dark" header-text-variant="light"
                                     centered>
                                <b-form-group label="Expiration Date"
                                              description="Please choose a new expiration date for this key.">
                                    <b-datepicker v-model="editedFidoKeyExpiration" value-as-date />
                                </b-form-group>
                            </b-modal>
                            <b-modal v-model="isEditingTokenExpiration"
                                     @ok="onSaveTokenExpiration"
                                     title="Edit Expiration Date"
                                     header-bg-variant="dark" header-text-variant="light"
                                     centered>
                                <b-form-group label="Expiration Date"
                                              description="Please choose a new expiration date for this token.">
                                    <b-datepicker v-model="editedTokenExpiration" value-as-date />
                                </b-form-group>
                            </b-modal>
                            <b-modal v-model="isEditingDeviceExpiration"
                                     @ok="onSaveDeviceExpiration"
                                     title="Edit Expiration Date"
                                     header-bg-variant="dark" header-text-variant="light"
                                     centered>
                                <b-form-group label="Expiration Date"
                                              description="Please choose a new expiration date for this device.">
                                    <b-datepicker v-model="editedDeviceExpiration" value-as-date />
                                </b-form-group>
                            </b-modal>
                        </div>
                    </b-card>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else-if="isTokenSelected">
            <b-col>
                <b-card :title="selectedTokenDetails && selectedTokenDetails.token && selectedTokenDetails.token.name" class="h-100">
                    <b-overlay :show="isLoadingTokens">
                        <b-card no-body>
                            <b-tabs card vertical>
                                <b-tab>
                                    <template v-slot:title>
                                        <b-icon v-if="selectedTokenDetails.token.isValid" icon="check-lg" variant="success" />
                                        <b-icon v-else icon="x-circle" variant="danger" />
                                        {{selectedTokenDetails.token.shortName}}
                                    </template>

                                    <TokenView :token="selectedTokenDetails.token" :policy="policy"
                                               :tokenAdditionalDetails="selectedTokenDetails.tokenAdditionalDetails"
                                               :tokenModels="tokenModels"
                                               @updatePhysicalStatus="onTokenUpdatePhysicalStatus" />
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-overlay>
                </b-card>
            </b-col>

        </b-row>
        <b-row v-else-if="isLoadingPage">
            <b-col>
                <b-card class="mx-auto text-center">
                    <b-card-text class="mx-auto">Loading...</b-card-text>
                    <b-spinner class="mx-auto"></b-spinner>
                </b-card>
            </b-col>

        </b-row>
        <WizardModal :handler="tokenPhysicalStatusWizard"
                     @next="tokenPhysicalStatusWizard.onNext()"
                     @skip="tokenPhysicalStatusWizard.onSkip()"
                     @cancel="tokenPhysicalStatusWizard.onCancel()"
                     @previous="tokenPhysicalStatusWizard.onPrevious()"
                     @error="(eve) => tokenPhysicalStatusWizard.onError(evt)"
                     />

        <WizardModal :handler="assignTokenWizard"
                     @next="assignTokenWizard.onNext()"
                     @skip="assignTokenWizard.onSkip()"
                     @cancel="assignTokenWizard.onCancel()"
                     @previous="assignTokenWizard.onPrevious()"
                     @error="(evt) => assignTokenWizard.onError(evt)"
                     />

    </b-container>
</template>

<script>
    //import SearchBox from "./SearchBox.vue";
    import ManageClient from "../library/ManageClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    import Url from "../util/Url.js";
    import Credential from "../library/Credential.js";
    import Token from "../library/Token.js";
    import TokenFidoKey from "../library/TokenFidoKey.js";
    import UserDevice from "../library/UserDevice.js";
    import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import TokenView from "../components/TokenView.vue";
    import WizardModal from "../components/WizardModal.vue";
    import WizardHandler from "../library/WizardHandler.js";
    import iconYubiKey from "../assets/icons/yubikey.svg";
    import iconSmartCard from "../assets/icons/card.svg";
    import iconPhone from "../assets/icons/phone.svg";
    import iconCertificate from "../assets/icons/certificate.svg";
    import FlexibleTabs from "../components/FlexibleTabs.vue";

    export default {
        props: {
            msg: String,
            auth: Object
        },
        emits: ['needsAuth', 'error', 'interface'],
        data() {
            return {
                showConfirm: false,
                client: new ManageClient(),
                policy: null,

                tokenModels: null, //list of details about token models.

                isBusySearching: false, //just for the search results
                searchText: null,
                searchResults: null,
                searchResultFields: [
                    {
                        key: "name",
                        sortable: true
                    },
                    {
                        key: "email",
                        sortable: true
                    },
                    {
                        key: "phone",
                        sortable: true,
                        // Variant applies to the whole column, including the header and footer
                        //variant: 'danger'
                    }
                ],
                isLoadingCachedIdentity: false,
                isLoadingTokens: false,
                isLoadingDevices: false,
                isLoadingHistory: false,
                isLoadingActions: false,
                isLoadingFidoKeys: false,
                isLoadingCertificates: false,
                isLoadingOverview: false,
                isLoadingRemoteActions: false,
                isLoadingCredentials: false,
                tokenFields: [
                    { key: "family", sortable: true },
                    { key: "tokenID", sortable: true },
                ],
                historyFields: [
                    {
                        key: "eventTime",
                        tdClass: "no-wrap",
                        thClass: "no-wrap",
                        sortable: true,
                        sortDirection: "desc",
                        formatter: (value) => { //, key, item) => {
                            try {
                                return ExpUtil.formatEventTime(value, false, false);
                            }
                            catch (ex) {
                                DConsole.log(ex);
                                return value;
                            }
                        }
                    },
                    { key: "message" },
                    {
                        key: "eventType",
                        sortable: true,
                        sortDirection: "desc",
                        formatter: (value) => {
                            try {
                                return ExpUtil.convertCamelCaseToTitleCase(value);
                            }
                            catch (ex) {
                                DConsole.log(ex);
                                return value;
                            }
                        }
                    },

                ],

                historyPage: 1,
                historyRowsPerPage: 25,

                historyRowsPerRequest: 500,

                actionFields: [
                    { key: "label", label: "Action" },
                    { key: "scheduledTokenLabel", label: "Token" },
                    { key: "scheduledAuthorityName", label: "Authorization" },
                    { key: "manageDescription", label: "Description" },
                    { key: "cancel", label: "" }
                ],

                availableActionFields: [
                    { key: "label", label: "Action" },
                    { key: "manageDescription", label: "Description" },
                    { key: "authorize", label: "" }
                ],

                identityDetails: null,
                identityOverview: null,
                cardholderHistory: null,
                cardholderActions: null,
                credentials: null,
                tokens: null,
                devices: null,
                fidoKeys: null,
                certificates: null,
                remoteActions: null,

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone,
                    certificate: iconCertificate
                },

                isEditingFidoKeyExpiration: false,
                editedFidoKey: null,
                editedFidoKeyExpiration: null,
                isSavingFidoKey: false,

                isSavingToken: false,
                editedToken: null,
                editedTokenExpiration: null,
                isEditingTokenExpiration: false,
                selectedTokenDetails: null,

                tokenPhysicalStatusWizard: new WizardHandler({
                    doStep: this.doStepTokenPhysicalStatusWizard,
                    onFinish: this.onFinishTokenPhysicalStatusWizard
                }),
                assignTokenWizard: new WizardHandler({
                    doStep: this.doStepAssignTokenWizard,
                    onFinish: this.onFinishAssignTokenWizard
                }),


                isSavingCertificate: false,
                editedCertificate: null,

                isSavingAction: false,
                editedAction: null,

                isSavingDevice: false,
                editedDevice: null,
                editedDeviceExpiration: null,
                isEditingDeviceExpiration: false,

                revokeTokenDeletesCerts: false,
                revokeTokenRevokesCerts: false,
                revokeTokenConfirmMessage: null,
                revokeTokenConfirmTitle: null,
                revokeTokenConfirmShowRevokeCheckbox: false,
                revokeTokenConfirmShowDeleteCheckbox: false,

                dataStores: {},

                //This has some data that we'll eventually get from the server.
                //Just provides an overview of statuses and things.
                /*identityOverview: {
                    title: "Identity Summary",

                    items: [
                        {
                            title: "Active Directory",
                            details: "Active Directory account is valid.",
                            status: "valid",
                            data: {
                                "UPN": "mgarcia@dev.entrypoint.io",
                                "Verified": ExpUtil.formatEventTime(new Date()),
                            }
                        },
                        {
                            title: "PIV Derived",
                            details: "FIDO token was derived from PIV and is valid.",
                            status: "valid",
                            data: {
                                "PIV Certificate (Subject Name)": "Maria.Garcia",
                                "Expiration": ExpUtil.formatEventTime(new Date(2030, 1, 7)),
                                "Certificate Verified": ExpUtil.formatEventTime(new Date())
                            }
                        },
                        {
                            title: "Ping Enabled",
                            details: "Has a valid FIDO token that is activated for single sign on with PingFederate.",
                            status: "valid",
                            data: {

                            }
                        },
                        {
                            title: "Azure AD",
                            details: "Is valid for Azure AD login through PingFederate.",
                            status: "valid",
                        }
                    ]
                }*/
            }
        },
        components: {
            TokenView,
            WizardModal,
            FlexibleTabs
        },
        computed: {
            isIdentitySelected() {
                return this.identityDetails?.identity;
            },
            selectedIdentityName() {
                return this.identityDetails?.identity?.name || "";
            },
            historyReportRows() {
                return (this.cardholderHistory && this.cardholderHistory.events && this.cardholderHistory.events.length) || 0;
            },
            historyHasRows() {
                return this.historyReportRows > 0;
            },
            isTokenSelected() {
                return this.selectedTokenDetails?.token ? true : false;
            },

            isSavingCredential() {
                //True if we are saving a token, etc.
                return this.isSavingToken || this.isSavingDevice || this.isSavingFidokey || this.isSavingCertificate;
            },

            editedCredential() {
                //TO do: if a token or somethign else is being edited, find the credential that matches it and return that.
                return null;
            },

            isLoadingPage() {
                return !this.policy || this.isLoadingCachedIdentity;
            },

            breadcrumbs() {
                let output = [];
                output.push({
                    to: "", text: "Home"
                });

                if (this.isIdentitySelected) {
                    let name = this.selectedIdentityName;
                    if (typeof name === "string" && name !== "") {
                        output.push({
                            to: "id",
                            text: name

                        });
                    }
                }
                else if (this.isTokenSelected) {
                    let name = this.selectedTokenDetails?.token?.name;
                    if (typeof name === "string" && name !== "") {
                        output.push({
                            to: "t",
                            text: name

                        });
                    }
                }

                return output;
            },

            subpage() {
                return this.$route?.params?.subpage ?? "";
            },

            availableRemoteActionsForCardholder() {
                let actions = this.remoteActions;
                let authorizedActions = this.cardholderActions;
                let output = null;

                if (Array.isArray(actions) && actions.length > 0 && Array.isArray(authorizedActions)) {
                    output = actions.filter((x) => {
                        return !x.requiresSpecificTokenID
                            && !authorizedActions.some(y => y.name === x.name);
                    });
                }
                return output;
            },
            showDisableButton() {
                let showButtons = this.identityOverview?.showTargetAccountOptions ? true : false;
                let isIdentityEnabled = this.identityDetails?.identity?.isEnabled;
                return (showButtons && isIdentityEnabled)

            },
            showEnableButton() {
                let showButtons = this.identityOverview?.showTargetAccountOptions ? true : false;
                let isIdentityEnabled = this.identityDetails?.identity?.isEnabled;
                return (showButtons && isIdentityEnabled == false)

            },
            showProvisionButtons() {
                let showButtons = this.identityOverview?.showTargetAccountOptions ? true : false;
                return showButtons;
            },
            targetDetails() {
                return this.identityOverview?.targetDetails?.filter(x => x.showIdentityDetails) ?? [];
            },
            dataStoreDetails() {
                DConsole.log("####  dataStoreDetails()  ####");
                let output = [];
                if (Array.isArray(this.identityOverview?.dataStores)) {
                    for (let t of this.identityOverview.dataStores) {
                        //DConsole.log(t);
                        let storeDetails = this.dataStores ? (this.dataStores[t.dataStore.name]) : null;
                        if (!storeDetails) {
                            storeDetails = {
                                details: t,
                                isLoading: true
                            };
                        }
                        else if (!storeDetails.details) {
                            storeDetails.details = t;
                        }
                        else if (storeDetails?.details?.identity) {

                            //Now specially handle certain additional properties.
                            if (storeDetails.details.identity.additional) {
                                let ad = storeDetails?.details?.identity?.additional;
                                for (let key in ad) {
                                    let prop = ad[key];
                                    if (prop?.type === "Json" && typeof prop?.value === "string") {
                                        try {
                                            let parsed = JSON.parse(prop?.value);
                                            DConsole.log("#### parsed:");
                                            DConsole.log(parsed);

                                            if (Array.isArray(parsed)) {
                                                if (parsed.length == 0) {
                                                    prop.value = null;
                                                }
                                                else {
                                                    prop.value = parsed;
                                                }

                                            }
                                            else {
                                                prop.value = JSON.stringify(parsed, null, 4);
                                            }

                                        }
                                        catch (ex) {
                                            DConsole.log("JSON parse error: " + ex?.message);
                                        }

                                    }
                                }
                            }
                        }
                        if (storeDetails) {
                            output.push(storeDetails);
                        }
                    }
                }
                DConsole.log("#### processed dataStoreDetails:");
                DConsole.log(output);

                return output;
            }
        },
        async mounted() {
            try {
                DConsole.log("Home component mounted.");
                DConsole.log(this.auth);

                this.client.session = this.auth;

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                await this.client.startSession();

                this.policy = await this.client.getManagerPolicy();

                //We won't wait for this.
                this.getTokenModels();

                if (ManageClient.hasCachedIdentity()) {
                    let identityID = ManageClient.getCachedIdentityID();
                    //set this temporarily so it will show the busy spinner and the user can tell something is happening.
                    this.identityDetails = {
                        identity: { id: identityID, name: "Reloading last viewed identity..." }
                    };

                    this.isLoadingCachedIdentity = true;
                    let identity = await this.client.getCachedIdentity();
                    this.isLoadingCachedIdentity = false;

                    if (identity) {
                        //simulate clicked on a row in the search results.
                        this.onRowClicked(identity);
                    }
                }
            }
            catch (ex) {
                this.isLoadingCachedIdentity = false;
                this.handleError(ex);
            }

        },
        methods: {

            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    goHome: (optionalIdentity) => this.goHome(optionalIdentity)
                });
            },

            raiseNeedsAuthEvent() {
                this.$emit("needsAuth");

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (ex.status === 401) {
                    this.raiseNeedsAuthEvent();
                }
                else {
                    this.raiseErrorEvent(ex);
                }

            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            onShowConfirm() {
                this.showConfirm = true;
            },

            goHome(optionalIdentity) {
                this.clearSelectedIdentity();

                if (optionalIdentity) {
                    this.onRowClicked(optionalIdentity);
                }
            },

            clearSelectedIdentity() {
                ManageClient.clearCachedIdentityID();

                //TODO maybe: should we check to make sure we aren't in the middle of async operations? Not sure it's necessary.

                this.isLoadingTokens = false;
                this.isLoadingDevices = false;
                this.isLoadingHistory = false;
                this.isLoadingActions = false;
                this.isLoadingFidoKeys = false;
                this.isLoadingCertificates = false;
                this.isLoadingOverview = false;
                this.isLoadingRemoteActions = false;

                this.identityDetails = null;
                this.identityOverview = null;
                this.cardholderHistory = null;
                this.cardholderActions = null;
                this.tokens = null;
                this.devices = null;
                this.fidoKeys = null;
                this.certificates = null;
                this.remoteActions = null;

                this.isEditingFidoKeyExpiration = false;
                this.editedFidoKey = null;
                this.editedFidoKeyExpiration = null;
                this.isSavingFidoKey = false;

                this.isSavingToken = false;
                this.editedToken = null;
                this.editedTokenExpiration = null;
                this.isEditingTokenExpiration = false;
                this.selectedTokenDetails = null;

                this.isSavingCertificate = false;
                this.editedCertificate = null;

                this.isSavingAction = false;
                this.editedAction = null;

                this.isSavingDevice = false;
                this.editedDevice = null;
                this.editedDeviceExpiration = null;
                this.isEditingDeviceExpiration = false;

            },

            async onSearch(evt) {
                DConsole.log("search event fired.");

                evt.preventDefault();
                let searchString = evt.target.value;

                if (!searchString) return;

                let client = this.client || new ManageClient();

                try {
                    this.isBusySearching = true;

                    //await client.startSession(); //don't actually need to start a session, it will start one if we need.
                    let results = await client.listIdentities(searchString, false, true, true, 20, null);

                    this.clearError();

                    DConsole.log(results);

                    if (this.searchText === searchString) {
                        this.searchResults = results;
                    }
                    //else just ignore these results, because the search has either been canceled or moved on.
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isBusySearching = false;
            },

            onSearchEnterKey() {
                let results = this.searchResults?.identities;
                if (Array.isArray(results) && results.length > 0) {
                    this.onRowClicked(results[0]);
                }
            },

            onRowClicked(item) { //, index, evt) {
                DConsole.log("onRowClicked:");
                DConsole.log(item);

                if (!item?.id && ! item?.additional?.tokenid) return;  //just in case

                this.clearError();

                this.isBusySearching = false;
                this.searchResults = null;
                this.searchText = null;

                this.cardholderHistory = null;
                this.tokens = null;
                this.devices = null;
                this.fidoKeys = null;
                this.certificates = null;
                this.dataStores = {};


                if (item?.id) {
                    this.loadIdentityDetails(item);
                }
                else if (item?.additional?.tokenid?.value && item?.additional?.tokenfamily?.value) {
                    //no identity, but we have a Token.
                    this.loadTokenOnly(item.additional.tokenfamily?.value, item.additional.tokenid?.value);
                }

            },

            loadTokenOnly(tokenFamily, tokenID) {
                DConsole.log(`loadTokenOnly("${tokenFamily}", "${tokenID}")`);

                if (!tokenFamily || !tokenID) return;

                this.clearError();
                this.clearSelectedIdentity();

                try {
                    this.selectedTokenDetails = {
                        token: { family: tokenFamily, tokenID: tokenID }
                    };

                    //ManageClient.cacheToken(tokenFamily, tokenID);

                    let client = this.client || new ManageClient();

                    this.isLoadingTokens = true;

                    client.getTokenDetails(tokenFamily, tokenID, true, false).then(async (tokenDetails) => {
                        if (this.selectedTokenDetails?.token?.tokenID && tokenDetails?.token?.tokenID === this.selectedTokenDetails?.token?.tokenID) {
                            //Wrap it in the wrapper object so we have more properties.
                            let token = new Token(tokenDetails.token, this.tokenModels);
                            tokenDetails.token = token;

                            try {
                                let chuid = await client.getPIVChuidForToken(token.family, token.tokenID);
                                token.pivChuid = chuid;
                            }
                            catch (ex) {
                                DConsole.log(ex);
                            }
                            this.selectedTokenDetails = tokenDetails;
                            this.isLoadingTokens = false;
                        }
                        else {
                            DConsole.log("Ignoring return value because selected token has changed.");
                        }

                    }).catch(ex => {
                        DConsole.log(ex);
                        this.isLoadingTokens = false;
                        this.handleError(ex);

                    });

                }
                catch (ex) {
                    this.handleError(ex);
                }


            },

            loadIdentityDetails(identity) {
                if (!identity?.id) return;  //just end it

                this.clearError();

                try {
                    this.identityDetails = { identity: identity };

                    ManageClient.cacheIdentityID(identity.id);

                    //Get all the details. These all return before the actually
                    //get the data, but we want them running at the same time.
                    this.getOverviewForCardholder(identity);
                    this.getCredentialsForCardholder(identity);
                    this.getTokensForCardholder(identity);
                    this.getDevicesForCardholder(identity);
                    this.getHistoryForCardholder(identity);
                    this.getFidoKeysForCardholder(identity);
                    this.getCertificatesForCardholder(identity);
                    this.getActionsForCardholder(identity);
                    this.getAvailableRemoteActions();

                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            getTokenModels() {
                //We'll always just get these in the background so nothing has to wait for it.
                this.client.getTokenModels().then((x) => {
                    this.tokenModels = x;

                })
                .catch((ex) => {
                    DConsole.log(`Error token models: ${ex.message}`);
                });
            },

            refreshTokenRelatedDetails() {
                if (this.identityDetails?.identity) {
                    this.getCredentialsForCardholder();
                    this.getTokensForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                else if (this.selectedTokenDetails?.token?.tokenID) {
                    this.loadTokenOnly(this.selectedTokenDetails.token.family, this.selectedTokenDetails.token.tokenID);
                }
            },

            getOverviewForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingOverview = true;

                client.getIdentityOverview(identity.id).then(overview => {

                    if (identity.id === this.identityDetails?.identity?.id) {
                        DConsole.log("*******Identity Overview*********");
                        DConsole.log(overview);

                        this.identityOverview = overview;
                        this.isLoadingOverview = false;

                        //this.$forceUpdate();

                        if (Array.isArray(overview.dataStores) && overview.dataStores.length > 0) {
                            for (let t of overview.dataStores) {
                                if (t?.dataStore?.name) {
                                    this.getIdentityFromAssociatedDataStore(identity, t.dataStore.name).then(() => { }).catch(() => { });
                                }
                            }
                        }
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    DConsole.log(ex);
                    this.isLoadingOverview = false;
                    this.handleError(ex);

                });
            },

            updateOverviewForCardholder(identity) {
                //Eventually, this function should just trigger dynamic updates of the dashboard items.
                //For now, we are just getting the overview again.
                this.getOverviewForCardholder(identity);
            },

            getTokensForCardholder(identity) {

                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingTokens = true;

                client.getIdentityDetails(identity.id).then(async (identityDetails) => {

                    if (identity.id === this.identityDetails?.identity?.id) {

                        let tokens = [];
                        if (Array.isArray(identityDetails.tokenDetails)) {
                            for (const tokenDetails of identityDetails.tokenDetails) {
                                let t = ExpUtil.getCaseInsensitiveProxy(new Token(tokenDetails.token, this.tokenModels));
                                t.additionalDetails = tokenDetails.tokenAdditionalDetails;
                                tokens.push(t);
                                DConsole.log("token:");
                                DConsole.log(t);
                            }
                        }
                        else {
                            tokens = null;
                        }
                        this.identityDetails = identityDetails;
                        this.tokens = tokens;

                        for (let token of tokens) {
                            try {
                                let chuid = await client.getPIVChuidForToken(token.family, token.tokenID);
                                token.pivChuid = chuid;
                            }
                            catch (ex) {
                                DConsole.log(ex);
                            }
                        }
                        this.isLoadingTokens = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    DConsole.log(ex);
                    this.isLoadingTokens = false;
                    this.handleError(ex);

                });
            },

            getCredentialsForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingCredentials = true;

                client.getCredentialsForCardholder(identity.id).then(creds => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        let output = [];
                        for (const cred of creds) {
                            output.push(new Credential(cred, this.tokenModels));
                        }
                        this.credentials = output;
                        this.isLoadingCredentials = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    this.isLoadingCredentials = false;
                    this.handleError(ex);
                });

            },



            getDevicesForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingDevices = true;

                client.getDevicesForIdentity(identity.id).then(devicesFromServer => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        let devices = [];
                        for (const d of devicesFromServer) {
                            devices.push(new UserDevice(d));
                        }
                        this.devices = devices;
                        this.isLoadingDevices = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    this.isLoadingDevices = false;
                    this.handleError(ex);
                });

            },

            getFidoKeysForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingFidoKeys = true;

                client.getFidoKeysForCardholder(identity.id).then(keys => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        let fidoKeys = [];
                        for (const key of keys) {
                            fidoKeys.push(new TokenFidoKey(key));
                        }
                        this.fidoKeys = fidoKeys;
                        this.isLoadingFidoKeys = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    this.isLoadingFidoKeys = false;
                    this.handleError(ex);
                });

            },

            getCertificatesForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingCertificates = true;

                client.getCertificatesForCardholder(identity.id, true).then(input => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        let certs = [];
                        for (const cert of input) {
                            certs.push(new CertificateSearchResult(cert));
                        }
                        this.certificates = certs;
                        this.isLoadingCertificates = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }

                }).catch(ex => {
                    this.isLoadingCertificates = false;
                    this.handleError(ex);

                });


            },

            getHistoryForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingHistory = true;

                client.getHistoryForCardholder(identity.id, this.historyRowsPerRequest).then(history => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        this.cardholderHistory = history;
                        this.isLoadingHistory = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }
                }).catch(ex => {
                    this.isLoadingHistory = false;
                    this.handleError(ex);

                });

            },

            getActionsForCardholder(identity) {
                identity = identity || this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                this.isLoadingActions = true;

                client.getAuthorizedActionsForCardholder(identity.id).then(actions => {
                    if (identity.id === this.identityDetails?.identity?.id) {
                        this.cardholderActions = actions;
                        this.isLoadingActions = false;
                    }
                    else {
                        DConsole.log("Ignoring return value because selected identity has changed.");
                    }
                }).catch(ex => {
                    this.isLoadingActions = false;
                    this.handleError(ex);


                });

            },

            getAvailableRemoteActions() {
                let client = this.client || new ManageClient();

                this.isLoadingRemoteActions = true;

                client.getAvailableRemoteActions().then(actions => {
                    this.remoteActions = actions;
                    this.isLoadingRemoteActions = false;
                }).catch(ex => {
                    this.isLoadingRemoteActions = false;
                    this.handleError(ex);
                });

            },

            async getIdentityFromAssociatedDataStore(identity, dataStore) {
                DConsole.log("****getIdentityFromAssociatedDataStore****");
                identity = this.identityDetails?.identity;
                if (!identity?.id) return;

                let client = this.client || new ManageClient();

                if (!this.dataStores) {
                    this.dataStores = {};
                }
                if (!this.dataStores[dataStore]) {
                    this.dataStores[dataStore] = {};
                }
                this.dataStores[dataStore].isLoading = true;

                try {
                    let response = await client.getIdentityFromAssociatedDataStore(identity.id, dataStore);
                    this.dataStores[dataStore].details = response;
                    this.dataStores[dataStore].isLoading = false;
                }
                catch (ex) {
                    this.dataStores[dataStore].error = ex.message;
                    this.dataStores[dataStore].errorDetails = ex.error;
                    this.dataStores[dataStore].isLoading = false;
                }

                //Force a re-render.
                //this.$forceUpdate();
            },

            saveCertificate(cert, filename) {
                try {
                    this.clearError();

                    let dataToBlob = null;
                    if (cert.rawData) {
                        //This means it's an X509Certificate (from a library we are using). "rawData" is an ArrayBuffer.
                        dataToBlob = new Uint8Array(cert.rawData, 0, cert.rawData.byteLength);
                    }
                    else if (typeof cert === "string") {
                        //base64-encoded string. Decode to an ASCII string of bytes.
                        dataToBlob = atob(cert);
                    }

                    let blob = new Blob([dataToBlob], { type: "application/x-x509-user-cert" });
                    let url = URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename || "downloaded.cer";

                    //make sure we can remove it before we add it to the DOM, it might be big. IE won't let you 
                    //call remove, for example.
                    if (typeof a.remove === "function") {
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    }

                    if (window && window.navigator && typeof window.navigator.msSaveBlob === "function") {
                        window.navigator.msSaveBlob(blob, a.download);
                    }
                    else {
                        a.click();
                    }

                    //IE doesn't seem to have the "remove" function.
                    if (typeof a.remove === "function") {
                        a.remove();
                    }
                    URL.revokeObjectURL(url);
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            makePhotoDataUrl(base64Image) {
                return Url.makeDataUrl(base64Image, "image/jpeg");
            },

            async onRevokeFidoKey(key) {
                await this.onRevokeOrUnrevokeFidoKey(key, true);
            },

            async onUnrevokeFidoKey(key) {
                await this.onRevokeOrUnrevokeFidoKey(key, false);
            },

            async onRevokeOrUnrevokeFidoKey(key, revoke) {
                try {
                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`${revoke ? "Revoke" : "Unrevoke"} FIDO key "${key.shortName}" for ${this.selectedIdentityName}?`, {
                        title: `${revoke ? "Revoke" : "Unrevoke"} FIDO Key`,
                        centered: true
                    });

                    if (!confirmed) return;

                    this.isSavingFidoKey = true;
                    this.editedFidoKey = key;

                    if (revoke) {
                        await this.client.revokeFidoKey(key.credentialID, null, false);
                    }
                    else {
                        await this.client.unrevokeFidoKey(key.credentialID, null);
                    }

                    this.isSavingFidoKey = false;
                    this.editedFidoKey = null;

                    this.getCredentialsForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            async onDeleteFidoKey(key) {
                try {
                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`Delete FIDO key "${key.shortName}" for ${this.selectedIdentityName}?`, {
                        title: "Delete FIDO Key",
                        centered: true
                    });

                    if (!confirmed) return;

                    this.isSavingFidoKey = true;
                    this.editedFidoKey = key;

                    await this.client.revokeFidoKey(key.credentialID, null, true);

                    if (this.fidoKeys) {
                        //Remove this key from our list immediately for user responsiveness.
                        let index = this.fidoKeys.indexOf(key);
                        if (index >= 0) {
                            this.fidoKeys.splice(index, 1);
                        }
                    }

                    this.isSavingFidoKey = false;
                    this.editedFidoKey = null;

                    this.getCredentialsForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);

                }
            },

            async onDeleteCertificate(cert) {
                try {
                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`Delete certificate "${cert.name}" for ${this.selectedIdentityName}?`, {
                        title: "Delete Certificate",
                        centered: true
                    });

                    if (!confirmed) return;

                    let identity = this.identityDetails?.identity;
                    if (!identity?.id) return;

                    this.isSavingCertificate = true;
                    this.editedCertificate = cert;

                    await this.client.deleteCertificateAssociations(cert.thumbprint, identity.id);

                    if (this.certificates) {
                        //Remove this cert from our list immediately for user responsiveness.
                        let index = this.certificates.indexOf(cert);
                        if (index >= 0) {
                            this.certificates.splice(index, 1);
                        }
                    }

                    this.isSavingCertificate = false;
                    this.editedCertificate = null;

                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);

                    this.isSavingCertificate = false;
                    this.editedCertificate = null;

                }
            },

            async onRevokeCertificate(cert) {
                try {
                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`Revoke certificate "${cert.name}" for ${this.selectedIdentityName}?`, {
                        title: "Revoke Certificate",
                        centered: true
                    });

                    if (!confirmed) return;

                    let identity = this.identityDetails?.identity;
                    if (!identity?.id) return;

                    this.isSavingCertificate = true;
                    this.editedCertificate = cert;

                    let reason = null;
                    await this.client.revokeCertificate(cert.thumbprint, identity.id, reason);

                    this.isSavingCertificate = false;
                    this.editedCertificate = null;

                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);

                    this.isSavingCertificate = false;
                    this.editedCertificate = null;

                }
            },

            async onRevokeToken(token, alsoDelete) {
                try {
                    alsoDelete = alsoDelete ? true : false;

                    this.clearError();

                    this.revokeTokenConfirmTitle = `${alsoDelete ? "Delete" : "Revoke"} Token`;
                    this.revokeTokenConfirmMessage = `${alsoDelete ? "Delete" : "Revoke"} token "${token.shortName}" for ${this.selectedIdentityName}?`;
                    this.revokeTokenConfirmShowRevokeCheckbox = true;
                    this.revokeTokenConfirmShowDeleteCheckbox = alsoDelete;
                    this.revokeTokenRevokesCerts = !alsoDelete && this.identityOverview?.revokeTokenRevokesCerts ? true : false;
                    this.revokeTokenDeletesCerts = alsoDelete;
                    this.revokeTokenDeleteToken = alsoDelete;
                    this.revokeTokenToken = token;

                    this.$bvModal.show("confirmRevokeToken");
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            async onRevokeTokenConfirmed() {
                try {
                    this.$bvModal.hide("confirmRevokeToken");

                    let token = this.revokeTokenToken;

                    this.isSavingToken = true;
                    this.editedToken = token;

                    let revokeCerts = this.revokeTokenRevokesCerts;
                    let deleteCerts = this.revokeTokenDeletesCerts;

                    await this.client.revokeToken(token.family, token.tokenID, null, this.revokeTokenDeleteToken, revokeCerts, deleteCerts);

                    this.getCredentialsForCardholder();
                    this.getTokensForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.getActionsForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;
                this.revokeTokenToken = null;

            },

            async onRevokeTokenCanceled() {
                try {
                    this.$bvModal.hide("confirmRevokeToken");

                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;
                this.revokeTokenToken = null;

            },

            async onRevokeDevice(device, alsoDelete) {
                try {
                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`${alsoDelete ? "Delete" : "Revoke"} device "${device.shortName}" for ${this.selectedIdentityName}?`, {
                        title: `${alsoDelete ? "Delete" : "Revoke"} Device`,
                        centered: true
                    });

                    if (!confirmed) return;

                    this.isSavingDevice = true;
                    this.editedDevice = device;

                    await this.client.revokeDevice(device.id, null, alsoDelete ? true : false);

                    this.getCredentialsForCardholder();
                    this.getDevicesForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.getActionsForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingDevice = false;
                this.editedDevice = null;

            },

            async onDisableAccount(token) {
                await this.enableOrDisableAccount(false, token);
            },

            async onEnableAccount(token) {
                await this.enableOrDisableAccount(true, token);
            },

            async enableOrDisableAccount(enable, token) {

                try {
                    let identity = this.identityDetails.identity;
                    if (!identity) return;


                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`${enable ? "Enable" : "Disable"} account for ${this.selectedIdentityName}?`, {
                        title: `${enable ? "Enable" : "Disable"} Account`,
                        centered: true
                    });

                    if (!confirmed) return;

                    this.isSavingToken = true;
                    this.editedToken = token;

                    if (enable) {
                        await this.client.enableIdentity(identity.id);
                    }
                    else {
                        await this.client.disableIdentity(identity.id);
                    }

                    this.getCredentialsForCardholder();
                    this.getTokensForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.getActionsForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;


            },

            async onDeprovisionAccount(token, targetDetail) {
                await this.provisionOrDeprovisionAccount(false, token, targetDetail);
            },

            async onProvisionAccount(token, targetDetail) {
                await this.provisionOrDeprovisionAccount(true, token, targetDetail);
            },

            async provisionOrDeprovisionAccount(provision, token, targetDetail) {

                try {
                    let identity = this.identityDetails.identity;
                    if (!identity) return;


                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`${provision ? "Provision" : "Deprovision"} account for ${this.selectedIdentityName} in ${targetDetail.label}?`, {
                        title: `${provision ? "Provision" : "Deprovision"} Account`,
                        centered: true
                    });

                    if (!confirmed) return;

                    this.isSavingToken = true;
                    this.editedToken = token;

                    if (provision) {
                        await this.client.provisionIdentity(identity.id, targetDetail.name, token.family, token.tokenID);
                    }
                    else {
                        await this.client.deprovisionIdentity(identity.id, targetDetail.name, token.family, token.tokenID);
                    }

                    this.getCredentialsForCardholder();
                    this.getTokensForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getCertificatesForCardholder();
                    this.getHistoryForCardholder();
                    this.getActionsForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;

            },

            async onGetPUK(token) {

                try {
                    let identity = this.identityDetails.identity;
                    if (!identity) return;

                    if (!token || ! token.tokenID) return;

                    this.clearError();

                    this.isSavingToken = true;
                    this.editedToken = token;

                    let puk = await this.client.getTokenPUK(token.family, token.tokenID);

                    if (puk === null || puk === "" || puk === undefined) throw new Error("PIN Unlock Key is not available or is not suitable for user entry.");

                    token.puk = puk;

                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;
            },

            onHidePUK(token) {

                try {
                    let identity = this.identityDetails.identity;
                    if (!identity) return;

                    if (!token || !token.tokenID) return;

                    this.clearError();
                    this.isSavingToken = true;
                    this.editedToken = token;


                    DConsole.log("Setting PUK to null.");

                    delete token.puk;

                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isSavingToken = false;
                this.editedToken = null;

            },

            async onTokenUpdatePhysicalStatus(token) {
                if (!token) return;

                this.clearError();
                this.editedToken = token;

                try {
                    await this.tokenPhysicalStatusWizard.start();
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            async doStepTokenPhysicalStatusWizard(stepInput) {
                if (!this.editedToken) throw new Error("Token is not being edited.");

                return await this.client.doEditTokenStep(this.identityDetails?.identity?.id, this.editedToken.family, this.editedToken.tokenID, stepInput);
            },

            async onFinishTokenPhysicalStatusWizard(ok) {
                this.editedToken = null;

                if (ok) {
                    this.refreshTokenRelatedDetails();
                }
            },

            async onAssignToken(token) {
                if (!token) return;
                if (!this.identityDetails?.identity?.id) return;


                this.clearError();
                this.editedToken = token;

                try {
                    await this.assignTokenWizard.start();
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            async doStepAssignTokenWizard(stepInput) {
                if (!this.editedToken) throw new Error("Token is not being edited.");
                if (!this.identityDetails?.identity?.id) throw new Error("No identity.");

                return await this.client.doAssignTokenStep(
                    this.identityDetails.identity.id,
                    this.editedToken.family, this.editedToken.tokenID, stepInput);
            },

            async onFinishAssignTokenWizard(ok) {
                this.editedToken = null;

                if (ok) {
                    this.refreshTokenRelatedDetails();
                }
            },



            onTokenEditExpiration(token) {
                if (!token) return;
                this.clearError();

                this.isEditingTokenExpiration = true;
                this.editedToken = token;
                this.editedTokenExpiration = token.expirationTime
            },

            async onSaveTokenExpiration() {
                if (!this.editedToken) return;

                try {
                    this.clearError();

                    this.editedToken.expirationTime = this.editedTokenExpiration;

                    this.isSavingToken = true;

                    await this.client.saveTokenExpiration(this.editedToken.family, this.editedToken.tokenID, this.editedTokenExpiration);

                    this.isSavingToken = false;
                    this.editedToken = null;
                    this.editedTokenExpiration = null;

                    this.refreshTokenRelatedDetails();
                }
                catch (ex) {
                    this.isSavingToken = false;
                    this.handleError(ex);
                }


            },


            async onSaveFidoKeyExpiration() {
                if (!this.editedFidoKey) return;

                try {
                    this.clearError();

                    this.editedFidoKey.expirationTime = this.editedFidoKeyExpiration;

                    this.isSavingFidoKey = true;

                    //This is just a placeholder to stall so I can see how the GUI works.
                    await this.client.saveFidoKeyExpiration(this.editedFidoKey.credentialID, this.editedFidoKeyExpiration);

                    this.isSavingFidoKey = false;
                    this.editedFidoKey = null;
                    this.editedFidoKeyExpiration = null;

                    this.getFidoKeysForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }
            },

            async onSaveDeviceExpiration() {
                if (!this.editedDevice) return;

                try {
                    this.clearError();

                    this.editedDevice.expirationTime = this.editedDeviceExpiration;

                    this.isSavingDevice = true;

                    await this.client.saveDeviceExpiration(this.editedDevice.id, this.editedDeviceExpiration);

                    this.isSavingDevice = false;
                    this.editedDevice = null;
                    this.editedDeviceExpiration = null;

                    this.getDevicesForCardholder();
                    this.getFidoKeysForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);
                }

            },


            async onAuthorizeActionForCardholder(action) {
                try {
                    if (!action || !action?.name) return;

                    let identity = this.identityDetails?.identity;
                    if (!identity?.id) return;


                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`Authorize action "${action.label}" for ${this.selectedIdentityName}?`, {
                        title: "Authorize Self-Service Action",
                        centered: true
                    });

                    if (!confirmed) return;


                    this.isSavingAction = true;
                    this.editedAction = action;

                    await this.client.authorizeSelfServiceActionForCardholder(action.name, identity.id);

                    this.isSavingAction = false;
                    this.editedAction = null;

                    this.getActionsForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);

                    this.isSavingAction = false;
                    this.editedAction = null;

                }
            },

            async onCancelAction(action) {
                try {
                    if (!action || !action?.name) return;

                    let identity = this.identityDetails?.identity;
                    if (!identity?.id) return;

                    this.clearError();

                    let confirmed = await this.$bvModal.msgBoxConfirm(`Cancel action "${action.label}" for ${this.selectedIdentityName}?`, {
                        title: "Cancel Self-Service Action",
                        centered: true
                    });

                    if (!confirmed) return;


                    this.isSavingAction = true;
                    this.editedAction = action;

                    await this.client.deleteSelfServiceAction(action.scheduledActionID);

                    this.isSavingAction = false;
                    this.editedAction = null;

                    this.getActionsForCardholder();
                    this.getHistoryForCardholder();
                    this.updateOverviewForCardholder();
                }
                catch (ex) {
                    this.handleError(ex);

                    this.isSavingAction = false;
                    this.editedAction = null;

                }
            },


            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },

            getIconForToken(token) {
                let out = null;
                if (!token) {
                    out = null;
                }
                else if (token.badgeImageFront) {
                    out = Url.makePhotoDataUrl(token.badgeImageFront);
                }
                else if (token.photo) {
                    out = Url.makePhotoDataUrl(token.photo);
                }
                else if (token.family.startsWith('YubiKey')) {
                    out = this.icons.yubiKey;
                }
                else if (token.family.startsWith('IDOne')) {
                    out = this.icons.smartCard;
                }
                else if (token.badgeImageBack) {
                    out = Url.makePhotoDataUrl(token.badgeImageBack);
                }
                return out;
            },

            getIconForFidoKey(key) {
                let out = null;
                if (!key) {
                    out = null;
                }
                else if (key.token?.photo) {
                    out = Url.makePhotoDataUrl(key.token.photo);
                }
                else if (key.token?.family?.startsWith('YubiKey') || key.attestationCertName?.includes('Yubi')) {
                    out = this.icons.yubiKey;
                }
                else if (key.token?.family?.startsWith('IDOne')) {
                    out = this.icons.smartCard;
                }
                else if (key.userDeviceID) {
                    out = this.icons.phone;
                }

                return out;
            },

            getIconForCredential(cred) {
                let out = null;
                if (!cred) {
                    out = null;
                }
                else if (cred.token?.photo) {
                    out = Url.makePhotoDataUrl(cred.token.photo);
                }
                else if (cred.token?.family?.startsWith('YubiKey') || (Array.isArray(cred.fidoKeys) && cred.fidoKeys.length > 0 && cred.fidoKeys[0]?.attestationCertName?.includes('Yubi'))) {
                    out = this.icons.yubiKey;
                }
                else if (cred.token?.family?.startsWith('IDOne')) {
                    out = this.icons.smartCard;
                }
                else if (cred.device) {
                    out = this.icons.phone;
                }

                return out;
            }

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

    .tabs .card-body {
        padding-top: 10px;
    }

    .token-view .card-body {
        padding-top: 0px;
    }

    .nav-item .row {
        flex-wrap: nowrap;
    }

    dl.style-as-rows {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    dl.style-as-rows dt {
        display: inline-block;
        padding-right: 0.5em;
    }

    dl.style-as-rows dd {
        display: inline-block;
    }

    dl.style-as-rows dd::after {
    }

    h6:first-of-type {
        font-weight: bold;
    }

    .overlay-icon {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media screen and (max-width: 768px) {
        .jumbotron h1 {
            font-size: 48px; /*normally 72px I think*/
        }

        /*This forces the tabs onto one row and makes them scrollable, with a hidden scrollbar.
            Has some usability issues though, especially on desktop, since you can't necessarily
            tell that there are more options.
        */
        /*.tabs .card-header .nav {  
            overflow-y: hidden;
            overflow-x: auto;
            flex-wrap: nowrap;
            scrollbar-width: none;
        }
            */

        .nav-tabs {
            flex-wrap: nowrap;
            white-space: nowrap;
        }

        nav.navbar {
            align-items: start;
        }

        .ep-card-icon {
            display: none;
        }

    }

    @media screen and (max-width: 576px) {
        div.jumbotron {
            padding: 16px 8px; /* normally 64px 32px I think*/
        }

        .jumbotron h1 {
            font-size: 36px; /*normally 72px I think*/
        }

        .jumbotron p.lead {
            font-size: 16px; /* normally 20px*/
        }

        div.card-body {
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        h4.card-title {
            font-size: 20px
        }

        button.btn-lg {
            font-size: 1rem;
        }

    }




</style>

