<template>
    <b-container>
        <b-row align-v="start">
            <b-col align-v="start">

                <b-card>

                    <b-card-title v-if="step && step.label">{{step.label}}</b-card-title>
                    <b-card-text v-if="step && step.description">{{step.description}}</b-card-text>

                    <slot>
                        <FormView v-if="step && step.form" @submit="onNext" :form="step.form" :model="dataModel" />

                        <div v-if="step && step.promptToRegisterDevice">
                            <b-card>
                                <!--Regarding error correcton level, from https://blog.acolad.com/qr-codes-what-you-didnt-know
                        Each QR code has one of four levels (of error correction): L, M, Q, H.
                        The level determines the percentage of the total QR code that is allowed to be
                        dirty or damaged without being unable to read.
                        Level L can be dirty/damaged for up to 7%, level M 15%, level Q 25%, level H 30%.
                        The higher the percentage, the larger the dimensions of the QR code.
                        Also, annoyingly the default for this library styles the QR code in an atypical way,
                        so you have to set some other settings to get it to look like a normal bland QR code.
                        -->
                                <QRCodeVue3 :value="stepRegisterQRCodeURL" :width="300" :height="300"
                                            :qrOptions="{ errorCorrectionLevel: 'H' }"
                                            :dotsOptions="{type: 'square', color: '#000000'}"
                                            :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                                            :cornersDotOptions="{ type: undefined, color: '#000000' }" />
                                <!--<div>{{stepRegisterQRCodeURL}}</div>-->
                                <b-link :href="stepRegisterQRCodeURL">{{stepRegisterDisplayURL}}</b-link>
                            </b-card>
                        </div>

                        <div v-if="step && step.encoderDownloadLink && step.encoderDownloadLink.url">
                            <p v-if="step && step.encoderDownloadLink.text">{{step.encoderDownloadLink.text}}</p>
                            <b-link :href="step.encoderDownloadLink.url">{{step.encoderDownloadLink.title}}</b-link>
                        </div>


                    </slot>

                    <b-button-group>
                        <b-button v-if="showPreviousComputed" @click="onPrevious" class="mr-2">{{(step && step.previousButtonLabel) || "Previous"}}</b-button>
                        <b-button v-if="showSkipComputed" @click="onSkip" class="mr-2">{{(step && step.skipButtonLabel) || "Skip"}}</b-button>
                        <b-button v-if="showCancelComputed" @click="onCancel" class="mr-2">{{(step && step.cancelButtonLabel) || "Cancel"}}</b-button>
                        <b-button v-if="showNextComputed" @click="onNext" class="mr-2">{{(step && step.nextButtonLabel) || (step && step.isFinished ? "Finish" : "Next")}}</b-button>
                    </b-button-group>

                </b-card>
                <b-overlay :show="isBusy" no-wrap class="w-100">
                    <template #overlay>
                        <b-card class="text-center">
                            <b-card-title class="mx-auto">{{busyMessage || "Please wait..."}}</b-card-title>
                            <b-card-text v-if="busyDetails" class="mx-auto">{{busyDetails}}</b-card-text>
                            <b-spinner class="mx-auto"></b-spinner>
                            <div v-if="step && step.encoderDownloadLink && step.encoderDownloadLink.url" class="w-100">
                                <b-card-text v-if="step && step.encoderDownloadLink.text">{{step.encoderDownloadLink.text}}</b-card-text>
                                <b-card-sub-title><b-link :href="step.encoderDownloadLink.url">{{step.encoderDownloadLink.title}}</b-link></b-card-sub-title>
                            </div>
                        </b-card>
                    </template>
                </b-overlay>


            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    import DConsole from "../util/DConsole.js";
    //import ExpUtil from "../util/ExpUtil.js";
    import FormView from "../components/FormView.vue";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubi-600x600.png";
    import iconSmartCard from "../assets/icons/Card.png";
    import QRCodeVue3 from "qrcode-vue3";

    export default {
        props: {
            step: Object,
            showNextByDefault: {
                type: Boolean,
                default: true
            },
            showCancelByDefault: {
                type: Boolean,
                default: true
            },
            showPreviousByDefault: {
                type: Boolean,
                default: false
            },
            showSkipByDefault: {
                type: Boolean,
                default: false
            },
            showNext: {
                type: Boolean,
                default: undefined
            },
            showCancel: {
                type: Boolean,
                default: undefined
            },
            showPrevious: {
                type: Boolean,
                default: undefined
            },
            showSkip: {
                type: Boolean,
                default: undefined
            },

            isBusy: Boolean,
            busyMessage: String,
            busyDetails: String

        },
        emits: ['error', 'next', 'previous', 'skip', 'cancel'],
        data() {
            return {
                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard
                },
            }
        },
        components: {
            FormView,
            QRCodeVue3
        },
        computed: {
            dataModel() {
                return this.step?.form?.dataModel ?? {};
            },

            showNextComputed() {
                return this.getPrioritizedPropValue(this.showNext, this.step?.showNextButton, this.showNextByDefault);
            },
            showCancelComputed() {
                return this.getPrioritizedPropValue(this.showCancel, this.step?.showCancelButton, this.showCancelByDefault && ! this.step?.isFinished);
            },
            showPreviousComputed() {
                return this.getPrioritizedPropValue(this.showPrevious, this.step?.showPreviousButton, this.showPreviousByDefault);
            },
            showSkipComputed() {
                return this.getPrioritizedPropValue(this.showSkip, this.step?.allowSkip, this.showSkipByDefault);
            },

            stepRegisterQRCodeURL() {
                let url = this.stepRegisterDisplayURL;
                let qr = null;
                if (url) {
                    let query = {
                        url: url
                    };

                    qr = "entrypointma://register?" + new URLSearchParams(query).toString();
                }
                return qr;
            },

            stepRegisterDisplayURL() {
                return (this.step && this.step.promptToRegisterDeviceURL) || this.client.getBaseUrl();
            }

        },
        async mounted() {

            try {
                let nextStep = this.step;
                if (nextStep?.promptToRegisterDevice && !nextStep?.form && !nextStep?.fidoCreationOptions) {
                    //All we're doing is showing the register device prompt, so just go ahead and wait.
                    this.onNext();
                }
            }
            catch (ex) {
                this.handleError(ex);
            }
        },
        methods: {

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            emitNext() {
                this.$emit("next");
            },

            emitPrevious() {
                this.$emit("previous");
            },

            emitSkip() {
                this.$emit("skip");
            },

            emitCancel() {
                this.$emit("cancel");
            },

            getPrioritizedPropValue(a, b, c) {
                let output;
                if (a !== undefined && a !== null) {
                    output = a;
                }
                else if (b !== undefined && b !== null) {
                    output = b;
                }
                else {
                    output = c;
                }
                return output;
            },

            handleError(ex) {
                DConsole.log(ex);
                if (typeof this.client?.handleError === "function") {
                    this.client.handleError(ex);
                }
                else {
                    if (ex.status === 401) {
                        this.raiseNeedsAuthEvent();
                    }
                    else {
                        this.raiseErrorEvent(ex);
                    }
                }
            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            async onPrevious() {
                this.emitPrevious();
            },

            async onNext() {
                this.emitNext();
            },

            async onCancel() {
                this.emitCancel();
            },

            async onSkip() {
                this.emitSkip();
            },


        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 
</style>

