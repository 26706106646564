import ExpUtil from "../util/ExpUtil.js";
import Token from "../library/Token.js";
import CertificateSearchResult from "../library/CertificateSearchResult";
import DConsole from "../util/DConsole.js";

import * as x509 from "@peculiar/x509";
//import X509Certificate from "@peculiar/x509";

class TokenFidoKey {
    constructor(options) {
        Object.assign(this, options);

        if (this.tokenFamily && this.tokenID) {
            this.token = new Token({ family: this.tokenFamily, tokenID: this.tokenID });
        }
        else {
            this.token = null;
        }
    }

    get shortName() {
        return this.token ? this.token.shortName : (this.relyingPartyName || this.relyingPartyID || "FIDO2 Key");
    }

    get creationTimeString() {
        if (this.creationTime) {
            return ExpUtil.formatEventTime(this.creationTime);
        }
        else {
            return "";
        }
    }

    get expirationTimeString() {
        if (this.expirationTime) {
            return ExpUtil.formatEventTime(this.expirationTime);
        }
        else {
            return "";
        }
    }

    get revocationTimeString() {
        if (this.revocationTime) {
            return ExpUtil.formatEventTime(this.revocationTime);
        }
        else {
            return "";
        }
    }

    get attestationCertX509() {
        let cert = this._attestationCertX509;

        if (! cert && this.attestationCert) {
            try {
                cert = new x509.X509Certificate(this.attestationCert);
                this._attestationCertX509 = cert;
                DConsole.log(cert);
            }
            catch (ex) {
                DConsole.log(ex);
            }
        }
        return cert;
    }

    _attestationCertX509 = null;

    get attestationCertName() {
        let cert = this.attestationCertX509;
        return cert && CertificateSearchResult.getLastPartOfDistinguishedName(cert.subjectName);
    }

    get isValid() {
        let now = new Date();
        let exp = this.expirationTime ? new Date(this.expirationTime) : null;

        return !this.isRevoked && (!exp || exp > now);
    }

    //True if these refer to the same fido key (by ID)
    equals(other) {
        let output = false;
        if (other && other instanceof TokenFidoKey) {
            output = this.fidoKeyID === other.fidoKeyID;
        }

        return output ? true : false;
    }

}

export default TokenFidoKey;