<template>
    <b-container>
        <b-row align-v="start">
            <b-col align-v="start">
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy" rounded="sm" opacity="0.6">

                            <b-jumbotron :header="(policy && policy.title) || 'Administration'" :lead="(policy && policy.text) || 'System administration of EntryPoint'" border-variant="secondary">
                                <b-row>
                                    <b-col :cols="6">
                                        <b-card border-variant="dark">
                                            <b-card-title>License Information</b-card-title>
                                            <b-card-text>{{licenseBusy || licenseError || (licenseInfo && licenseInfo.label) || null}}</b-card-text>
                                            <b-card-body>
                                                <div v-if="licenseBusy">
                                                    <b-spinner class="mx-auto"></b-spinner>
                                                </div>
                                                <div v-else-if="licenseError">
                                                </div>
                                                <div v-else-if="licenseInfo">
                                                    <b-table-simple>
                                                        <b-tbody>
                                                            <b-tr v-if="licenseInfo.id"><b-th>ID</b-th><b-td>{{licenseInfo.id}}</b-td></b-tr>
                                                            <b-tr v-if="licenseInfo.trialExpiration">
                                                                <b-th>Trial Expires</b-th>
                                                                <b-td>{{licenseInfo.trialExpiration ? licenseInfo.trialExpiration : "No expiration"}}</b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-th>Expires</b-th>
                                                                <b-td>{{licenseInfo.expiration ? licenseInfo.expiration : "No expiration"}}</b-td>
                                                            </b-tr>
                                                            <b-tr v-if="licenseInfo.upgradeExpiration">
                                                                <b-th>Upgrades Until</b-th>
                                                                <b-td>{{licenseInfo.upgradeExpiration ? licenseInfo.upgradeExpiration : "No expiration"}}</b-td>
                                                            </b-tr>
                                                            <b-tr v-if="licenseInfo.maxIdentities">
                                                                <b-th>Max Identities</b-th>
                                                                <b-td>{{licenseInfo.maxIdentities}}</b-td>
                                                            </b-tr>
                                                            <b-tr v-if="Array.isArray(licenseInfo.features) && licenseInfo.features.length > 0">
                                                                <b-th>Features</b-th>
                                                                <b-td>
                                                                    <b-table-simple borderless small class="p-0">
                                                                        <b-tbody class="p-0">
                                                                            <b-tr v-for="(f, index) in licenseInfo.features" :key="index" class="px-0">
                                                                                <b-td class="p-0">{{f.feature}}</b-td>
                                                                            </b-tr>
                                                                        </b-tbody>
                                                                    </b-table-simple>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr v-if="Array.isArray(licenseInfo.activations) && licenseInfo.activations.length > 0">
                                                                <b-th>Activated Servers</b-th>
                                                                <b-td>
                                                                    <b-table-simple borderless small class="p-0">
                                                                        <b-tbody class="p-0">
                                                                            <b-tr v-for="(a, index) in licenseInfo.activations" :key="index" class="px-0">
                                                                                <b-td class="p-0">{{a.machineName}}</b-td>
                                                                            </b-tr>
                                                                        </b-tbody>
                                                                    </b-table-simple>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </div>

                                            </b-card-body>
                                        </b-card>
                                        <b-card v-if="policy && Array.isArray(policy.registrationActions) && policy.registrationActions.length > 0">
                                            <b-button-group>
                                            </b-button-group>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-jumbotron>

                            <template #overlay>
                                <b-card class="text-center">
                                    <b-card-text class="mx-auto">{{busyMessage || "Please wait..."}}</b-card-text>
                                    <b-spinner class="mx-auto"></b-spinner>
                                </b-card>
                            </template>

                        </b-overlay>
                    </b-col>
                </b-row>

            </b-col>

        </b-row>

    </b-container>
</template>

<script>
    //import SearchBox from "./SearchBox.vue";
    import ManageClient from "../library/ManageClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubikey.svg";
    import iconSmartCard from "../assets/icons/card.svg";
    import iconPhone from "../assets/icons/phone.svg";
    import FidoWebAuthN from "../library/FidoWebAuthN.js";
    //import ReadOnlyFormDisplay from "../components/ReadOnlyFormDisplay.vue";

    export default {
        props: {
            msg: String,
            auth: Object
        },
        emits: ['needsAuth', 'error', 'interface', 'goHome'],
        data() {
            return {
                showConfirm: false,
                client: new ManageClient(),

                policy: null,

                licenseInfo: null,
                licenseError: null,
                licenseBusy: null,

                identity: null,
                authenticators: null,

                isBusy: false,
                busyMessage: "Please wait...",
                busyDetails: null,

                registerAction: null,
                registerStep: null,
                registerClient: null,
                isRegistering: false,

                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone
                },
            }
        },
        components: {
            //ReadOnlyFormDisplay
        },
        computed: {
        },
        async mounted() {
            try {

                DConsole.log("Admin component mounted.");
                DConsole.log(this.auth);

                this.client.session = this.auth;

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                this.isBusy = true;

                await this.reload();

            }
            catch (ex) {
                DConsole.log(ex);
                this.handleError(ex);

            }
            this.isBusy = false;

        },
        methods: {

            async reload() {

                this.policy = {}; // await this.client.getAdminPolicy();

                try {
                    this.licenseBusy = "Loading license info...";
                    this.licenseError = null;
                    this.licenseInfo = await this.client.getLicenseInfo();
                }
                catch (ex) {
                    DConsole.log(ex);
                    this.licenseError = ex;
                }
                this.licenseBusy = null;
            },

            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    //goHome: () => this.goHome()
                });
            },

            raiseNeedsAuthEvent() {
                this.$emit("needsAuth");

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (ex.status === 401) {
                    this.raiseNeedsAuthEvent();
                }
                else {
                    this.raiseErrorEvent(ex);
                }

            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            onShowConfirm() {
                this.showConfirm = true;
            },

            raiseGoHomeEvent(identityID, identityName) {
                let identity = null;
                if (identityID) {
                    identity = {
                        name: identityName,
                        id: identityID
                    };
                }
                this.$emit("goHome", identity);
            },

            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },


            async onRegister(action) {
                if (!action || !action.name) return;

                DConsole.log(action);

                let input = {
                    Action: action.name
                };

                this.registerAction = action;
                this.registerClient = this;

                this.isBusy = true;
                try {
                    this.registerStep = await this.client.register(input);
                    this.isRegistering = true;

                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isBusy = false;

            },

            showBusy(message, details) {
                this.isBusy = true;
                this.busyMessage = message;
                this.busyDetails = details;
            },

            hideBusy() {
                this.isBusy = false;
                this.busyMessage = null;
                this.busyDetails = null;
            },

            async submitActionForm(dataModel) {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ Form: dataModel });
                return this.registerStep;

            },

            async submitEmptyStep() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({});
                return this.registerStep;
            },

            async skipActionStep() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ Skip: true });
                return this.registerStep;

            },

            async submitCertificate() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ RegisterCertificate: true });
                return this.registerStep;

            },

            async createAndSubmitFidoKey(fidoCreationOptions) {
                if (!this.isRegistering) return;

                let input = {};
                let fidoClient = new FidoWebAuthN();

                try {
                    let fidoResult = await fidoClient.createCredential(fidoCreationOptions);

                    input.FidoCreationResult = fidoResult;
                }
                catch (ex) {
                    DConsole.log(ex);
                    input.FidoErrorResult = {
                        Message: ex.message,
                        Details: null
                    };
                }
                this.registerStep = await this.client.register({ FidoInput: input });

                return this.registerStep;
            },

            async waitForDeviceRegistration() {
                if (!this.isRegistering) return;

                this.registerStep = await this.client.register({ WaitForDeviceRegistration: true });
                return this.registerStep;

            },

            async registrationFinished() {
                if (!this.isRegistering) return;

                this.showBusy("Please wait...");

                try {
                    await this.reload();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isRegistering = false;
                this.registerAction = null;
                this.registerStep = null;

                this.hideBusy();

            },

            async registrationCanceled() {
                if (!this.isRegistering) return;

                this.showBusy("Please wait...");

                //Set this even though the action was canceled. It causes
                //the login client to just refresh and go back to the step it was on.
                this.client.didUserCompleteRegistrationAction = true;

                try {
                    await this.reload();
                }
                catch (ex) {
                    this.handleError(ex);
                }
                this.isRegistering = false;
                this.registerAction = null;
                this.registerStep = null;

                this.hideBusy();

            },


        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

</style>

