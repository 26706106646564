import ExpUtil from "../util/ExpUtil.js";
import DConsole from "../util/DConsole.js";

class Token {
    constructor(options, tokenModels) {
        if (options) {
            Object.assign(this, options);
            if (options.additional) {
                let a = {};

                for (let prop in options.additional) {
                    let propDesc = options.additional[prop];
                    if (propDesc.value !== undefined && propDesc.value !== null) {
                        a[prop] = propDesc.value; //todo: handle data types.
                    }
                }

                Object.assign(this, a);
            }
        }

        this.tokenModels = tokenModels;
    }

    tokenModels = null;

    get shortName() {
        let id = this.shortSerialNumber;
        if (id === undefined || id === null || id === "") {
            id = this.shortTokenID;
        }

        return (this.modelName || this.family || "Token") + " " + id;
    }

    get name() {
        let output = `${this.modelName || this.family || "Token"} ${this.serialNumber || this.tokenID}`;
        return output;
    }

    get shortTokenID() {
        let output = this.tokenID;
        if (typeof this.tokenID === "string") {
            output = this.tokenID.slice(-8); //last 8 characters.
        }
        return output;
    }

    get shortSerialNumber() {
        let output = this.serialNumber;
        if (typeof this.serialNumber === "string") {
            output = this.serialNumber.slice(-10); //last 10 characters.
        }
        return output;
    }

    get model() {

        let modelID = this.modelID;
        let modelName = this.tokenModels?.find(x => x.id === modelID)?.name || null;

        let output = { id: this.modelID, name: modelName};

        return output;
    }

    get modelName() {
        return this.model?.name;
    }

    get creationTimeString() {
        if (this.creationTime) {
            return ExpUtil.formatEventTime(this.creationTime);
        }
        else {
            return "";
        }
    }

    get expirationTimeString() {
        if (this.expirationTime) {
            return ExpUtil.formatEventTime(this.expirationTime);
        }
        else {
            return "";
        }
    }

    get revocationTimeString() {
        if (this.revocationTime) {
            return ExpUtil.formatEventTime(this.revocationTime);
        }
        else {
            return "";
        }
    }

    get isValid() {
        let now = new Date();
        let exp = this.expirationTime ? new Date(this.expirationTime) : null;

        return !this.isRevoked && (!exp || exp > now);
    }

    get pivFascn() {
        let output = null;
        if (this.pivChuid) {
            output = this.pivChuid.fascn;
        }
        return output;
    }

    get pivCardGuid() {
        let output = null;
        if (this.pivChuid) {
            output = this.pivChuid.cardGuid;
        }
        return output;
    }

    get pivExpirationDate() {
        let output = null;
        if (this.pivChuid && this.pivChuid.expirationDate) {
            try {
                let date = this.pivChuid.expirationDate;
                output = date;
            }
            catch (ex) {
                DConsole.log(`Error parsing pivChuid.expirationDate ${this.pivChuid?.expirationDate}: ${ex.message}`);
            }
        }
        return output;
    }

    get canGetPUK() {
        let output = false;
        //See if the PUK has a key name and a block of diversification data (which comes over as a base64 string)
        if (this.pinUnlockDiversificationKey && this.pinUnlockDiversificationData) {
            output = true;
        }

        return output;
    }

    //True if these refer to the same token (by ID)
    equals(other) {
        let output = false;
        if (other && other instanceof Token) {
            output = this.tokenID === other.tokenID && this.family === other.family;
        }

        return output ? true : false;
    }

}

export default Token;