<template>
    <b-container class="ep-flexible-tabs px-0">
        <b-row align-v="start" no-gutters>
            <b-col v-if="hasItems" align-v="start" no-gutters>
                <b-row v-if="! selectedItem" no-gutters>
                    <b-col v-for="(item, index) in items" :key="index" :cols="12" :md="6" :xl="4">
                        <slot name="tab" :item="item" :index="index">
                            <b-button @click="underlyingSelectedItem = item; underlyingSelectedIndex = index;" variant="outline-secondary" block squared>
                                <b-row align-v="center" no-gutters>
                                    <b-col v-if="typeof getIcon === 'function'" cols="3">
                                        <b-img :src="getIcon(item, index)" class="rounded-0 not-too-big" fluid></b-img>
                                    </b-col>
                                    <b-col :cols="typeof getIcon === 'function' ? 8 : 11" class="text-left pl-2 pr-1">
                                        <slot name="tab-description" :item="item" :index="index">
                                            <div>{{getTitleForItem(item, index)}}</div>
                                        </slot>
                                    </b-col>
                                    <b-col :cols="1">
                                        <b-icon-arrow-right />
                                    </b-col>
                                </b-row>
                            </b-button>

                        </slot>
                    </b-col>
                </b-row>
                <b-row v-if="selectedItem" align-v="center">
                    <b-col md="2">
                        <b-button @click="underlyingSelectedItem = null; underlyingSelectedIndex = -1;" variant="outline-secondary" block squared class="border-0">
                            <b-row align-v="center">
                                <b-col align-h="start" class="text-left text-nowrap">
                                    <b-icon-arrow-left /><span>Back</span>
                                </b-col>
                            </b-row>

                        </b-button>
                    </b-col>
                    <b-col md="10" align-h="start" class="text-left pl-4">
                        <h4>{{getTitleForItem(selectedItem, selectedIndex)}}</h4>
                    </b-col>

                </b-row>
                <Transition name="slide">
                    <b-row v-if="selectedItem">
                        <b-col>
                            <slot :item="selectedItem" :index="selectedIndex">
                            </slot>
                        </b-col>
                    </b-row>
                </Transition>
            </b-col>
            <b-col v-else-if="items !== undefined && ! hasItems">
                <slot name="empty"/>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    import DConsole from "../util/DConsole.js";
    //import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";

    export default {
        props: {
            items: {
                type: Array,
                default: undefined
            },
            titleProperty: {
                type: String,
                default: "name"
            },
            getIcon: {
                type: Function,
                default: undefined
            },
            getTitle: {
                type: Function,
                default: undefined
            },
            //Optional function for testing equality of two items.
            itemEquals: {
                type: Function,
                default: undefined
            },

            isBusy: Boolean,
            busyMessage: String,
            busyDetails: String

        },
        emits: ['error'],
        data() {
            return {
                underlyingSelectedItem: null,
                underlyingSelectedIndex: -1
            }
        },
        components: {
        },
        computed: {
            hasItems() {
                return Array.isArray(this.items) && this.items.length > 0;
            },

            selectedItem() {
                let item = this.underlyingSelectedItem;
                if (item !== null && item !== undefined) {
                    item = this.items.find((x) => {
                        if (typeof this.itemEquals === "function") {
                            return this.itemEquals(item, x);
                        }
                        else if (typeof item.equals === "function") {
                            //them item as an "equals" function, so we will use that to check
                            //for equivalence. That way we can handle situations where the same
                            //underlying object is there, but the exact instance has changed.
                            //This happens, for example, if we are wrapping objects from the server
                            //in local Javascript objects.
                            return item.equals(x);
                        }
                        else {
                            //fallback to standard equals.
                            return item === x;
                        }
                    });
                }
                return item;
            }
        },
        async mounted() {

            try {
                DConsole.log("FlexibleTabs mounted.");
            }
            catch (ex) {
                this.handleError(ex);
            }
        },
        methods: {

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            getTitleForItem(item, index) {
                return typeof this.getTitle === 'function' ? this.getTitle(item, index) : (item?.title || item?.label || item?.shortName || item?.name || ("Item " + index))
            },


            getPrioritizedPropValue(a, b, c) {
                let output;
                if (a !== undefined && a !== null) {
                    output = a;
                }
                else if (b !== undefined && b !== null) {
                    output = b;
                }
                else {
                    output = c;
                }
                return output;
            },

            handleError(ex) {
                DConsole.log(ex);
                if (typeof this.client?.handleError === "function") {
                    this.client.handleError(ex);
                }
                else {
                    if (ex.status === 401) {
                        this.raiseNeedsAuthEvent();
                    }
                    else {
                        this.raiseErrorEvent(ex);
                    }
                }
            },

            clearError() {
                this.raiseErrorEvent(null);
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .not-too-big {
        max-height: 25vw;
    }

    .slide-enter-active {
        transition: .2s;
    }

    .slide-enter {
        transform: translate(100%, 0);
    }

    .slide-leave-active {
        transition: none;
    }

    .slide-leave-to {
        transition: none;
        /*transform: translate(-100%, 0);
            */
    }

    .ep-flexible-tabs {
        overflow: hidden;
    }

</style>

