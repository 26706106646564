<template>
    <b-container>
        <b-row class="p-0">
            <b-col class="p-0">
                <!--<div><slot></slot></div>-->
                <b-navbar toggleable="md" type="light">
                    <b-navbar-brand href="#" @click.prevent="goHome" class="ep-navbar-brand"><img src="../assets/branding/header-logo.png" alt="EntryPoint"></b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse" v-if="isLoggedIn"/>

                    <b-collapse id="nav-collapse" is-nav v-if="isLoggedIn">
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item v-if="showHome" href="#" @click.prevent="goHome">Search</b-nav-item>
                            <b-nav-item v-if="showReports" href="#" @click.prevent="goReports">Reports</b-nav-item>
                            <b-nav-item v-if="showInventory" href="#" @click.prevent="goInventory">Inventory</b-nav-item>
                            <b-nav-item v-if="showAdmin" href="#" @click.prevent="goAdmin">Sys Admin</b-nav-item>
                            <b-nav-item v-if="showSelfService" href="#" @click.prevent="goSelfService">My Info</b-nav-item>
                            <b-nav-item href="#" @click.prevent="logOut">Log Out</b-nav-item>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        props: {
            title: String,
            isLoggedIn: Boolean,
            showHome: Boolean(true),
            showReports: Boolean(true),
            showSelfService: Boolean(true),
            showAdmin: Boolean(false),
            showInventory: Boolean(false)
        },
        emits: ['goHome', 'logOut', 'goReports', "goSelfService", "goInventory", "goAdmin"],

        methods: {
            goHome() {
                this.$emit("goHome");
            },

            goReports() {
                this.$emit("goReports");
            },

            goSelfService() {
                this.$emit("goSelfService");
            },

            goInventory() {
                this.$emit("goInventory");
            },

            goAdmin() {
                this.$emit("goAdmin");
            },

            logOut() {
                this.$emit("logOut");
            }
        }
    };
</script>

<style scoped>
    header {
        display: flex;
        background: url(../assets/branding/header-logo.png) no-repeat left center;
        background-size: contain;
        background-position: left 10px top 50%; /*put the background image 10 px from the left edge and centered vertically.*/
        height: 48px;
        margin: 10px 0 0 0;
        color: #384f68;
        justify-content: left;
        align-items: center;
        text-align: left;
        padding-left: 300px;
        font-weight: bold;
        font-style: italic;
        font-size: 200%;
        letter-spacing: 2px;
    }

    div {
        vertical-align: middle;
    }

    @media screen and (max-width: 768px) {
    }

    @media screen and (max-width: 576px) {
        .ep-navbar-brand img {
            width: 60vw;
        }
    }

</style>