import ExpUtil from "../util/ExpUtil.js";
import Token from "../library/Token.js";
import TokenFidoKey from "../library/TokenFidoKey.js";
import CertificateSearchResult from "../library/CertificateSearchResult";
import UserDevice from "../library/UserDevice.js";
import DConsole from "../util/DConsole.js";

import * as x509 from "@peculiar/x509";
//import X509Certificate from "@peculiar/x509";

class Credential {
    constructor(options, tokenModels) {
        Object.assign(this, options);

        if (this.token?.token) {
            this.token.token = new Token(this.token.token);

            let token = ExpUtil.getCaseInsensitiveProxy(new Token(this.token.token, tokenModels));
            token.additionalDetails = this.token.tokenAdditionalDetails;
            if (token.additionalDetails?.dataModel) {
                token.additionalDetails.dataModel = ExpUtil.getCaseInsensitiveProxy(token.additionalDetails.dataModel);
            }
            if (this.token.pivChuid) {
                token.pivChuid = ExpUtil.getCaseInsensitiveProxy(this.token.pivChuid);
            }
            this.token = token;
        }

        if (this.device) {
            this.device = new UserDevice(this.device);
        }

        if (Array.isArray(this.fidoKeys)) {
            let keyWrappers = [];
            for (let k of this.fidoKeys) {
                let wrapper = new TokenFidoKey(k);
                keyWrappers.push(wrapper);
            }
            this.fidoKeys = keyWrappers;
        }

        if (Array.isArray(this.certificates)) {
            let certWrappers = [];
            for (let c of this.certificates) {
                let wrapper = new CertificateSearchResult(c);
                certWrappers.push(wrapper);
            }

            this.certificates = certWrappers;
        }
    }

    get shortName() {
        return this.token ? this.token.shortName
            : this.device ? this.device.shortName
                : Array.isArray(this.fidoKeys) && this.fidoKeys.length > 0 ? (this.fidoKeys[0].relyingPartyName || this.fidoKeys[0].relyingPartyID || "FIDO2 Key")
                    : Array.isArray(this.certificates) && this.certificates.length > 0 ? this.certificates[0].name
                        : "Credential";
    }

    get name() {
        return this.shortName;
    }

    get creationTimeString() {
        if (this.creationTime) {
            return ExpUtil.formatEventTime(this.creationTime);
        }
        else {
            return "";
        }
    }

    get expirationTimeString() {
        if (this.expirationTime) {
            return ExpUtil.formatEventTime(this.expirationTime);
        }
        else {
            return "";
        }
    }

    get revocationTimeString() {
        if (this.revocationTime) {
            return ExpUtil.formatEventTime(this.revocationTime);
        }
        else {
            return "";
        }
    }

    get attestationCertX509() {
        let cert = this._attestationCertX509;

        if (!cert && this.attestationCert) {
            try {
                cert = new x509.X509Certificate(this.attestationCert);
                this._attestationCertX509 = cert;
                DConsole.log(cert);
            }
            catch (ex) {
                DConsole.log(ex);
            }
        }
        return cert;
    }

    _attestationCertX509 = null;

    get attestationCertName() {
        let cert = this.attestationCertX509;
        return cert && CertificateSearchResult.getLastPartOfDistinguishedName(cert.subjectName);
    }

    get isValid() {
        let now = new Date();
        let exp = this.expirationTime ? new Date(this.expirationTime) : null;

        return !this.isRevoked && (!exp || exp > now);
    }

    get isRevoked() {
        let output = false;
        if (this.isToken) {
            output = this.token.isRevoked;
        }
        else if (this.isDevice) {
            output = this.device.isRevoked;
        }
        else if (this.isStandaloneFidoKey) {
            output = this.fidoKeys[0].isRevoked;
        }
        else if (this.isStandaloneCertificate) {
            output = this.certificates[0].isRevoked;
        }
        return output ? true : false;
    }

    get isToken() {
        return this.token ? true : false;
    }

    get isDevice() {
        return this.device ? true : false;
    }

    get isStandaloneFidoKey() {
        return !this.isToken && !this.isDevice && Array.isArray(this.fidoKeys) && this.fidoKeys.length > 0;
    }

    get isStandaloneCertificate() {
        return !this.isToken && !this.isDevice && Array.isArray(this.certificates) && this.certificates.length > 0;
    }

    //Just checks to see if it referring to the same underlying object.
    equals(other) {
        let output = false;
        if (other && other instanceof Credential) {
            if (this.isToken) {
                output = this.token.equals(other.token);
            }
            else if (this.isDevice) {
                output = this.device.equals(other.device);
            }
            else if (this.isStandaloneFidoKey && other.isStandaloneFidoKey) {
                output = this.fidoKeys[0].equals(other.fidoKeys[0]);
            }
            else if (this.isStandaloneCertificate && other.isStandaloneCertificate) {
                output = this.certificates[0].equals(other.certificates[0]);
            }
        }

        return output ? true : false;
    }
}

export default Credential;