<template>
    <b-container>
        <b-row align-v="start">
            <b-col align-v="start">
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy" rounded="sm" opacity="0.6">

                            <b-jumbotron :header="policy && policy.title" :lead="policy && policy.text" border-variant="secondary">
                                <b-row v-if="isRegistering">
                                    <b-col>
                                        <div />
                                    </b-col>

                                </b-row>
                                <b-row v-else>
                                    <b-col>
                                        <b-card v-if="policy && policy.showPersonalInfo && identity">
                                            <b-card-body v-if="identity && ! policy.personalInfo">
                                                <div v-for="(value, key) in identity" :key="key">
                                                    <template v-if="key !== 'additional' && key !== 'groups' && key !== 'groupListIsValid' && key !== 'photo' && key !== 'thumbnailPhoto'">
                                                        <dt>{{key}}</dt>
                                                        <dd>{{value}}</dd>
                                                    </template>
                                                </div>

                                            </b-card-body>
                                            <b-card-body v-if="identity && policy.personalInfo">
                                                <b-row>
                                                    <b-col md="12">
                                                        <ReadOnlyFormDisplay :form="policy.personalInfo" />
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                        <b-card v-if="policy && policy.showAuthenticators && Array.isArray(authenticators) && authenticators.length > 0">
                                            <b-card-title v-if="true">Authenticators</b-card-title>
                                            <b-card-text v-if="true"></b-card-text>
                                            <b-row no-gutters>
                                                <b-card v-for="a in authenticators" :key="a.id" no-body
                                                        class="overflow-hidden col-4">
                                                    <b-row no-gutters>
                                                        <b-col md="2">
                                                            <b-img v-if="a.photo" :src="makePhotoDataUrl(a.photo)" class="rounded-0"></b-img>
                                                            <b-img v-else-if="(a.token && a.token.family && a.token.family.startsWith('YubiKey')) || (a.fidoKey && a.fidoKey.attestationCertName && a.fidoKey.attestationCertName.includes('Yubi'))" :src="icons.yubiKey" class="rounded-0 "></b-img>
                                                            <b-img v-else-if="a.token && a.token.family && a.token.family.startsWith('IDOne')" :src="icons.smartCard" class="rounded-0 "></b-img>
                                                            <b-img v-else-if="a.userDevice || (a.fidoKey && a.fidoKey.userDeviceID)" :src="icons.phone" class="rounded-0"></b-img>
                                                        </b-col>
                                                        <b-col md="10">
                                                            <b-card-body>
                                                                <b-card-title>{{a.title}}</b-card-title>
                                                                <b-card-sub-title v-if="a.subtitle">{{a.subtitle}}</b-card-sub-title>
                                                                <div v-if="Array.isArray(a.fidoKeys) && a.fidoKeys.length > 0">
                                                                    <b-card-sub-title>Credentials</b-card-sub-title>
                                                                    <dl>
                                                                        <div v-for="key in a.fidoKeys" :key="key.credentialID">
                                                                            <dt>{{key.relyingPartyName}}</dt>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <dl v-if="a.data">
                                                                    <div v-for="(value, key) in a.data" :key="key">
                                                                        <dt>{{key}}</dt>
                                                                        <dd>{{formatDataValue(value)}}</dd>
                                                                    </div>
                                                                </dl>
                                                            </b-card-body>
                                                            <b-card-footer>
                                                                <b-button-group>
                                                                    <div>Some text.</div>
                                                                </b-button-group>
                                                            </b-card-footer>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-row>
                                        </b-card>
                                        <b-card v-if="policy && Array.isArray(policy.registrationActions) && policy.registrationActions.length > 0">
                                            <b-button-group>
                                                <b-button v-for="a in policy.registrationActions" :key="a.name" @click="onRegister(a)" class="mr-2">{{a.label}}</b-button>
                                            </b-button-group>
                                        </b-card>
                                        <b-card v-if="policy && Array.isArray(policy.links) && policy.links.length > 0">
                                            <b-card-group>
                                                <b-link v-for="a in policy.links" :href="a.url" :key="a.url + a.title + a.text" :target="a.sameTab ? undefined : '_blank'" :ref="a.sameTab ? undefined : 'noreferrer noopener'">
                                                    <b-card :title="a.title" :text="a.text" />
                                                </b-link>
                                            </b-card-group>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-jumbotron>

                            <template #overlay>
                                <b-card class="text-center">
                                    <b-card-text class="mx-auto">{{busyMessage || "Please wait..."}}</b-card-text>
                                    <b-spinner class="mx-auto"></b-spinner>
                                </b-card>
                            </template>

                        </b-overlay>
                    </b-col>
                </b-row>

            </b-col>

        </b-row>

        <WizardModal :handler="wizard"
                     @next="wizard.onNext()"
                     @skip="wizard.onSkip()"
                     @cancel="wizard.onCancel()"
                     @previous="wizard.onPrevious()"
                     @error="(evt) => wizard.onError(evt)"
                      />


    </b-container>
</template>

<script>
    //import SearchBox from "./SearchBox.vue";
    import SelfServiceClient from "../library/SelfServiceClient.js";
    import DConsole from "../util/DConsole.js";
    import ExpUtil from "../util/ExpUtil.js";
    //import Url from "../util/Url.js";
    //import Token from "../library/Token.js";
    //import TokenFidoKey from "../library/TokenFidoKey.js";
    //import CertificateSearchResult from "../library/CertificateSearchResult.js";
    import iconYubiKey from "../assets/icons/yubikey.svg";
    import iconSmartCard from "../assets/icons/card.svg";
    import iconPhone from "../assets/icons/phone.svg";
    import ReadOnlyFormDisplay from "../components/ReadOnlyFormDisplay.vue";
    import WizardModal from "../components/WizardModal.vue";
    import WizardHandler from "../library/WizardHandler.js";

    export default {
        props: {
            msg: String,
            auth: Object
        },
        emits: ['needsAuth', 'error', 'interface', 'goHome'],
        data() {
            return {
                showConfirm: false,
                client: new SelfServiceClient(),

                policy: null,

                identity: null,
                authenticators: null,

                isBusy: false,
                busyMessage: "Please wait...",
                busyDetails: null,

                isRegistering: false,

                wizard: new WizardHandler({
                    doStep: this.doStepWizard,
                    onFinish: this.onFinishWizard
                }),


                icons: {
                    yubiKey: iconYubiKey,
                    smartCard: iconSmartCard,
                    phone: iconPhone
                },
            }
        },
        components: {
            ReadOnlyFormDisplay,
            WizardModal
        },
        computed: {
        },
        async mounted() {
            try {

                DConsole.log("Self-service component mounted.");
                DConsole.log(this.auth);

                this.client.session = this.auth;

                //Emit the interface the parent can use to call certain methods on this component in a clean way.
                this.emitInterface();

                this.isBusy = true;

                await this.reload();

            }
            catch (ex) {
                DConsole.log(ex);
                this.handleError(ex);

            }
            this.isBusy = false;

        },
        methods: {

            async reload() {

                this.policy = await this.client.getSelfServiceDescriptor();
                this.identity = ExpUtil.getCaseInsensitiveProxy(await this.client.getIdentity());
                if (this.policy?.showAuthenticators) {
                    this.authenticators = await this.client.getAuthenticators();
                }
                else {
                    this.authenticators = null;
                }
            },

            /**
             * Emitting an interface with callable methods from outside.
             * See https://stackoverflow.com/a/70723343
             */
            emitInterface() {
                this.$emit("interface", {
                    //goHome: () => this.goHome()
                });
            },

            raiseNeedsAuthEvent() {
                this.$emit("needsAuth");

            },

            raiseErrorEvent(ex) {
                this.$emit("error", ex);
            },

            handleError(ex) {
                DConsole.log(ex);
                if (ex.status === 401) {
                    this.raiseNeedsAuthEvent();
                }
                else {
                    this.raiseErrorEvent(ex);
                }

            },

            clearError() {
                this.raiseErrorEvent(null);
            },

            onShowConfirm() {
                this.showConfirm = true;
            },

            raiseGoHomeEvent(identityID, identityName) {
                let identity = null;
                if (identityID) {
                    identity = {
                        name: identityName,
                        id: identityID
                    };
                }
                this.$emit("goHome", identity);
            },

            formatDataValue(input) {
                return ExpUtil.formatFieldValue(input);
            },

            async onRegister(action) {
                if (!action || !action.name) return;

                if (!this.identity?.id) return;

                this.clearError();

                this.isRegistering = true;

                try {
                    await this.wizard.start(action);
                }
                catch (ex) {
                    this.handleError(ex);
                    this.isRegistering = false;
                }
            },


            async doStepWizard(stepInput) {
                if (!this.isRegistering) throw new Error("No registration is in progress.");
                if (!this.identity?.id) throw new Error("No identity.");

                return await this.client.register(
                    this.identity.id,
                    null, null, null, stepInput);
            },

            async onFinishWizard(ok) {
                this.isRegistering = false;
                if (ok) {
                    await this.reload();
                }
            },


            showBusy(message, details) {
                this.isBusy = true;
                this.busyMessage = message;
                this.busyDetails = details;
            },

            hideBusy() {
                this.isBusy = false;
                this.busyMessage = null;
                this.busyDetails = null;
            },


        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    /*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
    }

    td.no-wrap, th.no-wrap
    {
        white-space: nowrap;
    }

    .fill-remaining-vertical-space {
        flex: 1;
    }

    .yubi-icon-large {
        padding: 40px;
    }

    .card-body dd {
        /* Break in the middle of a super long word if necessary.*/
        overflow-wrap: break-word;
        display: block;
    }

</style>

