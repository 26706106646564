import Url from '../util/Url';
//import Guid from '../util/Guid';
import DConsole from '../util/DConsole';
import BaseClient from '../library/BaseClient';

class SelfServiceClient extends BaseClient {
    constructor(baseUrl) {
        super();

        this.session = null;
        this.baseUrl = baseUrl || Url.combine(Url.removePathPart(window.location.href), "self");
        if (process.env.NODE_ENV === "development") {
            //when in dev mode (say, on my laptop in a debugger) point to server running in a different debug process.
            let url = new URL(window.location.href);
            this.baseUrl = `https://${url.hostname}:5001/self`; //"https://localhost:5001/";

        }

        DConsole.log("baseURL: " + this.baseUrl);
    }


    async start() {
        return await this.postJson("start", {});
    }

    async end() {
        return await this.postJson("end", {});
    }

    async getSelfServiceDescriptor() {
        return await this.get("getSelfServiceDescriptor");
    }

    async getIdentity() {
        return await this.get("getIdentity");
    }

    async getTokens() {
        return await this.get("getTokens");
    }

    async getUserDevices() {
        return await this.get("getUserDevices");
    }

    async getAuthenticators() {
        return await this.get("getAuthenticators");
    }

    async register(identityID, tokenFamily, tokenID, deviceID, stepInput) {
        let input = {
            IdentityID: identityID,
            TokenFamily: tokenFamily,
            TokenID: tokenID,
            DeviceID: deviceID,
            StepInput: stepInput
        };
        return await this.postJson("register", input);

    }


}

export default SelfServiceClient;
