import moment from 'moment';
import DConsole from './DConsole.js';
//import CryptoJS from "CryptoJS";

class ExpUtil {
    /// Returns a 3 element array of strings.
    /// element 0: the first line
    /// element 1: the second line
    /// element 2: connecting word or punctuation if they are displayed on one line.
    static getDatesInTwoLines(exp, policy) {
        let out = ["", "", ""];

        let start = exp.StartUTC || exp.StartTimeUTC;
        let end = exp.EndUTC || exp.EndTimeUTC;
        start = start ? moment.utc(start).local() : null;
        end = end ? moment.utc(end).local() : (start ? start.clone() : null);

        let now = moment();
        let isAllDay = exp.IsAllDay ? true : false;

        if (start === null) {
            //do nothing. Note: end will only be null if start is null.
        }
        else if (start.year() === end.year() && start.dayOfYear() === end.dayOfYear()) {
            //same date. Line 0 will be the date.
            if (start.year() === now.year()) {
                out[0] = start.format("MMMM D");
            }
            else {
                out[0] = start.format("MMMM D, YYYY");
            }
            //Line 1 will be the time or times.
            if (isAllDay) {
                out[1] = "All Day";
                out[2] = ", ";
            }
            else {
                let formatString = (policy && policy.TimeFormatReadOnly) || "LT"; //LT = localized short time

                if (start.hour() === end.hour() && start.minute() === end.minute()) {
                    out[1] = start.format(formatString);
                    out[2] = " at ";
                }
                else {
                    out[1] = start.format(formatString) + " to " + end.format(formatString);
                    out[2] = " from ";
                }
            }
        }
        else {
            //different dates.
            let format;
            if (start.year() === now.year() && end.year() === now.year()) {
                format = "MMMM D";
            }
            else {
                format = "MMMM D, YYYY";
            }

            if (!isAllDay) {
                //note: wrapping characters in square brackets escapes them (for the "moment" library)
                format += "[ at ]" + ((policy && policy.TimeFormatReadOnly) || "LT"); //LT = localized short time
            }

            out[0] = start.format(format);
            out[1] = "to " + end.format(format);
            out[2] = " ";
        }
        return out;
    }

    static getSortableStartTime(exp) {
        let start = exp.StartUTC || exp.StartTimeUTC;
        start = start ? moment.utc(start).local().format() : "";

        return start;
    }

    static getSortableEndTime(exp) {
        let start = exp.StartUTC || exp.StartTimeUTC;
        let end = exp.EndUTC || exp.EndTimeUTC;
        start = start ? moment.utc(start).local() : null;
        end = end ? moment.utc(end).local().format() : (start ? start.clone().format() : "");

        return end;
    }

    // Usually just returns the input, but handles some special cases
    // (mainly dates) and formats accordingly.
    static formatFieldValue(input) {
        let out = input;

        //2022-09-28T11:11:34.8357129-04:00
        if (typeof input === "string" && /^[0-9]{4}-[01][0-9]-[0-2][0-9][TZ][0-9]{2}:[0-9]{2}:[0-9]{2}/.test(input)) {
            DConsole.log("Field value appears to be date string.");

            out = ExpUtil.formatEventTime(input);
        }

        return out;
    }

    static formatDateUTCToLocal(dateUTC, use24HourTime, isAllDay) {
        let out = "";
        let now = moment();
        let date = dateUTC ? moment.utc(dateUTC).local() : null;

        if (date !== null) {
            if (date.year() === now.year()) {
                out = date.format("MMMM D");
            }
            else {
                out = date.format("MMMM D, YYYY");
            }
            if (!isAllDay) {
                let formatString = use24HourTime ? "HH:mm" : "h:mm a";

                out = out + " at " + date.format(formatString);
            }
        }
        return out;
    }

    static formatEventTime(dateTime, use24HourTime, isAllDay) {
        //DConsole.log("formatEventTime(" + dateTime + ")");

        let out = "";
        let now = moment();
        let date = dateTime ? moment(dateTime) : null;

        if (date !== null) {
            if (date.year() === now.year()) {
                out = date.format("MMMM D");
            }
            else {
                out = date.format("MMMM D, YYYY");
            }
            if (!isAllDay) {
                let formatString = use24HourTime ? "HH:mm:ss" : "h:mm:ss a";

                out = out + " at " + date.format(formatString);
            }
        }
        return out;
    }



    static getAgeInYears(dob) {
        let today = moment();
        let age = -1;

        if (dob !== undefined && dob !== null) {
            dob = moment(dob);

            if (dob.isValid()) {

                //First, just figure out the difference in years.
                age = today.year() - dob.year();

                //Next, if the person's birthday has not yet passed for this year, we need to 
                //subtract one from the age, because they haven't had their birthday yet.
                //Note that we can't just use the DayOfYear property on DateTime, because the day of the
                //year for all dates after February 28 is one greater in leap years than in regular years.
                if (today.month() < dob.month()
                    || (today.month() === dob.month() && today.date() < dob.date()))
                {
                    age--;
                }
            }

        }
        return age;
    }

    static getAllFormFields(form) {
        if (form.Parts === undefined) {
            //this is a field.
            return [form];
        }
        else {
            let fields = []
            for (let i = 0; i < form.Parts.length; i++) {
                let part = form.Parts[i];
                if (part) {
                    //Push all the fields from this part onto the fields array.
                    fields.push.apply(fields, ExpUtil.getAllFormFields(part));
                }
            }
            return fields;
        }
    }

    static getSortableLabelForFieldValue(val) {
        let label = null;
        if (val === undefined || val === null) {
            label = "";
        }
        else if (typeof val === "string") {
            label = val;
        }
        else if (val.Label !== undefined) {
            label = val.Label;
        }
        else if (val.Name !== undefined) {
            label = val.Name;
        }
        else if (val.label !== undefined) {
            label = val.label;
        }
        else if (val.Value !== undefined) {
            label = val.Value;
        }
        else if (typeof val.getMonth === "function") {
            //must be a Date
            label = moment.utc(val).local().format();
        }
        else if (typeof val.month === "function") {
            //must be a Moment object
            label = val.format(); //sortable date format.
        }
        else if (typeof val === "number") {
            label = val;
        }
        else {
            label = JSON.stringify(val);
        }

        return label;
    }

    static getMatchableValueForFieldValue(val) {
        let output = null;
        if (val === undefined || val === null) {
            output = null;
        }
        else if (val.ID !== undefined && val.ID !== null) {
            output = val.ID;
        }
        else if (val.Value !== undefined) {
            output = val.Value;
        }
        else if (val.Stats_Value !== undefined) {
            output = val.Stats_Value;
        }
        else if (val.value !== undefined) {
            output = val.value;
        }
        else if (val.Label !== undefined) {
            output = val.Label;
        }
        else if (val.Name !== undefined) {
            output = val.Name;
        }
        else if (val.label !== undefined) {
            output = val.label;
        }
        else {
            output = val;
        }
        return output;
    }

    static escapeForCSV(input) {
        input = "" + input;
        let out = input.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm,' ');
        if (out.match(/["',]/g)) {
            out = '"' + out.replace(/"/g, '""') + '"';
        }
        
        return out;
    }

    static convertCamelCaseToTitleCase(input) {
        if (typeof input !== "string") {
            return "";
        }
        else {
            //kind of a quick, not real smart version taken from stack overflow.
            //let result = input.replace(/(?<![A-Z])([A-Z])/g, " $1").trim();
            let result = input.replace(/([^A-Z])?([A-Z])/g, ($0, $1, $2) => {   //NOTE: using this as a substitute for the "negative-lookbehind" above: (?<!) because WebKit (which includes Safari) on iOS does not support it as of May 30, 2023.
                return $1 ? ($1 + " " + $2) : $0;
            }).trim();
            result = result.replace(/ +/g, " ").trim(); //replace multiple spaces with single space.
            result = result.replace(/([A-Z])([A-Z])(?=[a-z])/g, "$1 $2").trim();
            result = result.charAt(0).toUpperCase() + result.slice(1);
            return result;
        }
    }

    /* eslint no-useless-escape: "off" */

    static EmailRegex = /^([a-zA-Z0-9.!#$%&�*+\-/=?^_�{|}~])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    static isValidEmail(input) {
        if (typeof input !== "string") {
            return false;
        }
        else {
            return ExpUtil.EmailRegex.test(input);
        }
    }

    static isValidPhoneNumber(input) {
        if (typeof input !== "string") {
            return false;
        }
        else {
            return true; //todo: make this work
        }
    }



    static convertJSONBooleanStringsToBool(obj) {
        if (typeof obj === "string") {
            let lower = obj.toLowerCase();
            if (lower === "true") {
                return true;
            }
            else if (lower === "false") {
                return false;
            }
            else {
                return obj;
            }
        }
        else if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                obj[i] = this.convertJSONBooleanStringsToBool(obj[i]);
            }
            return obj;
        }
        else if (obj instanceof Object) {
            for (let key in obj) {
                obj[key] = this.convertJSONBooleanStringsToBool(obj[key]);
            }
            return obj;
        }
        else {
            return obj;
        }

    }

    static coerceToBase64(input) {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(input)) {
            input = Uint8Array.from(input);
        }

        if (input instanceof ArrayBuffer) {
            input = new Uint8Array(input);
        }

        // Uint8Array to base64
        if (input instanceof Uint8Array) {
            let str = "";
            let len = input.byteLength;

            for (let i = 0; i < len; i++) {
                str += String.fromCharCode(input[i]);
            }
            input = window.btoa(str);
        }

        if (typeof input !== "string") {
            throw new Error("could not coerce to base64 string");
        }

        return input;
    }

    static coerceToBase64Url(input) {
        let output = ExpUtil.coerceToBase64(input);

        // NOTE: "=" padding at the end is optional, strip it off here
        output = output.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
        return output;
    }

    static decodeBase64Url(input) {
        //adapted from https://stackoverflow.com/a/51838635

        // Replace non-url compatible chars with base64 standard chars
        input = input.replace(/-/g, '+').replace(/_/g, '/');

        // Pad out with standard base64 required padding characters
        let pad = input.length % 4;
        if (pad) {
            if (pad === 1) {
                throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
            }
            input += new Array(5 - pad).join('=');
        }

        return input;
    }

    static convertBase64UrlToBase64(input) {
        let bytes = ExpUtil.decodeBase64Url(input);
        return ExpUtil.coerceToBase64(bytes);
    }

    static caseInsensitiveProxyFunctions = {
        get: (obj, prop) => {
            let output = obj != null ? obj[prop] : undefined;
            if (output === undefined && obj && typeof prop === "string") {
                let lowerProp = prop.toLowerCase();
                let key = Object.keys(obj).find(y => y.toLowerCase() === lowerProp);
                if (key !== undefined) {
                    output = obj[key];
                }
            }
            return output;
        },
        has: (obj, prop) => {
            let output = obj != null ? obj[prop] : undefined;
            if (output === undefined && obj && typeof prop === "string") {
                let lowerProp = prop.toLowerCase();
                let key = Object.keys(obj).find(y => y.toLowerCase() === lowerProp);
                if (key !== undefined) {
                    output = obj[key];
                }
            }
            return output !== undefined;
        },
        set: (obj, prop, value) => {
            let lowerProp = prop.toLowerCase();
            let key = Object.keys(obj).find(y => y.toLowerCase() === lowerProp);
            if (key === undefined) {
                key = prop;
            }
            obj[key] = value;
            return true;
        }
    }

    // Returns a Proxy that allows for case-insensitive access to properties.
    // "Proxy" is a JavaScript thing, see MDN docs for details.
    static getCaseInsensitiveProxy(obj) {
        if (Array.isArray(obj)) {
            //Do it for each element in the array.
            let output = [];
            for (let x of obj) {
                output.push(ExpUtil.getCaseInsensitiveProxy(x));
            }
            return output;
        }
        else if (typeof obj === "object" && obj !== null) {
            return new Proxy(obj ?? {}, ExpUtil.caseInsensitiveProxyFunctions);
        }
        else {
            //Else it should be a primitive so just return it.
            return obj;
        }
    }

}

export default ExpUtil;